import {Component, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {DateTime} from 'luxon'
import {SelectorBaseComponent} from '../selector-base.component'
import {TBorgoEmploymentType} from 'sparbanken-syd-borgo'
import {Employment} from '../../model/employment/employment'
import {filter} from 'rxjs'
import {startWith} from 'rxjs/operators'
import {EmploymentTypeMap} from '@sparbanken-syd/borgo-helpers'
import {DateValidator} from '../../../application/validators/date.validator'

@Component({
  selector: 'spb-employment',
  templateUrl: './employment.component.html',
  styleUrl: '../selectors.scss'
})
export class EmploymentComponent extends SelectorBaseComponent<Employment> implements OnInit {

  protected readonly employers = EmploymentTypeMap

  protected maxDate = DateTime.fromMillis(new Date().getTime() - 60 * 60 * 24 * 2 * 1000)

  public override form = new FormGroup({
    employmentType: new FormControl<TBorgoEmploymentType | null>(null,
      {nonNullable: true, validators: [Validators.required]}),

    employerName: new FormControl<string | null>(null),
    employedStartDate: new FormControl<DateTime | null>(null,
      {validators: []}),
    temporaryEmployedEndDate: new FormControl<DateTime | null>(null),
    typeSelfEmployedAbOrgnr: new FormControl<string | null>(null, {nonNullable: false}),
    isEgenForetagare: new FormControl<boolean | null>(null, {nonNullable: false})
  })

  public override ngOnInit() {
    super.ngOnInit()
    this.form.controls.isEgenForetagare.valueChanges
      .pipe(
        startWith(false),
        filter((v): v is boolean => typeof v === 'boolean'))
      .subscribe({
        next: (v) => {
          // Force org number if not enskild firma
          this.setValidatorsIfRequired(
            v,
            this.form.controls.typeSelfEmployedAbOrgnr, [Validators.required])
        }
      })

    this.form.controls.employmentType.valueChanges.subscribe({
      next: (v) => {
        this.form.reset({employmentType: v, isEgenForetagare: true}, {emitEvent: false})
      }
    })

    this.form.valueChanges
      .pipe(startWith(null))
      .subscribe({
        next: () => {
          // We have to assert this since TS thinks it is partial...
          const initial = (this.initial ?? new Employment()) as Required<Employment>
          initial.employmentType = this.form.controls.employmentType.value
          this.setValidatorsIfRequired(
            initial.shouldHaveEmployerName(),
            this.form.controls.employerName, [Validators.required])

          // Start date mandatory for employed.
          this.setValidatorsIfRequired(
            initial.shouldHaveEmployerName(),
            this.form.controls.employedStartDate, [
              Validators.required, DateValidator(0, this.maxDate.toMillis())])

          // Temporary employed, require end date
          this.setValidatorsIfRequired(
            this.form.controls.employmentType.value === 'TEMPORARY_EMPLOYED',
            this.form.controls.temporaryEmployedEndDate, [Validators.required])
        }
      })
  }
}
