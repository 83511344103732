import {Component, effect} from '@angular/core'
import {KycBaseItemComponent} from '../0-base/kyc-base-item.component'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {filter} from 'rxjs'
import {KycItemPep} from '../../model/kyc-item-pep'

@Component({
  selector: 'spb-kyc-pep',
  templateUrl: './kyc-pep.component.html',
  styleUrl: '../kyc-items.scss',
  providers: [{provide: KycBaseItemComponent, useExisting: KycPepComponent}]
})
export class KycPepComponent extends KycBaseItemComponent {

  public ctrl = new FormControl<boolean | null>(null,
    {nonNullable: true, validators: [Validators.required]})

  public override form = new FormGroup({pep: this.ctrl})

  protected pep: KycItemPep = new KycItemPep()

  constructor() {
    super()
    effect(() => {
      this.pep = this.input().pep

      this.pep.isValid$.subscribe(this.updateKyc)

      this.pep.value$.subscribe({
        next: v => this.ctrl.setValue(v)
      })

      this.ctrl.setValue(this.pep.pep)
    })

    this.ctrl.valueChanges
      .pipe(filter((v): v is boolean => v !== null))
      .subscribe({
        next: v => this.pep.pep = v
      })
  }
}
