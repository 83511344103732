import {Component, inject, Input} from '@angular/core'
import {UserService} from '../../../services/user.service'
import {filter, first} from 'rxjs'

@Component({
  selector: 'spb-operators',
  templateUrl: './operators.component.html'
})
export class OperatorsComponent {

  @Input() caseId: string = ''
  @Input() applicationId: string = ''

  private userService = inject(UserService)

  /**
   * Access data functions.
   */

  public updateAmortization(): void {
    this.userService
      .updateAmortization(this.caseId, this.applicationId).subscribe({
      next: (r) => console.log(r)
    })
  }

  public onboard(): void {
    this.userService
      .onboard(this.caseId, this.applicationId).subscribe({
      next: (r) => console.log(r)
    })
  }

  public offer(): void {
    const p = {
      loanCreditBreakdown: {
        ruleFramework: 'LTV_LTI',
        creditBreakdown: [
          {
            order: 2,
            amount: 100 * 1000,
            rateFixationPeriod: '36',
            monthlyAmortization: 3000
            //interestRateDiscount: '0.30',
            //interestRateDiscountInMonths: '36',
            //alternativeRule: false
            //creditBreakdownId: '1405BD0A6CBC739AE0630100007FB1D6',
            //externalCreditId: '7752',
            //accountNumber: '2342 00 171 52'
          },
          {
            order: 1,
            amount: 100 * 1000,
            rateFixationPeriod: '3',
            monthlyAmortization: 2000
            //interestRateDiscount: '0.20',
            //interestRateDiscountInMonths: '12',
            //alternativeRule: false
            //creditBreakdownId: '1405BD0A6CBB739AE0630100007FB1D6',
            //externalCreditId: '7751',
            //accountNumber: '2342 00 171 44'
          }]
      }
    }
    this.userService
      .offer(this.caseId, this.applicationId, p).subscribe({
      next: (r) => console.log(r)
    })
  }

  public getLoans(): void {
    this.userService.getLoans().subscribe({
      next: (r) => console.log(r)
    })
  }

  public getApplications(): void {
    this.userService.getApplications().subscribe({
      next: (r) => console.log(r)
    })
  }

  public dumpCredentials(): void {
    this.userService.token$.pipe(
      first(),
      filter(Boolean)
    ).subscribe({
      next: r => {
        const credentials = {
          accessToken: r.token,
          applicationId: this.applicationId,
          caseId: this.caseId
        }
        console.log(credentials)
      }
    })
  }
}
