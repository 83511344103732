<div>
  <spb-kyc-citizen
    [input]="data()"
    [position]="1"
    [primary]="primary()"
  ></spb-kyc-citizen>
  <spb-kyc-tax
    [input]="data()"
    [position]="2"
    [primary]="primary()"
  ></spb-kyc-tax>
  <spb-kyc-contact
    [position]="3"
    [input]="data()"
    [primary]="primary()"
  ></spb-kyc-contact>
  <spb-kyc-employment
    [input]="data()"
    [position]="4"
    [primary]="primary()"
  ></spb-kyc-employment>
  <spb-kyc-income
    [input]="data()"
    [position]="5"
    [primary]="primary()"
  ></spb-kyc-income>
  <spb-kyc-pep
    [input]="data()"
    [position]="6"
    [primary]="primary()"
  ></spb-kyc-pep>
</div>