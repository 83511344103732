import {Component, inject} from '@angular/core'
import {IApplicationSelect} from '../selectors/applications/applications.component'
import {PathSelector} from './path-selector/path-selector'

@Component({
  selector: 'spb-select-application',
  templateUrl: './select-application.component.html'
})
export class SelectApplicationComponent {

  private pathSelector = inject(PathSelector)

  public setApplication(a: IApplicationSelect): Promise<any> {
    return this.pathSelector.select(a.application.caseId, a.application.applicationId, a.application.status)
  }
}
