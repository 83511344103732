import {Component, inject, Input, OnChanges} from '@angular/core'
import {IWaitComponentData, WaitComponent} from '../../../../common/dialogs/wait/wait.component'
import {filter, switchMap} from 'rxjs'
import {MatDialog} from '@angular/material/dialog'
import {UserService} from '../../../../services/user.service'
import {Router} from '@angular/router'
import {LOAN_ROUTE_PATH} from '../../../../application/data-types'
import {IBorgoApplicationResult} from 'sparbanken-syd-borgo'

@Component({
  selector: 'spb-create-application',
  templateUrl: './create-application.component.html'
})
export class CreateApplicationComponent implements OnChanges {

  @Input() createApplication: boolean = false

  private dialog = inject(MatDialog)
  private service = inject(UserService)
  private router = inject(Router)

  public ngOnChanges() {
    if (this.createApplication) {
      this.create()
    }
  }

  public create(): void {
    const ref = this.dialog.open<WaitComponent, IWaitComponentData, any>(WaitComponent, {
      data: {
        title: 'Skapar',
        text: 'Vi skapar en ny ansökan...'
      }
    })
    ref.afterOpened()
      .pipe(
        switchMap(() => this.service.createApplication()),
        switchMap((application) => {
          return this.service.getApplication(application.caseId, application.applicationId)
        }),
        filter(Boolean)
      )
      .subscribe({
        next: (a: IBorgoApplicationResult) => {
          this.router.navigate(['/', LOAN_ROUTE_PATH, 'uppdatera', a.caseId, a.applicationId]).then()
          ref.close()
        }
      })
  }
}
