@if (!applicationStatus) {
  <spb-progress-ng
    message="Kontrollerar ansökan..."
    [minimal]="false"
  ></spb-progress-ng>
}
<div [ngClass]="{hidden: !applicationStatus}">
  <mat-stepper
    linear
    orientation="vertical"
    spbStepperScroll>
    @for (s of [1, 2, 3, 4, 5, 6, 7]; track $index) {
      <div>
        <mat-step
          [editable]="true"
          [label]="labelMap.get(s)!" completed>
          <div [style.margin-right]="'-60px'">
            @if (application) {
              @if (s === 1) {
                <spb-ro-applicants
                  [application]="application">
                </spb-ro-applicants>
                <spb-ro-costs
                  [application]="application">
                </spb-ro-costs>
              }
              @if (s === 2) {
                <spb-ro-household
                  [application]="application">
                </spb-ro-household>
              }
              @if (s === 3) {
                <spb-ro-main-property
                  [application]="application">
                </spb-ro-main-property>
              }
              @if (s === 4) {
                <spb-ro-other-living
                  [application]="application">
                </spb-ro-other-living>
              }
              @if (s === 5) {
                <spb-ro-new-loan
                  [application]="application">
                </spb-ro-new-loan>
              }
              @if (s === 6) {
                <spb-select-advice
                  [selected]="adviceSelection">
                </spb-select-advice>
              }
              @if (s === 7) {
                <spb-documents
                  [application]="application"
                  [applicationStatus]="applicationStatus"
                ></spb-documents>
              }

            }
          </div>

        </mat-step>
      </div>
    }
    <mat-step
      [completed]="!blockProgress()"
      [editable]="false"
      [label]="labelMap.get(8)!">

      <div *matStepContent>
        <h3>Tack för din ansökan!</h3>
        <p>Vi återkommer till dig inom 24 timmar (under kontorstid)</p>
        <p>Vänliga hälsningar</p>
        <p>Sparbanken Syd</p>
      </div>
    </mat-step>
    <mat-step
      [completed]="termsSelected"
      [editable]="true"
      [label]="labelMap.get(9)!">
      <div *matStepContent>
        <spb-offer></spb-offer>
      </div>
    </mat-step>
    <mat-step
      [completed]="kycCompleted"
      [editable]="true"
      [label]="labelMap.get(10)!">
      <div *matStepContent>
        <spb-offer-kyc></spb-offer-kyc>
      </div>
    </mat-step>
    <mat-step [label]="labelMap.get(allIsDone)!">
      <div *matStepContent>
        <h3>Nu förbereds dina lånehandlingar!</h3>
        <p>Det tar max 24 timmar (under kontorstid) innan dina lånehandlingar är klara för signering. Vi kontaktar dig
          via sms när handlingarna är redo och du signerar med BankID.</p>
      </div>
    </mat-step>
  </mat-stepper>

  @if (developerMode) {
    Har medsökande: {{ applicationService.coApplicant$ | async }}
    @if (applicationService.coApplicant$ | async) {
      <button (click)="applicationService.coApplicant$.next(false)">Ändra</button>
    } @else {
      <button (click)="applicationService.coApplicant$.next(true)">Ändra</button>
    }
    <spb-operators
      [applicationId]="applicationId"
      [caseId]="caseId"
    ></spb-operators>
  }
</div>