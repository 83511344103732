/**
 * The Path Selector is used to direct you to the proper
 * step depending on tha application status.
 *
 * Handles only 3 states,
 * Go to offer
 * Go to supplement
 * --> or go to update
 *
 * Offer and supplement have their states set individually
 *
 */
import {Router} from '@angular/router'
import {TBorgoApplicationStatus} from 'sparbanken-syd-borgo'
import {LOAN_ROUTE_PATH} from '../../../application/data-types'
import {inject, Injectable} from '@angular/core'

@Injectable(
  {providedIn: 'root'}
)
export class PathSelector {

  /**
   * The statuses where you will go to offering
   */
  private offerPathStatuses: TBorgoApplicationStatus[] = [
    'APPROVED_CREDIT_DECISION',
    'WAITING_CUSTOMER_ONBOARDED',
    'CREDIT_PREPARATION',
    'AGREEMENT_CREATED' // Move this to the signing page
  ]

  private supplementPathStatuses: TBorgoApplicationStatus[] = [
    'PENDING_CREDIT_DECISION',
    'REJECTED_CREDIT_DECISION'
  ]

  private router = inject(Router)

  public select(caseId: string, applicationId: string, status: TBorgoApplicationStatus): Promise<boolean> {

    if (this.offerPathStatuses.indexOf(status) !== -1) {
      return this.router.navigate(
        ['/', LOAN_ROUTE_PATH, 'erbjudande', caseId, applicationId])
    }

    /** Both pending and rejected takes you to komplettera (supplement). Komplettera
     * has its own deeper logic for the different detailed cases.
     */
    if (this.supplementPathStatuses.indexOf(status) !== -1) {
      return this.router
        .navigate(['/', LOAN_ROUTE_PATH, 'komplettera', caseId, applicationId])
    }

    // The default is to take you to the edit application page.
    return this.router
      .navigate(['/', LOAN_ROUTE_PATH, 'uppdatera', caseId, applicationId])
  }
}
