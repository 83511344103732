import {AfterContentChecked, Component, effect, ViewChild} from '@angular/core'
import {KycBaseItemComponent} from '../0-base/kyc-base-item.component'
import {Employment} from '../../../../model/employment/employment'
import {IKycEmploymentDataset} from 'sparbanken-syd-borgo'
import {AbstractControl, FormArray} from '@angular/forms'
import {EmploymentComponent} from '../../../../selectors/employer/employment.component'
import {KycItemEmployment} from '../../model/kyc-item-employment'

@Component({
  selector: 'spb-kyc-employment',
  templateUrl: './kyc-employment.component.html',
  styleUrl: '../kyc-items.scss',
  providers: [{provide: KycBaseItemComponent, useExisting: KycEmploymentComponent}]
})
export class KycEmploymentComponent extends KycBaseItemComponent implements AfterContentChecked {

  @ViewChild(EmploymentComponent) childComponent!: EmploymentComponent

  public kycEmployment: Partial<IKycEmploymentDataset> = {}

  public employment: Employment | undefined

  public override form = new FormArray<AbstractControl>([
  ])

  public kycItemEmployment!: KycItemEmployment

  constructor() {
    super()
    effect(() => {
      const input = this.input()
      // We are free to fiddle with this one, we will
      // still call "set value"
      this.kycItemEmployment = this.input().employment
      this.kycEmployment = input.employment.kycEmployment
      this.employment = input.employment.applicationEmployment

      this.kycItemEmployment.isValid$.subscribe(this.updateKyc)

      this.kycItemEmployment.value$.subscribe({
        next: () => {
          this.employment = input.employment.applicationEmployment
        }
      })
    })
  }

  /**
   * Note that this is called after every
   * change ot the inputs. Could be solved
   * by setting display: none perhaps.
   */
  public ngAfterContentChecked(): void {
    if (this.childComponent) {
      if (this.form.length === 0) {
        this.form.push(this.childComponent.form)
      }
    } else {
      if (this.form.length === 1) {
        this.form.removeAt(1)
      }
    }
  }

  /**
   * When we get values from the Employment form.
   * @param event
   */
  public update(event: Employment): void {
    KycItemEmployment.convertEmploymentToKyc(this.kycEmployment, event)
    this.input().employment.setValue(this.kycEmployment)
  }
}
