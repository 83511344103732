<div class="application">
  @if (developerMode) {
    <div class="developer-buttons">
      <button [routerLink]="['/', loanPath, 'start' ]" mat-raised-button>Byt</button>
      <button (click)="userService.logout()" color="primary" mat-raised-button>Logga ut</button>
      <button (click)="reset()" mat-flat-button>Rensa</button>
      <spb-create-application></spb-create-application>
    </div>
  }
  <spb-user-info [user]="applicationService.currentUser$()"></spb-user-info>

  @if (application) {
    <mat-stepper
      [style.max-width.px]="600"
      [linear]="true" orientation="vertical" spbStepperScroll>
      <mat-step
        [completed]="applicantsValid()"
        [label]="labelMap.get(1)!">
        <spb-applicant-section
          [input]="application.application"
          [applicationToSend]="applicationToSend"
          (valid)="applicantsValid.set($event)"
        ></spb-applicant-section>
        <div class="buttons">
          <button
            color="primary"
            mat-raised-button matStepperNext
            [disabled]="!applicantsValid()"
            type="button">Nästa
          </button>
        </div>
      </mat-step>

      <mat-step
        [completed]="childrenValid()"
        [label]="labelMap.get(2)!">
        <spb-children [initial]="children"
                      (resultValue)="setUpdated()"
                      (valid)="childrenValid.set($event)"
        ></spb-children>

        <div class="buttons">
          <button mat-raised-button matStepperPrevious>Tillbaka</button>
          <button color="primary"
                  mat-raised-button matStepperNext
                  [disabled]="!childrenValid()"
                  type="button">Nästa
          </button>
        </div>
      </mat-step>

      <mat-step
        [completed]="accommodationValid() && insuranceValid()"
        [label]="labelMap.get(3)!">
        <spb-collateral
          [selectCollateral]="selectCollateral()"
          (resultValue)="setCollateral($event)">
        </spb-collateral>

        <spb-collateral-loans
          (currentLoanIds)="setCurrentLoanIds($event)"
          [collateralId]="selectCollateral()">
        </spb-collateral-loans>

        <!-- This should be inside the "Household" component ??
            -- Or do we separate these?! one for the loan object and
             one for the others ? IDK.
        -->
        <spb-accommodation
          [primary]="true"
          [initial]="accommodation"
          (resultValue)="accommodation.toApplication(applicationToSend)"
          (valid)="accommodationValid.set($event)"
        ></spb-accommodation>
        <div class="spb-sub-section-header">Försäkring</div>
        <!-- We apparantly take insurance only on primary object -->
        @if (insurance) {
          <spb-insurance
            [house]="house"
            [initial]="insurance"
            (resultValue)="insurance.toApplication(applicationToSend)"
            (valid)="insuranceValid.set($event)"
            [primary]="true"
          ></spb-insurance>
        }
        <!--
         Valuation is temporarily off!
         @if (valuation) {
          <spb-valuation
            [initial]="valuation"
            (resultValue)="valuation.toApplication(applicationToSend)">
          </spb-valuation>
        }-->


        <div class="buttons">
          <button mat-raised-button matStepperPrevious>Tillbaka</button>
          <button color="primary"
                  [disabled]="!accommodationValid() || !insuranceValid()"
                  mat-raised-button matStepperNext type="button">Nästa
          </button>
        </div>
      </mat-step>
      <mat-step [label]="labelMap.get(4)!">
        <ng-template matStepContent>
          <spb-accommodations #valid
                              [input]="application.application"
                              [applicationToSend]="applicationToSend"
          ></spb-accommodations>
          <div class="buttons">
            <button mat-raised-button matStepperPrevious>Tillbaka</button>
            <button color="primary"
                    [disabled]="!valid.valid()"
                    mat-raised-button matStepperNext type="button">Nästa
            </button>
          </div>
        </ng-template>
      </mat-step>
      <mat-step [label]="labelMap.get(5)!" [completed]="loanAmountValid()">
        <spb-loan-amount
          [collateralLoans]="currentLoans"
          [initial]="loanAmount"
          (resultValue)="loanAmount.toApplication(applicationToSend)"
          (valid)="loanAmountValid.set($event)"
        ></spb-loan-amount>
        <spb-kyc-purpose
          [initial]="kycPurpose"
          (resultValue)="kycPurpose.toApplication(applicationToSend)"
          (valid)="kycValid.set($event)"
        ></spb-kyc-purpose>
        <div class="buttons">
          <button mat-raised-button matStepperPrevious>Tillbaka</button>
          <button color="primary"
                  [disabled]="!loanAmountValid() || !kycValid()"
                  mat-raised-button matStepperNext type="button">Nästa
          </button>
        </div>
      </mat-step>
      <mat-step [label]="labelMap.get(6)!">
        @if (applicationEditable(application.applicationStatus)) {
          <div>
            <spb-select-advice
              [selected]="null"
              (selection)="selectAdvice($event)"
            ></spb-select-advice>
          </div>
          <div class="buttons">
            @if (developerMode) {
              <button mat-stroked-button color="primary"
                      (click)="clicker.next(false)">Spara
              </button>
            }
            <button mat-raised-button color="primary"
                    [disabled]="loanAdviceSelected === null"
                    (click)="clicker.next(true)">Ansök
            </button>
          </div>
        }
      </mat-step>
      @for (s of [7, 8, 9, 10, 11]; track $index) {
        <mat-step
          [editable]="false"
          [label]="labelMap.get(s)!"></mat-step>
      }
    </mat-stepper>
  }
</div>


<!--- -->
@if (developerMode) {
  <h1 [style.margin-top.px]="150">Teststrunt, ignorera detta</h1>
  {{ application?.applicationStatus }}
  {{ application?.statusOverview }}
  @if (application) {
    <spb-operators
      [caseId]="application.caseId"
      [applicationId]="application.applicationId"
    ></spb-operators>
  }
  <div *ngIf="!application">
    Update väntar på application
    <button mat-raised-button color="primary" [routerLink]="['..', 'start']">Login</button>
  </div>

  <div [style.max-width.px]="600">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ansökan att skicka
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <pre> {{ applicationToSend | json }}</pre>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Ansökan från Borgo
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <pre>{{ application | json }}</pre>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
}