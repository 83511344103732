import {Component, EventEmitter, Output} from '@angular/core'
import {FormControl} from '@angular/forms'
import {filter} from 'rxjs'

export interface ITestUser {
  name: string
  sub: string
  partyId: string
  applicationId: string
  caseId: string
}

@Component({
  selector: 'spb-test-user',
  templateUrl: './test-user.component.html'
})
export class TestUserComponent {

  @Output() selected = new EventEmitter<ITestUser>()

  public testUsers: ITestUser[] = [
    {
      name: 'Maria Eriksson',
      sub: '196705105242',
      partyId: 'c4b529a1-0e51-4eda-9f79-ebde1c7e0e1b',
      applicationId: '0D92312E2C90479FE0630100007F5784',
      caseId: '0D923126E62C476CE0630100007F9D7A'
    },
    {
      name: 'Solveig',
      sub: '194706195460',
      partyId: '5263fc18-dfb2-441e-bb2e-f72d4c5a2936',
      applicationId: '0D79FB0413D16189E0630100007F278C',
      caseId: '0D79FAF555AE60ABE0630100007FAAA9'
    },
    {name: 'Lac Adolfosson', sub: '197201067373', partyId: '', applicationId: '', caseId: ''}
  ]

  public control = new FormControl<ITestUser | null>(null)

  constructor() {
    this.control.valueChanges.pipe(
      filter(Boolean)
    ).subscribe({
      next: (val: ITestUser) => {
        this.selected.emit(val)
      }
    })
  }
}
