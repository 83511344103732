import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose} from '@angular/material/dialog'
import {MatButton} from '@angular/material/button'
import {ProblemSolver} from './problem-solver'

@Component({
  selector: 'spb-apply-error',
  standalone: true,
  imports: [
    MatDialogActions,
    MatButton,
    MatDialogClose
  ],
  templateUrl: './apply-error.component.html',
  styleUrl: './apply-error.component.scss'
})
export class ApplyErrorComponent {

  public solver: ProblemSolver

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.solver = new ProblemSolver(data)
  }


}
