<div [style.margin-top.px]="20" class="holder">
  @for (applicant of applicants; track $index) {
    <div class="row-holder">
      <div class="heading">{{ $index === 0 ? 'Sökande' : 'Medsökande' }}</div>
      @for (cost of applicant.monthlyCost; track $index) {
        <div class="row-item">
          <div>Studielån:</div>
          <div>{{ cost.amount | number: '1.0-0' :'fr' }} kr / mån</div>
        </div>
      } @empty {
        <div class="row-item">
          <div>Studielån:</div>
          <div>Nej</div>
        </div>
      }


      <div class="row-item">
        <div>Borgensåtagande:</div>
        @if (applicant.obligations?.guaranteeCommitmentAmount) {
          <div>{{ applicant.obligations?.guaranteeCommitmentAmount | number: '1.0-0' :'fr' }} kr</div>
        } @else {
          <div>Nej</div>
        }
      </div>


      <div class="row-item">
        <div>Uppskov bostadsförs.:</div>
        @if (applicant.obligations?.guaranteeCommitmentAmount) {
          <div>{{ applicant.obligations?.taxDefermentAmount | number: '1.0-0' :'fr' }} kr</div>
        } @else {
          <div>Nej</div>
        }
      </div>


      @if (applicant.obligations?.loan && applicant.obligations!.loan!.length > 0) {
        <div class="sub-heading">Privatlån</div>
        @for (loan of applicant.obligations?.loan; track $index) {
          <div [style.margin-bottom.px]="10">
            <div class="row-item">
              <div>Typ:</div>
              <div>{{ loan.type | loan }}</div>
            </div>
            <div class="row-item">
              <div>Långivare:</div>
              <div>{{ loan.creditor }}</div>
            </div>
            <div class="row-item">
              <div>Belopp:</div>
              <div>{{ loan.amount | number : '1.0-0': 'fr' }} kr</div>
            </div>
            <div class="row-item">
              <div>Ränta:</div>
              <div>{{ loan.interestRate | number: '1.2-2' : 'fr' }} %</div>
            </div>

            <div class="row-item">
              <div>Amortering:</div>
              <div>{{ loan.monthlyAmortization | number: '1.0-0':'fr' }} kr / mån</div>
            </div>
          </div>
        } @empty {
          <div>Inga lån</div>
        }
      }

      @if (applicant.obligations?.credit && applicant.obligations?.credit!.length > 0) {
        <div class="sub-heading">Övriga krediter</div>
        @for (loan of applicant.obligations?.credit; track $index) {
          <div [style.margin-bottom.px]="10">
            <div class="row-item">
              <div>Typ:</div>
              <div>{{ loan.type | credit }}</div>
            </div>
            <div class="row-item">
              <div>Långivare:</div>
              <div>{{ loan.creditor }}</div>
            </div>
            <div class="row-item">
              <div>Belopp:</div>
              <div>{{ loan.amount | number : '1.0-0': 'fr' }} kr</div>
            </div>
            <div class="row-item">
              <div>Ränta:</div>
              <div>{{ loan.interestRate | number: '1.2-2' : 'fr' }} %</div>
            </div>
          </div>
        }
      }
    </div>
  }
</div>