<div class="holder">
  <div class="row-holder">
    <div class="row-item">
      <div>Belopp att låna:</div>
      <div>{{ newLoan | number: '1.0-0': 'fr' }} kr</div>
    </div>
    <div class="row-item">
      <div>Ny total skuld:</div>
      <div>{{ application.application.appliedCredit.totalCreditAmount | number: '1.0-0': 'fr' }} kr</div>
    </div>
    <div class="row-item">
      <div>Syfte</div>
      <div>{{ application.application.complementaryData.kyc.purpose | purpose }}</div>
    </div>
  </div>
</div>
