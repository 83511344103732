<form [formGroup]="form">
  <spb-selector
    [ctrl]="form.controls.employmentType"
    [items]="employers"
    label="Sysselsättning"
  ></spb-selector>
  @if (form.controls.employmentType.value === 'PERMANENT_EMPLOYED' || form.controls.employmentType.value === 'TEMPORARY_EMPLOYED') {
    <mat-form-field>
      <mat-label>Anställningsdatum</mat-label>
      <input formControlName="employedStartDate"
             [matDatepicker]="picker"
             [max]="maxDate"
             matInput>
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  }
  @if (form.controls.employmentType.value === 'TEMPORARY_EMPLOYED') {
    <mat-form-field>
      <mat-label>Slutdatum för anställning</mat-label>
      <input formControlName="temporaryEmployedEndDate"
             [matDatepicker]="picker"
             matInput>
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  }
  @if (form.controls.employmentType.value === 'PERMANENT_EMPLOYED' || form.controls.employmentType.value === 'TEMPORARY_EMPLOYED') {
    <mat-form-field>
      <mat-label>Arbetsgivare</mat-label>
      <input formControlName="employerName" matInput>
    </mat-form-field>
  }

  @if (form.controls.employmentType.value === 'SELF_EMPLOYED') {
    <spb-yes-no-input
      (resultValue)="form.controls.typeSelfEmployedAbOrgnr.setValue($event)"
      (resultAnswer)="form.controls.isEgenForetagare.setValue($event)"
      [ctrl]="form.controls.typeSelfEmployedAbOrgnr"
      inputLabel="Organisationsnummer"
      [numberField]="false"
      yesNoLabel="Är du anställd i eget aktiebolag?"
    ></spb-yes-no-input>
  }
</form>