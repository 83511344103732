import {Component, Input, OnChanges} from '@angular/core'
import {InsuranceCompanyMap} from '@sparbanken-syd/borgo-helpers'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {SelectorBaseComponent} from '../selector-base.component'
import {TInsuranceCompany} from 'sparbanken-syd-borgo'
import {startWith} from 'rxjs'
import {IInsurance, Insurance} from '../../model/insurance/insurance'

@Component({
  selector: 'spb-insurance',
  templateUrl: './insurance.component.html',
  styleUrl: '../selectors.scss'
})
export class InsuranceComponent extends SelectorBaseComponent<Insurance> implements OnChanges {

  /**
   * Do not show detailed values about insurance when
   * it is not a house?
   */
  @Input() house: boolean = true

  /**
   * If this is the primary property/house/apartment
   * then insurance is mandatory and we select yes.
   *
   * This is only true if we are in an INCREASE scenario
   */
  @Input({required: true}) primary: boolean = false

  protected readonly companies = InsuranceCompanyMap

  public override form = new FormGroup({
    insured: new FormControl<boolean | null>(null, {
      nonNullable: true, validators: [
        Validators.required
      ]
    }),
    company: new FormControl<TInsuranceCompany>('OTHER_INSURANCE_COMPANY', {nonNullable: true}),
    insuranceAmount: new FormControl<number | null>(null),
    insuranceExcessAmount: new FormControl<number | null>(null),
    fullValue: new FormControl<boolean | null>(null, {nonNullable: true}),
    companyName: new FormControl<string | null>(null)
  })

  constructor() {
    super()
    this.form.valueChanges
      .pipe(startWith({insured: null} as Partial<IInsurance>))
      .subscribe({
        next: r => {
          this.setValidatorsIfRequired(r.insured, this.form.controls.company, [Validators.required])
          this.setValidatorsIfRequired(r?.company === 'OTHER_INSURANCE_COMPANY', this.form.controls.companyName, [Validators.required])

          if (this.house && r.insured) {
            this.setValidatorsIfRequired(!r.fullValue, this.form.controls.insuranceAmount, [Validators.required])
            this.setValidatorsIfRequired(true, this.form.controls.insuranceExcessAmount, [Validators.required])
          } else {
            this.setValidatorsIfRequired(false, this.form.controls.insuranceAmount, [Validators.required])
            this.setValidatorsIfRequired(false, this.form.controls.insuranceExcessAmount, [Validators.required])
          }
        }
      })
  }

  public override ngOnChanges() {
    super.ngOnChanges()
    if (this.primary) {
      this.form.controls.insured.setValue(true)
    }
  }
}
