<form [formGroup]="form">
  <spb-selector
    [ctrl]="form.controls.creditor"
    [items]="creditors"
    label="Långivare"
  ></spb-selector>
  @if (form.controls.creditor.value === 'OTHER_CREDITOR') {
    <mat-form-field>
      <mat-label>Namn på långivare</mat-label>
      <input matInput formControlName="otherCreditorName" type="text">
    </mat-form-field>
  }
</form>