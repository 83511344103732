import {IKycPersonDataset} from 'sparbanken-syd-borgo'
import {signal} from '@angular/core'
import {ReplaySubject, Subject} from 'rxjs'

export class KycItem<T> {

  /**
   * A signal that signals that any value has changed.
   */
  public changes$ = signal<boolean>(false)

  /**
   * If we consider this input really valid.
   */
  public isValid$ = new ReplaySubject<boolean>(1)

  /**
   * A return value that can be used to "reset" to original
   * value
   */
  public value$ = new Subject<T>()

  constructor(protected input: Partial<IKycPersonDataset> = {}) {
  }

  public reset(): void {
  }
}
