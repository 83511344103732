import {Component, input} from '@angular/core'
import {KycPersonDataset} from '../model/kyc-person-dataset'

@Component({
  selector: 'spb-kyc-items',
  templateUrl: './kyc-items.component.html',
  styleUrl: './kyc-items.component.scss'
})
export class KycItemsComponent {
  /**
   * The input kyc
   */
  public data = input.required<KycPersonDataset>()
  /**
   * Is this the primary KYC (from the application) or not, that is
   * a KYC done for another person.
   */
  public primary = input.required<boolean>()
}
