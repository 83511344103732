import {Component, Input, OnInit} from '@angular/core'
import {ControlValueAccessor, FormControl, Validators} from '@angular/forms'
import {filter} from 'rxjs'

/**
 * A "radio" selector, asking yes / no (Ja Nej) but sets the
 * output to true | false so that we can keep our models clean.
 *
 * It acts like a "checkbox" but looks like a radio,
 *
 * Formats the radio properly as well. good stuff.
 */
@Component({
  selector: 'spb-yes-no-radio',
  templateUrl: './yes-no-radio.component.html'
  /*providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => YesNoRadioComponent),
      multi: true
    }
  ]*/
})
export class YesNoRadioComponent implements OnInit, ControlValueAccessor {
  /**
   * This is the same as you would put in on a regular material
   * input or something. We will set this to true or false
   * when we have a value.
   */
  @Input({required: true}) externalControl!: FormControl<boolean | null>

  /**
   * The question you want to ask, like "Are you a member of A-Kassa?"
   */
  @Input({required: true}) leadText: string = ''

  /**
   * IF you for some reason have more to say.
   */
  @Input() explanation: string | null = null
  /**
   * The internal control that is Ja or Nej or null.
   *
   * Make this public for easy testing
   */
  public ctrl = new FormControl<'ja' | 'nej' | null>(null,
    {
      validators: Validators.required
    })

  /**
   * OK if I use the default value accessor it emits shite
   * so let us not. Create a "noopValueAccessor.
   */
  writeValue(): void {
  }

  registerOnChange(): void {
  }

  registerOnTouched(): void {
  }

  setDisabledState(): void {
  }

  /**
   * Initial set up after creation. If the control is replaced
   * (ngOnChanges) new controls will _not_ be configured
   */
  public ngOnInit(): void {
    if (this.externalControl.value !== null) {
      this.ctrl.setValue(this.externalControl.value ? 'ja' : 'nej', {emitEvent: false})
    }

    /**
     * Sets the external control to true/false
     * if we have a selection
     */
    this.ctrl.valueChanges.pipe(
      filter(Boolean)
    ).subscribe({
      next: (r) => {
        this.externalControl.setValue(r === 'ja')
      }
    })

    // Change our status based on the control but only if
    // not already the same value.
    this.externalControl.valueChanges.pipe().subscribe({
      next: (v) => {
        if (v === null) {
          this.ctrl.reset()
        }
        if (v === true) {
          this.ctrl.setValue('ja', {emitEvent: false})
        }
        if (v === false) {
          this.ctrl.setValue('nej', {emitEvent: false})
        }
      }
    })
  }
}
