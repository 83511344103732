import {Component, inject, OnInit} from '@angular/core'
import {RoBaseComponent} from '../0-base/ro-base.component'
import {IBorgoLoanObject} from 'sparbanken-syd-borgo'
import {DecimalPipe, JsonPipe} from '@angular/common'
import {CollateralComponent} from '../../selectors/collaterals/collateral.component'
import {ReadOnlyService} from '../service/read-only.service'
import {InsuranceCompanyPipe, PropertyTypePipe} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-ro-main-property',
  standalone: true,
  imports: [
    JsonPipe,
    InsuranceCompanyPipe,
    CollateralComponent,
    DecimalPipe,
    PropertyTypePipe
  ],
  templateUrl: './ro-main-property.component.html',
  styleUrl: '../read-only.scss'
})
export class RoMainPropertyComponent extends RoBaseComponent implements OnInit {

  public loanObject: IBorgoLoanObject | undefined

  public sumOfLoans: number = 0

  private service = inject(ReadOnlyService)

  public override ngOnInit() {
    super.ngOnInit()

    this.service.loadData(this.application.application).subscribe({
      next: value => {
        this.loanObject = this.application.application.loanObject
        this.sumOfLoans = value.sum
      }
    })
  }
}
