<spb-yes-no-radio
  [externalControl]="haveCtrl"
  leadText="{{ 'Har ' + ('du/ni' | plr | async) + ' andra boende?'}}"
></spb-yes-no-radio>
@if (haveCtrl.value) {
  @for (accommodation of accommodations; track $index) {
    <mat-card>
      <mat-card-content>
        <div class="spb-sub-section-header">Uppgifter om boendet</div>
        <spb-accommodation
          (resultValue)="accommodation.toApplication(applicationToSend)"
          [initial]="accommodation"
          [primary]="false"
          (valid)="setValid($event, $index+1)"
        ></spb-accommodation>
      </mat-card-content>
      <mat-card-actions>
        <button (click)="removeAccommodation($index)" color="warn" mat-flat-button>
          <mat-icon>delete</mat-icon>
          Ta bort bostaden
        </button>
      </mat-card-actions>
    </mat-card>
  }
  <div class="buttons">
    <button
      [disabled]="!allValid()"
      mat-raised-button (click)="addAccommodation()">
      <mat-icon>add</mat-icon>
      Fler boenden
    </button>
  </div>
}
