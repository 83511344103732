@if (alternative.possible && alternative.rule === 'NONE') {
  <div class="ingress">
    Enligt de lagstadgade reglerna för amorteringskrav kan du välja mellan
    att amortera {{ alternative.percentage }} procent på det totala
    lånebeloppet (befintliga lån + beloppet du söker i det här ärendet) eller att enbart beräkna amorteringskravet på
    höjningen, och då amorterar du av det på 10 år (10 procent per år).
  </div>
  <div>Alternativ 1 – huvudregeln: du måste minst amortera <b>{{ alternative.ruleMortgage | number: '1.0-0': 'fr' }}</b>
    kr / månad <span>@if (alternative.ruleMortgage === 0) {
      <sup>*</sup>
    }</span>
  </div>
  <div>Alternativ 2 – alternativregeln (”tilläggslån”): du måste
    amortera <b>{{ alternative.alternativeMortgage | number: '1.0-0': 'fr' }}</b> kr / mån
  </div>
} @else if (alternative.possible && alternative.hasAlternative) {
  <div class="ingress">
    Enligt de lagstadgade reglerna för amorteringskrav kan du välja mellan att amortera {{ alternative.percentage }}
    procent på det totala
    lånebeloppet (befintliga lån + beloppet du söker i det här ärendet) eller att enbart beräkna amorteringskravet på
    höjningen, och då amorterar du av det på 10 år (10 procent per år).
  </div>
  <div>
    Alternativ 1 – huvudregeln: du måste minst amortera <b>{{ alternative.ruleMortgage | number: '1.0-0': 'fr' }}</b> kr
    /
    mån<sup>*</sup>
    Alternativ 2 – alternativregeln (”tilläggslån”): du måste
    amortera <b>{{ alternative.alternativeMortgage | number: '1.0-0': 'fr' }}</b> kr / mån
  </div>

} @else {
  <div>
    Enligt de lagstadgade reglerna för amorteringskrav måste du amortera
    minst <b>{{ alternative.ruleMortgage | number: '1.0-0': 'fr' }}</b> kr / mån.
  </div>
}
@if (alternative.ruleMortgage === 0) {
  <div class="disclaimer">*) om minsta belopp att amortera är 0 kr innebär det att du redan amorterar
    tillräckligt på dina
    befintliga
    lån för att uppfylla Finansinspektionens regler om amorteringskrav.
  </div>
}