<form [formGroup]="form">
  <spb-selector
    [ctrl]="form.controls.type"
    [items]="bankAssetTypes"
    label="Typ av tillgång"
  ></spb-selector>
  <mat-form-field>
    <mat-label>Bank/Förvaltare/Institut</mat-label>
    <input formControlName="bankName" matInput>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Värde</mat-label>
    <input formControlName="amount" matInput spbFormatNumber>
    <span matTextSuffix>kr</span>
  </mat-form-field>
</form>