/**
 *
 */
import {FactoryProvider, InjectionToken} from '@angular/core'

/**
 * Defines and exports an injection token that replaces the browser
 * file reader.
 *
 * FILE_READER is just name, it could be FOO_BAR
 * `export` is to make it available outside this file
 * `const` is just to declare that it is not supposed to be overridden.
 *
 * InjectionToken is what we want to achieve (we want to inject what ever)
 *
 * <FileReader> just tells what we _intend_ to return. In this case it is the
 * browser (dom) fileReader
 *
 * The "fileReader" is just a name for debugging can be anything.
 */
export const FILE_READER = new InjectionToken<FileReader>('fileReader')

/**
 * The provider is a "factoryProvider". A factory is just a way
 * to resolve things in runtime. If we prove a concrete class
 * it will be the same always. As we do it here we could
 * just as well skip the factory b/c it will always
 * return the same thing, a concrete FileReader.
 */
export const fileReaderProvider: FactoryProvider = {
  provide: FILE_READER,
  useFactory: () => new FileReader()
  // If we want to have many, if one is multi
  // All will have to be multi
  // multi: true
}
