import {Component} from '@angular/core'
import {SelectorBaseComponent} from '../../selector-base.component'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {TBorgoChildCustody} from 'sparbanken-syd-borgo'
import {Child} from '../../../model/children/child'
import {ChildCustodyMap, ChildrenAgeMap, TChildrenAge} from '@sparbanken-syd/borgo-helpers'
import {filter} from 'rxjs'

@Component({
  selector: 'spb-child',
  templateUrl: './child.component.html',
  styleUrls: ['../../selectors.scss']
})
export class ChildComponent extends SelectorBaseComponent<Child> {

  protected childrenAges = ChildrenAgeMap
  protected childrenCustody = ChildCustodyMap


  public override form = ChildComponent.generateForm()

  public static generateForm() {
    return new FormGroup({
      age: new FormControl<TChildrenAge | null>(null, {validators: [Validators.required]}),
      custody: new FormControl<TBorgoChildCustody | null>(null, {validators: [Validators.required]}),
      income: new FormControl<boolean | null>(null, {validators: [Validators.required]}),
      cost: new FormControl<boolean | null>(null, {validators: [Validators.required]}),
      shared: new FormControl<boolean | null>(null,
        {nonNullable: true, validators: [Validators.required]})
    })
  }

  constructor() {
    super()
    this.form.controls.shared.valueChanges
      .pipe(filter((v): v is boolean => v !== null))
      .subscribe({
        next: v => {
          if (!v) {
            this.form.controls.income.setValue(false)
            this.form.controls.cost.setValue(false)
            this.form.controls.custody.setValue('100')
          } else {
            this.form.controls.income.setValue(this.initial.income ?? null)
            this.form.controls.cost.setValue(this.initial.cost ?? null)
            this.form.controls.custody.setValue(this.initial.custody ?? null)
          }
        }
      })
  }
}
