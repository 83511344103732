<mat-expansion-panel expanded="false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="title">Läs för mer information om PEP</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *matExpansionPanelContent>
    <p>Med en person i politiskt utsatt ställning menas en person som har
      eller tidigare har haft en viktig offentlig funktion i en stat eller i
      ledningen i en internationell organisation.</p>
    <p>Med viktig offentlig funktion avses:</p>
    <ol>
      <li>statschef</li>
      <li>regeringschef</li>
      <li>minister</li>
      <li>vice minister eller biträdande minister</li>
      <li>parlamentsledamot</li>
      <li>ledamot i styrelsen för politiska partier</li>
      <li>domare i högsta domstolen</li>
      <li>domare i en konstitutionell domstol eller i ett motsvarande
        rättsorgan på hög nivå vars beslut endast undantagsvis kan överklagas
      </li>
      <li>högre tjänsteman vid revisionsmyndighet och ledamot i
        centralbanks styrande organ
      </li>
      <li>ambassadör, beskickningschef samt hög officerare i
        försvarsmakten
      </li>
      <li>person som ingår i statsägda företags förvaltnings-, lednings- eller
        kontrollorgan
      </li>
      <li>direktör, biträdande direktör eller styrelseledamot i en
        internationell sammanslutning.
      </li>
    </ol>
    <p>Som familjemedlem till en person i politiskt utsatt ställning betraktas:</p>
    <ol>
      <li>make/maka, registrerad partner eller sambo</li>
      <li>barn och deras make/maka, registrerad partner eller sambo</li>
      <li>föräldrar.</li>
    </ol>
    <p>Som nära medarbetare till en person i politiskt utsatt ställning
      betraktas:</p>
    <ol>

      <li>fysisk person som, enligt vad som är känt eller finns anledning att
        förmoda, gemensamt med en person i politiskt utsatt ställning är
        verklig huvudman till en juridisk person eller juridisk konstruktion
        eller som på annat sätt har eller har haft nära förbindelser med en
        person i politiskt utsatt ställning
      </li>
      <li>fysisk person som ensam är verklig huvudman till en juridisk
        person eller juridisk konstruktion som, enligt vad som är känt eller
        finns anledning att förmoda, egentligen har upprättats till förmån för
        en person i politiskt utsatt ställning
      </li>

    </ol>
  </div>
</mat-expansion-panel>