import {Component, input} from '@angular/core'
import {IBorgoInterestListPrice} from 'sparbanken-syd-borgo'
import {ApplicationLoanBindingMap} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-interest-list',
  templateUrl: './interest-list.component.html',
  styleUrl: './interest-list.component.scss'
})
export class InterestListComponent {
  public today = new Date()
  protected loanBindingMap = ApplicationLoanBindingMap
  public interests = input.required<IBorgoInterestListPrice[]>()
}
