<form [formGroup]="form">
  @if (!primary) {
    <spb-yes-no-radio
      [externalControl]="form.controls.insured"
      leadText="Är bostaden som ska belånas försäkrad?"
    ></spb-yes-no-radio>
  }
  @if (form.controls.insured.value) {
    <spb-selector
      [ctrl]="form.controls.company"
      [items]="companies"
      label="Försäkringsbolag"
    ></spb-selector>
  }

  @if (form.controls.company.value === 'OTHER_INSURANCE_COMPANY') {
    <mat-form-field>
      <mat-label>Ange försäkringsbolag</mat-label>
      <input matInput formControlName="companyName" type="text">
    </mat-form-field>
  }
  @if (form.controls.insured.value && house) {
    <spb-yes-no-radio
      [externalControl]="form.controls.fullValue"
      leadText="Är bostaden fullvärdesförsäkrat?"
    ></spb-yes-no-radio>
  }
  @if (form.controls.fullValue.value === false && form.controls.insured.value && house) {
    <mat-form-field>
      <mat-label>Försäkringsbelopp</mat-label>
      <input matInput spbFormatNumber formControlName="insuranceAmount" type="text">
      <span matTextSuffix>kr</span>
    </mat-form-field>
  }
  @if (form.controls.insured.value && house) {
    <mat-form-field>
      <mat-label>Självrisk</mat-label>
      <input matInput spbFormatNumber formControlName="insuranceExcessAmount" type="text">
      <span matTextSuffix>kr</span>
    </mat-form-field>
  }
</form>