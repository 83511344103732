import {Component, Input} from '@angular/core'
import {IBorgoApplicationListItem} from 'sparbanken-syd-borgo'

@Component({
  selector: 'spb-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})
export class SelectComponent {
  @Input({required: true}) application: IBorgoApplicationListItem = {} as any
}
