@if (kycPerson) {
  @if (!savedKyc) {
    <p>Uppgifterna är hämtade från din tidigare kundkännedom alt. uppdaterade med ny information från din pågående
      kreditansökan. Saknas någon uppgift markeras avsnittet med ett <span class="x-icon">X</span>, var vänlig att
      komplettera genom att trycka på
      Ändra.</p>
    <p>Tryck även ändra och uppdatera om någon uppgift är inaktuell.</p>
    <p>Uppgifter om anställning och inkomst kan ej ändras då aktuella uppgifter redan är registrerade tidigare i
      ansökan.</p>
    <spb-kyc-items
      [data]="kycPerson"
      [primary]="true"
    ></spb-kyc-items>

    <button (click)="setKyc()"
            [disabled]="(kyc.allGood$ | async) !== true"
            color="primary" mat-raised-button>Spara
    </button>
  } @else {
    <h4>Tack!</h4>
    <p>Dina lämnade uppgifter till en uppdaterad kundkännedom kommer att skickas digitalt till dig för godkännande med
      mobilt BankID. Detta godkännande kommer att ske samtidigt som du signeringar och godkänner övriga lånehandlingar.
      Vi kommer att meddela dig när det finns dokument att signera.</p>
    <button mat-raised-button
            color="primary"
            (click)="next()">Nästa
    </button>
  }
} @else {
  <spb-progress-ng></spb-progress-ng>
}