import {Base} from '../base/base'
import {IBorgoApplicantCost, IBorgoApplication, TBorgoCostType} from 'sparbanken-syd-borgo'

/**
 * Borgo have "cost" but currently only studielån
 */
export interface ICost {
  selected?: boolean | null
  cost: number | null
  type: TBorgoCostType
}

export class Cost extends Base implements ICost {
  selected?: boolean | null | undefined = null
  cost: number | null = null
  type: TBorgoCostType = 'STUDENT_LOAN'

  /**
   * We assume initially that cost is one per applicant. This
   * may change in the future to several, more like children. Beware.
   * @param application
   * @param index - The applicant index
   */
  constructor(application: Partial<IBorgoApplication> = {}, private index: number = 0) {
    super(application)
    this.setFrom(application, index)
  }

  public override toApplication(application: Partial<IBorgoApplication>) {
    if (application.applicants && application.applicants[this.index] && this.isValid()) {
      delete application.applicants[this.index].monthlyCost
      // We now it is a proper object sine we check valid above
      // Note how we rely on only one cost here.
      if (this.selected) {
        application.applicants[this.index].monthlyCost = [this.toBorgo()] as IBorgoApplicantCost[]
      }
    }
  }

  /**
   * A simple converter that refuses to return items
   * that are not valid.
   */
  public toBorgo(): IBorgoApplicantCost | null {
    if (this.isValid()) {
      return {
        type: this.type,
        amount: this.cost as number,
        currency: 'SEK'
      }
    }
    return null
  }

  public override isValid(): boolean {
    return typeof this.selected === 'boolean'
  }

  private setFrom(application: Partial<IBorgoApplication>, index: number): void {
    this.getApplicantPropertyOrNot<IBorgoApplicantCost[]>(application, index, 'monthlyCost')
      .forEach((costs) => {
        this.selected = costs.length === 1
        costs.forEach(c => this.cost = c.amount)
      })
  }
}
