import {Component, Input} from '@angular/core'
import {ApplicationLoanBindingMap} from '@sparbanken-syd/borgo-helpers'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {SelectorBaseComponent} from '../selector-base.component'
import {TApplicationLoanBinding, TBorgoCreditor} from 'sparbanken-syd-borgo'

import {Creditor, ICreditor} from '../../model/creditor/creditor'

export interface ILoan extends ICreditor {
  loanNumber: string
  amortization: number
  debt: number
  binding: TApplicationLoanBinding
}

@Component({
  selector: 'spb-loan',
  templateUrl: './loan.component.html',
  styleUrl: '../selectors.scss'
})
export class LoanComponent extends SelectorBaseComponent<ILoan> {
  /**
   * In case the one holding this wants to know.
   */
  @Input() index: number = 0

  protected creditor = new Creditor()

  protected readonly bindings = ApplicationLoanBindingMap

  protected override form = LoanComponent.GenerateForm()

  public static GenerateForm() {
    return new FormGroup({
      creditor: new FormControl<TBorgoCreditor | string | null>(null, {
        nonNullable: true, validators: [
          Validators.required
        ]
      }),
      otherCreditorName: new FormControl<string>('', {nonNullable: true, validators: []}),
      loanNumber: new FormControl<string>('', {nonNullable: true, validators: []}),
      amortization: new FormControl<number>(0, {nonNullable: true, validators: []}),
      debt: new FormControl<number>(0, {
        nonNullable: true,
        validators: [Validators.min(1)]
      }),
      binding: new FormControl<TApplicationLoanBinding | null>(null, {
        nonNullable: true, validators: [
          Validators.required
        ]
      })
    })
  }

  public setResult(v: Creditor): void {
    this.form.controls.creditor.setValue(v.otherCreditorName ?? v.creditor!)
  }

  public creditorValid(v: boolean): void {
    this.othersValid = v
    this.valid.emit(this.othersValid && this.form.valid)
    if (!this.creditor.isValid()) {
      this.form.controls.creditor.reset()
    }
  }
}
