import {IBorgoCreditBreakdownRequirements, TBorgoRuleFramework} from 'sparbanken-syd-borgo'

export class Alternative {

  /**
   * True or false if Alternative rule is possible
   */
  public possible = false

  public rule: TBorgoRuleFramework = 'NONE'

  /**
   * The percentage used
   */
  public percentage: number = 0

  public ruleMortgage: number = 0

  public alternativeMortgage: number = 0

  public hasAlternative: boolean = false

  constructor(requirements: IBorgoCreditBreakdownRequirements, actual?: number | null) {
    /**
     * The values are the same for all loans
     */
    this.percentage = requirements.strictRulePercentage

    this.ruleMortgage = actual ?? Math.max(requirements.strictRuleMonthlyAmortization -
      requirements.amortizationBasisTotalMonthlyAmortization, 0)

    this.alternativeMortgage = requirements.alternativeRuleIncreaseMonthlyAmortization

    this.rule = requirements.currentRuleFramework

    this.hasAlternative = requirements.alternativeRuleIncreaseCreditAmount > 0
    /**
     * If no previous requirement then we can
     * offer a loan
     */
    if (requirements.currentRuleFramework === 'NONE') {
      this.possible = true
    }

    /**
     * On loans before 2018 we can always choose alternative
     * rule.
     */
    if (requirements.currentRuleFramework === 'LTV') {
      this.possible = true
    }


    /**
     * If LTV_LTI AND there is an existing loan on Alternative rule
     * we can also offer Alternative!
     */
    if (requirements.currentRuleFramework === 'LTV_LTI' &&
      requirements.alternativeRuleIncreaseCreditAmount > 0) {
      this.possible = true
    }
  }
}
