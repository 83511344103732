import {Component, Input} from '@angular/core'
import {sortOriginal} from '../../../../application/helpers'
import {FormControl} from '@angular/forms'
import {IBorgoSelectionItem} from '@sparbanken-syd/borgo-helpers'

/**
 * Take in a selection map and a control and make a selection
 */
@Component({
  selector: 'spb-selector',
  templateUrl: './selector.component.html'
})
export class SelectorComponent<T> {

  @Input({required: true}) label: string = ''

  @Input({required: true}) items: Map<T, IBorgoSelectionItem> = new Map<T, IBorgoSelectionItem>()

  @Input() ctrl: FormControl<T | null> = new FormControl<T | null>(null, {nonNullable: true})

  /**
   * Keep maps sorted
   */
  protected readonly sortOriginal = sortOriginal
}
