import {Component, effect, Input} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {SelectorBaseComponent} from '../selector-base.component'
import {IContact} from '../../model/contact/contact'
import {ApplicationService} from '../../../services/application.service'

@Component({
  selector: 'spb-contact',
  templateUrl: './contact.component.html',
  styleUrl: '../selectors.scss'
})
export class ContactComponent extends SelectorBaseComponent<IContact> {
  @Input({required: true}) primary!: boolean
  protected override form = new FormGroup({
    email: new FormControl<string>('', {
      nonNullable: true, validators:
        [Validators.required, Validators.email,
          Validators.pattern(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]
    }),
    phone: new FormControl<string>('', {
      nonNullable: true, validators: [Validators.required,
        Validators.minLength(9)]
    })
  })

  constructor(applicationService: ApplicationService) {
    super()
    effect(() => {
      if (this.primary) {
        const contact = applicationService.currentUser$()
        contact.emails.forEach(e => {
          this.form.controls.email.setValue(e)
        })
        contact.phones.forEach(p => this.form.controls.phone.setValue(p))
      }
    })
  }
}
