<form>
  <spb-selector
    [ctrl]="form.controls.purpose"
    [items]="purposes"
    label="Syfte med ansökan"
  ></spb-selector>
  @if (form.controls.purpose.value === '4') {
    <mat-form-field>
      <mat-label>Ange syfte</mat-label>
      <input matInput [formControl]="form.controls.purposeDescription"/>
    </mat-form-field>
  }
</form>