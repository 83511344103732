import {Component, Input} from '@angular/core'
import {Alternative} from './alternative'

@Component({
  selector: 'spb-alternative',
  templateUrl: './alternative.component.html',
  styleUrl: './alternative.component.scss'
})
export class AlternativeComponent {
  @Input({required: true}) alternative!: Alternative
}
