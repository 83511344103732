<div class="content">
  <h2>Problem!</h2>
  <p>Något gick fel med din ansökan, kontrollera uppgifterna och försök igen.</p>
  <p>Kontakta Kundcenter på <a href="tel:+46411822000">0411-82 20 00</a> eller <a
    href="mailto:info@sparbankensyd.se">info&#64;sparbankensyd.se</a> om problem kvarstår.</p>
</div>
<mat-dialog-actions>
  <button
    color="primary" mat-dialog-close mat-raised-button>Stäng
  </button>
</mat-dialog-actions>
