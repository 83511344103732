import {Component, inject, OnInit} from '@angular/core'
import {RoBaseComponent} from '../0-base/ro-base.component'
import {ReadOnlyService} from '../service/read-only.service'
import {DecimalPipe} from '@angular/common'
import {PropertyTypePipe, PurposePipe} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-ro-new-loan',
  standalone: true,
  imports: [
    PropertyTypePipe,
    DecimalPipe,
    PurposePipe
  ],
  templateUrl: './ro-new-loan.component.html',
  styleUrl: '../read-only.scss'
})
export class RoNewLoanComponent extends RoBaseComponent implements OnInit {

  public newLoan: number = 0
  private service = inject(ReadOnlyService)

  override ngOnInit() {
    super.ngOnInit()
    this.service.loadData(this.application.application).subscribe({
      next: summary => {
        this.newLoan = this.application.application.appliedCredit.totalCreditAmount - summary.sum
      }
    })
  }
}
