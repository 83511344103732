import {Component} from '@angular/core'
import {SelectorBaseComponent} from '../selector-base.component'
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms'
import {startWith} from 'rxjs'
import {pnrValidator} from '@sparbanken-syd/sparbanken-syd-theme'
import {TBorgoCivilStatus} from 'sparbanken-syd-borgo'
import {MaritalStatus, shouldHaveMaritalStatusName} from '../../model/marital-status/marital-status'
import {BorgoCivilStatusMap} from '@sparbanken-syd/borgo-helpers'

const subValidators: ValidatorFn[] = [Validators.required, Validators.minLength(10), pnrValidator]
const nameValidators: ValidatorFn[] = [Validators.required, Validators.minLength(2)]

@Component({
  selector: 'spb-marital-status',
  templateUrl: './marital-status.component.html',
  styleUrl: '../selectors.scss'
})
export class MaritalStatusComponent extends SelectorBaseComponent<MaritalStatus> {

  protected readonly statuses = BorgoCivilStatusMap

  protected override form = new FormGroup({
    status: new FormControl<TBorgoCivilStatus | null>(null, {validators: [Validators.required]}),
    partnerSub: new FormControl<string>('', {nonNullable: true}),
    partnerName: new FormControl<string>('', {nonNullable: true})
  })


  constructor() {
    super()
    this.form.controls.status.valueChanges.pipe(
      // Make sure everything runs at least once, saves a test case.
      startWith('SINGLE' as TBorgoCivilStatus)
    ).subscribe({
      next: () => {
        this.setValidatorsIfRequired(this.nameRequired, this.form.controls.partnerSub, subValidators)
        this.setValidatorsIfRequired(this.nameRequired, this.form.controls.partnerName, nameValidators)
      }
    })
  }

  /**
   * A getter is worth it since it makes code cleaner and it is auto-tested.
   */
  get nameRequired(): boolean {
    /**
     * Only when Sambo, else it is fetched from UC.
     */
    return shouldHaveMaritalStatusName(this.form.controls.status.value)
  }
}
