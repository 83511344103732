import {Base} from '../base/base'
import {IBorgoApplication, IBorgoBankAsset} from 'sparbanken-syd-borgo'

export class BankAssets extends Base {
  public selected: boolean | null = null
  public assets: IBorgoBankAsset[] = []

  constructor(application: Partial<IBorgoApplication> = {}, private index: number = 0) {
    super(application)
    this.setFrom(application)
  }

  public override toApplication(application: Partial<IBorgoApplication>) {
    if (application.applicants && application.applicants[this.index]) {
      application.applicants[this.index].bankAsset = this.assets
    }
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    this.getApplicantPropertyOrNot<IBorgoBankAsset[]>(application, this.index, 'bankAsset')
      .forEach(b => {
        this.selected = true
        this.assets = b
      })
  }
}
