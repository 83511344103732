<form>
@if (sum >= 0) {
  <mat-form-field>
    <mat-label>Befintliga lån på bostaden</mat-label>
    <input matInput [formControl]="sumCtrl" spbFormatNumber>
    <span matTextSuffix>kr</span>
  </mat-form-field>
} @else {
  <spb-progress-ng></spb-progress-ng>
}
</form>
