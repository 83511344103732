import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms'
import {DateTime} from 'luxon'

export const DateValidator = (min: number, max: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.value) {
      return null
    }

    const value: DateTime | Date | number = control.value
    let cmp = 0
    if (value instanceof DateTime) {
      cmp = value.toMillis()
    }
    if (value instanceof Date) {
      cmp = value.getTime()
    }

    if (typeof value === 'number') {
      cmp = value
    }

    if (cmp < max && cmp > min) {
      return null
    }

    return {
      error: 'Value not in range'
    }
  }
}