import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'isoDate',
  standalone: true
})
export class IsoDatePipe implements PipeTransform {
  transform(value: string): string {
    return new Date(value).toLocaleDateString('sv-SE')
  }
}
