import {Component, OnInit} from '@angular/core'
import {RoBaseComponent} from '../0-base/ro-base.component'
import {IBorgoLoanAccommodation} from 'sparbanken-syd-borgo'
import {DecimalPipe, JsonPipe} from '@angular/common'
import {BindingPipe, CreditorPipe, PropertyTypePipe} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-ro-other-living',
  standalone: true,
  imports: [
    JsonPipe,
    PropertyTypePipe,
    DecimalPipe,
    BindingPipe,
    CreditorPipe
  ],
  templateUrl: './ro-other-living.component.html',
  styleUrl: '../read-only.scss'
})
export class RoOtherLivingComponent extends RoBaseComponent implements OnInit {

  public accommodations: IBorgoLoanAccommodation[] = []

  public override ngOnInit() {
    super.ngOnInit()
    this.accommodations = this.application.application.household[0].accommodation
      .filter(a => !a.loanObject)

  }
}
