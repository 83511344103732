import {Component, OnChanges} from '@angular/core'
import {SelectorBaseComponent} from '../../selector-base.component'
import {FormControl, FormGroup} from '@angular/forms'
import {filter} from 'rxjs'
import {ChildrenCountMap} from '@sparbanken-syd/borgo-helpers'

export interface IChildSelect {
  have: boolean | null
  count: number
}

@Component({
  selector: 'spb-child-select',
  templateUrl: './child-select.component.html',
  styleUrls: ['../../selectors.scss']
})
export class ChildSelectComponent extends SelectorBaseComponent<IChildSelect> implements OnChanges {

  protected override form = new FormGroup({
    have: new FormControl<boolean | null>(null),
    count: new FormControl<number | null>(null)
  })

  protected readonly childrenCounts = ChildrenCountMap

  constructor() {
    super()
    this.form.controls.have.valueChanges.pipe(
      filter(r => !r)
    ).subscribe({
      next: () => {
        this.form.controls.count.setValue(0)
      }
    })
  }

  public override ngOnChanges() {
    /**
     * Doing this because? The children selector refuses
     * to become unset.
     */
    this.form.reset(this.initial)
  }
}
