<mat-card [class]="{ error: !formValid$() && !edit()}">
  <mat-card-header>
    <div class="kyc-header">
      <div>Skatterättslig hemvist</div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (edit()) {
      <form [formGroup]="form">
        <spb-kyc-tax-input
          [form]="getForm('first')!"
        ></spb-kyc-tax-input>
        <spb-yes-no-radio
          leadText="Har du ytterligare en skatterättslig hemvist?"
          [externalControl]="hasTwo"></spb-yes-no-radio>
        @if (hasTwo.value) {
          <spb-kyc-tax-input
            [form]="getForm('second')!"
          ></spb-kyc-tax-input>
          <spb-yes-no-radio
            leadText="Har du ytterligare en skatterättslig hemvist?"
            [externalControl]="hasThree"></spb-yes-no-radio>
          @if (hasThree.value) {
            <spb-kyc-tax-input
              [form]="getForm('third')!"
            ></spb-kyc-tax-input>
          }
        }
      </form>
    } @else {
      <spb-kyc-tax-output
        label="Skatterättslig hemvist: "
        [form]="getForm('first')"
      ></spb-kyc-tax-output>
      @if (hasTwo.value) {
        <spb-kyc-tax-output
          label="Skatterättslig hemvist: "
          [form]="getForm('second')"
        ></spb-kyc-tax-output>
      }
      @if (hasThree.value) {
        <spb-kyc-tax-output
          label="Skatterättslig hemvist: "
          [form]="getForm('third')"
        ></spb-kyc-tax-output>
      }
    }
  </mat-card-content>
  <mat-card-actions>
    <spb-kyc-footer
      [(edit)]="edit"
      [changes]="taxation"
      [formValid]="formValid$()"></spb-kyc-footer>
  </mat-card-actions>
</mat-card>