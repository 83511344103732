<spb-yes-no-radio
  [externalControl]="assetHandler.have"
  leadText="Har du andra tillgånger såsom aktier eller fondsparande?"
></spb-yes-no-radio>
@if (assetHandler.have.value) {
  @for (asset of assetHandler.list; track asset) {
    <div class="spb-sub-section-header">Uppgifter om {{ $index + 1 | enumerate }} tillgången</div>
    <div class="spb-list-item">
      <spb-bank-asset
        [initial]="asset"
        (resultValue)="assetHandler.set($event, $index)"
      ></spb-bank-asset>
      <button (click)="assetHandler.remove($index)" color="warn" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
  <button [style.margin-top.px]="15" mat-stroked-button (click)="assetHandler.add()">Fler</button>
}