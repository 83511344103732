<mat-card [class]="{ error: !formValid$() && !edit()}">
  <mat-card-header>
    <div class="kyc-header">
      <div>Medborgarskap</div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (edit()) {
      <form [formGroup]="form">
        <mat-form-field>
          <mat-label>Medborgarskap</mat-label>
          <mat-select formControlName="primary">
            @for (country of CountryMap | keyvalue:sortOriginal; track $index) {
              <mat-option [value]="country.key">{{ country.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <spb-yes-no-radio
          leadText="Har du dubbelt medborgarskap?"
          [externalControl]="hasDouble"></spb-yes-no-radio>
        @if (hasDouble.value) {
          <mat-form-field>
            <mat-label>Dubbelt medborgarskap:</mat-label>
            <mat-select formControlName="secondary">
              @for (country of CountryMap | keyvalue:sortOriginal; track $index) {
                <mat-option [value]="country.key">{{ country.value }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </form>
    } @else {
      <div>Medborgarskap: {{ form.controls.primary.value! | country }}</div>
      <div>Dubbelt medborgarskap:
        @if (hasDouble.value) {
          {{ form.controls.secondary.value! | country }}
        } @else {
          Nej
        }
      </div>
    }
  </mat-card-content>
  <mat-card-actions>
    <spb-kyc-footer
      [(edit)]="edit"
      [changes]="citizen"
      [formValid]="formValid$()"></spb-kyc-footer>
  </mat-card-actions>
</mat-card>