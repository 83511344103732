<div class="spb-main-section-header">
  @if (index === 0) {
    Uppgifter om huvudlåntagare
  } @else {
    Uppgifter om medlåntagare
  }
</div>

@if (index !== 0) {
  <form>
    <mat-form-field>
      <mat-label>Personnummer</mat-label>
      <input matInput [formControl]="subControl">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Namn</mat-label>
      <input matInput>
    </mat-form-field>
  </form>
}


<spb-marital-status
  (resultValue)="maritalStatus.toApplication(applicationToSend)"
  [initial]="maritalStatus"
  (valid)="form.controls.marital.setValue($event)"
></spb-marital-status>
<spb-contact
  [primary]="index === 0"
  (resultValue)="contact.toApplication(applicationToSend)"
  (valid)="form.controls.contact.setValue($event)"
  [initial]="contact"
></spb-contact>
<spb-employment
  (resultValue)="employer.toApplication(applicationToSend)"
  [initial]="employer"
  (valid)="form.controls.employment.setValue($event)"
></spb-employment>


<spb-income
  (resultValue)="incomes[0].toApplication(applicationToSend)"
  [initial]="incomes[0]"
  suffix="kr / mån"
  title="Inkomst per månad, före skatt"
  (valid)="form.controls.income.setValue($event)"
></spb-income>
<spb-income
  (resultValue)="incomes[1].toApplication(applicationToSend)"
  [initial]="incomes[1]"
  suffix="kr / mån"
  title="Kapitalinkomst per månad"
  (valid)="form.controls.capital.setValue($event)"
></spb-income>

<div class="spb-main-section-header">Övriga lån och krediter</div>

<spb-cost
  (resultValue)="cost.toApplication(applicationToSend)"
  (valid)="form.controls.cost.setValue($event)"
  [initial]="cost"
>
</spb-cost>

<spb-credits
  (resultValue)="obligations.toApplication(applicationToSend)"
  [initial]="obligations"
  (valid)="form.controls.credits.setValue($event)"
></spb-credits>

