import {Base} from '../base/base'
import {IBorgoApplication, TKycPurpose} from 'sparbanken-syd-borgo'

export class KycPurpose extends Base {

  public purpose: string | null = null

  public purposeDescription: string | undefined

  constructor(application: Partial<IBorgoApplication> = {}) {
    super(application)
    this.setFrom(application)
  }

  public override toApplication(application: Partial<IBorgoApplication>) {
    application.complementaryData = application.complementaryData || {} as any
    application.complementaryData!.kyc = {
      fundingOrigination: ['0'],
      purpose: this.purpose as TKycPurpose,
      purposeDescription: this.purposeDescription ?? ''
    }
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    if (application.complementaryData?.kyc) {
      this.purpose = application.complementaryData.kyc.purpose
      this.purposeDescription = application.complementaryData.kyc.purposeDescription
    }
  }
}
