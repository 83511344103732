import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core'
import {FormControl} from '@angular/forms'
import {filter} from 'rxjs'

/**
 * A yes and no input component that presents an input if you
 * select "yes".
 *
 * It can be reversed so that it shows the value if "no", if so
 * set the _numberField_ input to true.
 */
@Component({
  selector: 'spb-yes-no-input',
  templateUrl: './yes-no-input.component.html'
})
export class YesNoInputComponent<T> implements OnInit, OnChanges {
  /**
   * The question to ask for yes no.
   */
  @Input({required: true}) yesNoLabel: string = 'Fråga'

  /**
   * The label for the input
   */
  @Input({required: true}) inputLabel: string = 'Etikett'

  /**
   * Put something at the end?
   */
  @Input() inputSuffix?: string

  /**
   * A longer description to show after selection.
   */
  @Input() inputInfoText?: string

  /**
   * If this is a field for numbers, else we keep it
   * as text.
   */
  @Input() numberField: boolean = true

  /**
   * If you want to control yes/no from the outside
   */
  @Input() initialState: boolean | null = null

  /**
   * The control that eventually gets its value
   */
  @Input({required: true}) ctrl: FormControl<T | null> = new FormControl<T | null>(null)

  /**
   * If you set this to true the "No" means show the value.
   */
  @Input() reverse: boolean = false

  /**
   * The resulting value. You can assign it to your control
   * directly. We do not assign to the control from here.
   */
  @Output() resultValue = new EventEmitter<T>()

  /**
   * The true or false emitted if ever selected.
   */
  @Output() resultAnswer = new EventEmitter<boolean>()

  /**
   * Controls the yes/no button
   */
  protected have = new FormControl<boolean | null>(null)

  public ngOnInit(): void {
    this.ctrl.valueChanges.subscribe({
      next: (v) => {
        /**
         * null = reset to no selection
         * 0 or '' = set it to false as a "no" is selected
         * any other value is seen as selected
         */
        if (v === null) {
          this.have.reset()
        } else if (v === 0 || v === '') {
          this.have.setValue(this.reverse)
        } else
          this.have.setValue(!this.reverse)
      }
    })

    if (this.ctrl.value !== null) {
      this.have.setValue(!!this.ctrl.value)
    }

    this.have.valueChanges.pipe(
      filter((v): v is boolean => v !== null)
    ).subscribe({
      next: v => {
        this.resultAnswer.emit(v)
      }
    })
  }

  public ngOnChanges(): void {
    this.have.setValue(this.initialState)
  }
}
