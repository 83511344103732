import * as i0 from '@angular/core';
import { Pipe, Component, Input, Inject } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import * as i1 from '@angular/platform-browser';
function ProgressNgComponent_Conditional_2_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.message);
  }
}
function ProgressNgComponent_Conditional_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.error);
  }
}
function ProgressNgComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ProgressNgComponent_Conditional_2_Conditional_0_Template, 2, 1, "div", 2)(1, ProgressNgComponent_Conditional_2_Conditional_1_Template, 2, 1, "div", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵconditional(!ctx_r0.errored ? 0 : 1);
  }
}
function WaitComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.data.text);
  }
}
function WaitComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("innerHtml", ctx_r0.htmlText, i0.ɵɵsanitizeHtml);
  }
}
function WaitComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "spb-progress-ng");
  }
}
function WaitComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-dialog-actions")(1, "button", 3);
    i0.ɵɵtext(2, "St\xE4ng");
    i0.ɵɵelementEnd()();
  }
}
const BorgoApplicationPurposeMap = new Map([['INCREASE', {
  available: true,
  name: 'Höjning av befintliga lån'
}], ['PROMISE', {
  available: false,
  name: 'Lånelöfte'
}], ['BUY', {
  available: false,
  name: 'Köp av ny bostad'
}], ['TAKEOVER', {
  available: false,
  name: 'Flytt av lån'
}], ['TAKEOVER_INCREASE', {
  available: false,
  name: 'Flytt och höj av lån'
}], ['BRIDGE', {
  available: false,
  name: 'Överbryggningslån'
}], ['DOWN_PAYMENT', {
  available: false,
  name: 'Handpenningslån'
}], [null, {
  available: false,
  name: 'Ej valt'
}]]);
class ApplicationPurposePipe {
  transform(value) {
    return BorgoApplicationPurposeMap.get(value)?.name;
  }
  static {
    this.ɵfac = function ApplicationPurposePipe_Factory(t) {
      return new (t || ApplicationPurposePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "applicationPurpose",
      type: ApplicationPurposePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationPurposePipe, [{
    type: Pipe,
    args: [{
      name: 'applicationPurpose',
      standalone: true
    }]
  }], null, null);
})();
const BorgoApplicationStatusMap = new Map([['INITIATED', {
  available: true,
  name: 'Startad'
}], ['UPDATED_APPLICATION', {
  available: true,
  name: 'Uppdaterad'
}], ['PENDING_CREDIT_DECISION', {
  available: true,
  name: 'Väntar på kreditbeslut'
}], ['WAITING_SUPPLEMENT', {
  available: true,
  name: 'Väntar på komplettering'
}], ['REJECTED_CREDIT_DECISION', {
  available: true,
  name: 'Avslag på kreditansökan'
}], ['WAITING_MANUAL_APPROVAL', {
  available: true,
  name: 'Väntar på manuellt godkännande'
}], ['PRELIMINARY_APPROVED_CREDIT_DECISION', {
  available: true,
  name: 'Preliminärt godkänd'
}], ['APPROVED_CREDIT_DECISION', {
  available: true,
  name: 'Beviljat'
}], ['OFFER_ACCEPTED', {
  available: true,
  name: 'Erbjudandet accepterat'
}], ['WAITING_CUSTOMER_ONBOARDED', {
  available: true,
  name: 'Väntar på onboarding'
}], ['CREDIT_PREPARATION', {
  available: true,
  name: 'Kreditförberedning'
}], ['AGREEMENT_PREPARATION', {
  available: true,
  name: 'Avtalsförberedning'
}], ['AGREEMENT_CREATED', {
  available: true,
  name: 'Avtal skapat'
}], ['AGREEMENT_SENT', {
  available: true,
  name: 'Avtal skickat'
}], ['WAITING_AGREEMENT_SIGNED', {
  available: true,
  name: 'Väntar på signatur'
}], ['AGREEMENT_SIGNED', {
  available: true,
  name: 'Avtal underskrivet'
}], ['LOAN_ACTIVATED', {
  available: true,
  name: 'Lån aktiverat'
}], ['LOAN_DISBURSED', {
  available: true,
  name: 'Lån utbetalt'
}], ['APPLICATION_COMPLETED', {
  available: true,
  name: 'Ansökan komplett'
}], ['APPLICATION_TERMINATED', {
  available: true,
  name: 'Ansökan avslutad'
}]]);
class ApplicationStatusPipe {
  transform(value) {
    return BorgoApplicationStatusMap.get(value)?.name;
  }
  static {
    this.ɵfac = function ApplicationStatusPipe_Factory(t) {
      return new (t || ApplicationStatusPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "applicationStatus",
      type: ApplicationStatusPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApplicationStatusPipe, [{
    type: Pipe,
    args: [{
      name: 'applicationStatus',
      standalone: true
    }]
  }], null, null);
})();
const ApplicationLoanBindingMap = new Map([['3', {
  available: true,
  name: '3 månader'
}], ['12', {
  available: true,
  name: '1 år'
}], ['24', {
  available: true,
  name: '2 år'
}], ['36', {
  available: true,
  name: '3 år'
}], ['48', {
  available: true,
  name: '4 år'
}], ['60', {
  available: true,
  name: '5 år'
}], ['84', {
  available: true,
  name: '7 år'
}], ['120', {
  available: true,
  name: '10 år'
}]]);
class BindingPipe {
  transform(value) {
    return ApplicationLoanBindingMap.get(value).name;
  }
  static {
    this.ɵfac = function BindingPipe_Factory(t) {
      return new (t || BindingPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "binding",
      type: BindingPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BindingPipe, [{
    type: Pipe,
    args: [{
      name: 'binding',
      standalone: true
    }]
  }], null, null);
})();
const BorgoCaseStatusMap = new Map([['OPEN', {
  available: true,
  name: 'Öppet'
}], ['IN_PROGRESS', {
  available: true,
  name: 'Pågående'
}], ['CLOSED', {
  available: true,
  name: 'Stängt'
}], ['ARCHIVED', {
  available: true,
  name: 'Arkiverat'
}]]);
const ChildrenCount = [1, 2, 3, 4, 5, 6];
const ChildrenCountMap = new Map([[1, {
  available: true,
  name: '1'
}], [2, {
  available: true,
  name: '2'
}], [3, {
  available: true,
  name: '3'
}], [4, {
  available: true,
  name: '4'
}], [5, {
  available: true,
  name: '5'
}], [6, {
  available: true,
  name: '5'
}]]);
const ChildCustodyMap = new Map([['0', {
  available: true,
  name: '0%'
}], ['50', {
  available: true,
  name: '50%'
}], ['100', {
  available: true,
  name: '100%'
}]]);
const ChildrenAges = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
const ChildrenAgeMap = new Map([[1, {
  available: true,
  name: '1'
}], [2, {
  available: true,
  name: '2'
}], [3, {
  available: true,
  name: '3'
}], [4, {
  available: true,
  name: '4'
}], [5, {
  available: true,
  name: '5'
}], [6, {
  available: true,
  name: '6'
}], [7, {
  available: true,
  name: '7'
}], [8, {
  available: true,
  name: '8'
}], [9, {
  available: true,
  name: '9'
}], [10, {
  available: true,
  name: '10'
}], [11, {
  available: true,
  name: '11'
}], [12, {
  available: true,
  name: '12'
}], [13, {
  available: true,
  name: '13'
}], [14, {
  available: true,
  name: '14'
}], [15, {
  available: true,
  name: '15'
}], [16, {
  available: true,
  name: '16'
}], [17, {
  available: true,
  name: '17'
}], [18, {
  available: true,
  name: '18'
}]]);

/**
 * This file is only for maps that does not have
 * pipes. When we add a pipe move the map.
 */
const BorgoAccommodationTypeMap = new Map([['HOUSE', {
  available: true,
  name: 'Villa'
}], ['APARTMENT', {
  available: true,
  name: 'Bostadsrätt'
}], ['HOLIDAY_HOUSE', {
  available: true,
  name: 'Fritidshus'
}], ['RENTED_APARTMENT', {
  available: false,
  name: 'Hyresrätt'
}]]);
const BorgoObligationCreditTypeMap = new Map([['CARD', {
  available: true,
  name: 'Kortkredit'
}], ['ACCOUNT', {
  available: true,
  name: 'Kontokredit'
}]]);
const BorgoObligationLoanTypeMap = new Map([['UNSECURED', {
  available: true,
  name: 'Privatlån'
}], ['SECURED', {
  available: false,
  name: 'Leasing'
}], ['SMS', {
  available: true,
  name: 'SMS-lån'
}]]);
const BorgoBankAssetTypeMap = new Map([['BANK_ACCOUNT', {
  available: true,
  name: 'Bankkonto'
}], ['STOCK_SHARES', {
  available: true,
  name: 'Aktier'
}], ['MUTUAL_FUNDS', {
  available: true,
  name: 'Fonder'
}], ['OTHER', {
  available: true,
  name: 'Annat'
}]]);
const BorgoCreditorsMap = new Map([['BORGO', {
  available: true,
  name: 'Borgo'
}], ['AVANZA_BANK', {
  available: true,
  name: 'Avanza'
}], ['BLUESTEP', {
  available: true,
  name: 'Bluestep'
}], ['DANSKE_BOLAN', {
  available: true,
  name: 'Danske Bank'
}], ['HYPOTEKET', {
  available: true,
  name: 'Hypoteket'
}], ['ICA_BOLAN', {
  available: true,
  name: 'ICA Banken'
}], ['LANDSHYPOTEK', {
  available: true,
  name: 'Landshypotek'
}], ['LANSFORSAKRINGAR', {
  available: true,
  name: 'Länsförsäkringar'
}], ['MARGINALEN_BANK', {
  available: true,
  name: 'Marginalen Bank'
}], ['NORDAX_BANK', {
  available: true,
  name: 'Nordax Finans och Skoj'
}], ['NORDEA_HYPOTEK', {
  available: true,
  name: 'Nordea'
}], ['NORDNET', {
  available: true,
  name: 'Nordnet'
}], ['SBAB', {
  available: true,
  name: 'SBAB'
}], ['SEB', {
  available: true,
  name: 'SEB'
}], ['SHB_STADSHYPOTEK', {
  available: true,
  name: 'Handelsbanken'
}], ['SKANDIABANKEN', {
  available: true,
  name: 'Skandiabanken'
}], ['STABELO', {
  available: true,
  name: 'Stabelo'
}], ['SVEA_EKONOMI', {
  available: true,
  name: 'Svea'
}], ['SWEDBANK_BOLAN', {
  available: true,
  name: 'Swedbank'
}], ['ALANDSBANKEN_BOLAN', {
  available: true,
  name: 'Ålandsbanken'
}], ['OTHER_CREDITOR', {
  available: true,
  name: 'Annan'
}]]);
const InsuranceCompanyMap = new Map([['AKTSAM', {
  available: true,
  name: 'Aktsam'
}], ['DINA', {
  available: true,
  name: 'Dina Försäkringar'
}], ['FOLKSAM', {
  available: true,
  name: 'Folksam'
}], ['GJENSIDIGE', {
  available: true,
  name: 'Gjensidige'
}], ['HEDVIG', {
  available: true,
  name: 'Hedvig'
}], ['ICA', {
  available: true,
  name: 'ICA'
}], ['IF', {
  available: true,
  name: 'If'
}], ['LANSFORSAKRINGAR', {
  available: true,
  name: 'Länsförsäkringar'
}], ['MODERNA', {
  available: true,
  name: 'Moderna'
}], ['SAFETOWN', {
  available: true,
  name: 'Safetown'
}], ['SVELAND', {
  available: true,
  name: 'Sveland'
}], ['TRE_KRONOR', {
  available: true,
  name: 'Tre Kronor'
}], ['TRYGG_HANSA', {
  available: true,
  name: 'Trygg Hansa'
}], ['VARDIA', {
  available: true,
  name: 'Vardia'
}], ['WATERCIRCLES_CIRCLES', {
  available: true,
  name: 'Water Circles'
}], ['OTHER_INSURANCE_COMPANY', {
  available: true,
  name: 'Annat'
}]]);
const BorgoCivilStatusMap = new Map([['MARRIED', {
  available: true,
  name: 'Gift'
}], ['REGISTERED_PARTNERSHIP', {
  available: true,
  name: 'Registrerad partner'
}], ['SINGLE', {
  available: true,
  name: 'Ensamstående'
}], ['COHABITATION', {
  available: true,
  name: 'Sambo'
}]]);
class CivilStatusPipe {
  transform(value) {
    return BorgoCivilStatusMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function CivilStatusPipe_Factory(t) {
      return new (t || CivilStatusPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "civilStatus",
      type: CivilStatusPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CivilStatusPipe, [{
    type: Pipe,
    args: [{
      name: 'civilStatus',
      standalone: true
    }]
  }], null, null);
})();
class CreditPipe {
  transform(value) {
    return BorgoObligationCreditTypeMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function CreditPipe_Factory(t) {
      return new (t || CreditPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "credit",
      type: CreditPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreditPipe, [{
    type: Pipe,
    args: [{
      name: 'credit',
      standalone: true
    }]
  }], null, null);
})();
class CreditorPipe {
  transform(value) {
    return BorgoCreditorsMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function CreditorPipe_Factory(t) {
      return new (t || CreditorPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "creditor",
      type: CreditorPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreditorPipe, [{
    type: Pipe,
    args: [{
      name: 'creditor',
      standalone: true
    }]
  }], null, null);
})();
const DocumentSupplementMap = new Map([['AMORTIZATION_BASIS', {
  available: true,
  name: 'Amorteringsunderlag'
}], ['ACCOMMODATION', {
  available: true,
  name: 'Boende'
}], ['EMPLOYER_CERTIFICATE', {
  available: true,
  name: 'Anställningsavtal'
}], ['SALARY_SPEC_BASIS', {
  available: true,
  name: 'Lönespecifikation'
}], ['PENSION_SPEC_BASIS', {
  available: true,
  name: 'Pensionsunderlag'
}], ['MARKET_VALUE_VALUATION', {
  available: true,
  name: 'Marknadsvärdering'
}], ['SAVINGS_CAPITAL_BASIS', {
  available: true,
  name: 'Sparkapital'
}], ['CHILDREN_ALIMONY', {
  available: true,
  name: 'Underhållsbidrag'
}], ['APARTMENT_EXTRACT', {
  available: true,
  name: 'Lägenhetsförteckning'
}], ['REPAYMENT_LOANS_BASIS', {
  available: true,
  name: 'Låneavi'
}], ['TITLE_DEED', {
  available: true,
  name: 'Lagfart'
}], ['DOWN_PAYMENT_BASIS', {
  available: true,
  name: 'Handpenning'
}], ['RESIDENCE_PERMIT_BASIS', {
  available: true,
  name: 'Uppehållstillstånd'
}], ['DIVORCE_APPLICATION', {
  available: true,
  name: 'Bodelningsavtal'
}], ['ANNUAL_REPORT_BUSINESS', {
  available: true,
  name: 'Årsredovisning'
}], ['DIVIDEND_CAPITAL_BUSINESS', {
  available: true,
  name: 'Utdelning'
}], ['ID_DOCUMENT_COPY', {
  available: true,
  name: 'Kopia av legitimation'
}], ['BUY_CONTRACT', {
  available: true,
  name: 'Köpekontrakt'
}], ['DEPOSIT_AGREEMENT', {
  available: true,
  name: 'Depositionsavtal'
}], ['SALES_CONTRACT', {
  available: true,
  name: 'Försäljningskontrakt'
}], ['OTHER_SUPPLEMENT', {
  available: true,
  name: 'Annat'
}]]);
class DocumentTypePipe {
  transform(documentKey) {
    return DocumentSupplementMap.get(documentKey)?.name;
  }
  static {
    this.ɵfac = function DocumentTypePipe_Factory(t) {
      return new (t || DocumentTypePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "documentType",
      type: DocumentTypePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DocumentTypePipe, [{
    type: Pipe,
    args: [{
      name: 'documentType',
      standalone: true
    }]
  }], null, null);
})();
const EmploymentTypeMap = new Map([['PERMANENT_EMPLOYED', {
  available: true,
  name: 'Tillsvidare'
}], ['TEMPORARY_EMPLOYED', {
  available: true,
  name: 'Tidsbegränsad'
}], ['SELF_EMPLOYED', {
  available: true,
  name: 'Egenföretagare'
}], ['PENSIONER', {
  available: true,
  name: 'Pensionär'
}], ['JOB_SEEKER', {
  available: true,
  name: 'Jobbsökande'
}], ['STUDENT', {
  available: true,
  name: 'Studerande'
}]]);
class EmploymentPipe {
  transform(value) {
    return EmploymentTypeMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function EmploymentPipe_Factory(t) {
      return new (t || EmploymentPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "employment",
      type: EmploymentPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmploymentPipe, [{
    type: Pipe,
    args: [{
      name: 'employment',
      standalone: true
    }]
  }], null, null);
})();
class InsuranceCompanyPipe {
  transform(value) {
    return InsuranceCompanyMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function InsuranceCompanyPipe_Factory(t) {
      return new (t || InsuranceCompanyPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "insuranceCompany",
      type: InsuranceCompanyPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InsuranceCompanyPipe, [{
    type: Pipe,
    args: [{
      name: 'insuranceCompany',
      standalone: true
    }]
  }], null, null);
})();
class LoanPipe {
  transform(value) {
    return BorgoObligationLoanTypeMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function LoanPipe_Factory(t) {
      return new (t || LoanPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "loan",
      type: LoanPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoanPipe, [{
    type: Pipe,
    args: [{
      name: 'loan',
      standalone: true
    }]
  }], null, null);
})();
class LoanTypePipe {
  transform(value) {
    return value === 'HOUSING_LOAN' ? 'Huslån' : 'Privatlån';
  }
  static {
    this.ɵfac = function LoanTypePipe_Factory(t) {
      return new (t || LoanTypePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "loanType",
      type: LoanTypePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoanTypePipe, [{
    type: Pipe,
    args: [{
      name: 'loanType',
      standalone: true
    }]
  }], null, null);
})();
const OccupationTypeMap = new Map([['EMPLOYEE', {
  available: true,
  name: 'Arbetare'
}], ['EXECUTIVE_EMPLOYEE', {
  available: true,
  name: 'Anställd i ledande ställning'
}], ['SELF_EMPLOYED', {
  available: true,
  name: 'Egenföretagare'
}], ['STUDENT', {
  available: true,
  name: 'Studerande'
}], ['PENSIONER', {
  available: true,
  name: 'Pensionär'
}], ['FARMER', {
  available: true,
  name: 'Lantbrukare'
}], ['JOB_SEEKER', {
  available: true,
  name: 'Jobbsökande'
}], ['OTHER', {
  available: true,
  name: 'Annat'
}]]);
class OccupationPipe {
  transform(value) {
    return OccupationTypeMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function OccupationPipe_Factory(t) {
      return new (t || OccupationPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "occupation",
      type: OccupationPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OccupationPipe, [{
    type: Pipe,
    args: [{
      name: 'occupation',
      standalone: true
    }]
  }], null, null);
})();
class PropertyTypePipe {
  transform(value) {
    return BorgoAccommodationTypeMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function PropertyTypePipe_Factory(t) {
      return new (t || PropertyTypePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "propertyType",
      type: PropertyTypePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PropertyTypePipe, [{
    type: Pipe,
    args: [{
      name: 'propertyType',
      standalone: true
    }]
  }], null, null);
})();
const PurposeTypeMap = new Map([['1', {
  available: true,
  name: 'Amortering av andra krediter'
}], ['2', {
  available: true,
  name: 'Investering i bostaden'
}], ['3', {
  available: true,
  name: 'Köp av bostad eller fritidsbostad'
}], ['4', {
  available: true,
  name: 'Annat'
}]]);
class PurposePipe {
  transform(value) {
    return PurposeTypeMap.get(value)?.name || '';
  }
  static {
    this.ɵfac = function PurposePipe_Factory(t) {
      return new (t || PurposePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "purpose",
      type: PurposePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PurposePipe, [{
    type: Pipe,
    args: [{
      name: 'purpose',
      standalone: true
    }]
  }], null, null);
})();
class ProgressNgComponent {
  constructor() {
    this.message = 'Vänta...';
    this.error = '';
    this.errored = false;
    /**
     * Default true and then we only show the progress bar
     */
    this.minimal = true;
  }
  static {
    this.ɵfac = function ProgressNgComponent_Factory(t) {
      return new (t || ProgressNgComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProgressNgComponent,
      selectors: [["spb-progress-ng"]],
      inputs: {
        message: "message",
        error: "error",
        errored: "errored",
        minimal: "minimal"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 4,
      vars: 5,
      consts: [[1, "holder"], [3, "color", "mode"], [1, "message"]],
      template: function ProgressNgComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div");
          i0.ɵɵelementStart(1, "div", 0);
          i0.ɵɵtemplate(2, ProgressNgComponent_Conditional_2_Template, 2, 1);
          i0.ɵɵelement(3, "mat-progress-bar", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("flex-grow", 1);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(!ctx.minimal ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("color", ctx.errored ? "warn" : "primary")("mode", ctx.errored ? "buffer" : "indeterminate");
        }
      },
      dependencies: [MatProgressBar],
      styles: [".holder[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:flex-start;justify-content:center}.message[_ngcontent-%COMP%]{margin-top:100px;font-size:150%;font-weight:700}mat-progress-bar[_ngcontent-%COMP%]{height:30px;min-width:250px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressNgComponent, [{
    type: Component,
    args: [{
      selector: 'spb-progress-ng',
      imports: [MatProgressBar, MatButton],
      standalone: true,
      template: "<div [style.flex-grow]=\"1\"></div>\n<div class=\"holder\">\n  @if (!minimal) {\n    @if (!errored) {\n      <div class=\"message\">{{ message }}</div>\n    } @else {\n      <div class=\"message\">{{ error }}</div>\n    }\n  }\n\n  <mat-progress-bar\n    [color]=\"errored ? 'warn' : 'primary'\"\n    [mode]=\"errored ? 'buffer': 'indeterminate'\"\n    [style]=\"\"></mat-progress-bar>\n</div>",
      styles: [".holder{display:flex;flex-direction:column;align-items:flex-start;justify-content:center}.message{margin-top:100px;font-size:150%;font-weight:700}mat-progress-bar{height:30px;min-width:250px}\n"]
    }]
  }], null, {
    message: [{
      type: Input
    }],
    error: [{
      type: Input
    }],
    errored: [{
      type: Input
    }],
    minimal: [{
      type: Input
    }]
  });
})();
class WaitComponent {
  constructor(sanitizer, data) {
    this.sanitizer = sanitizer;
    this.data = data;
    this.htmlText = '';
    if (data.html) {
      this.htmlText = this.sanitizer.bypassSecurityTrustHtml(data.html);
    }
  }
  static {
    this.ɵfac = function WaitComponent_Factory(t) {
      return new (t || WaitComponent)(i0.ɵɵdirectiveInject(i1.DomSanitizer), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WaitComponent,
      selectors: [["spb-wait"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 7,
      vars: 5,
      consts: [[1, "title"], ["matDialogContent", ""], ["matDialogContent", "", 3, "innerHtml"], ["matDialogClose", "", "mat-raised-button", "", "color", "primary"]],
      template: function WaitComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "mat-dialog-content");
          i0.ɵɵtemplate(3, WaitComponent_Conditional_3_Template, 2, 1, "div", 1)(4, WaitComponent_Conditional_4_Template, 1, 1, "div", 2)(5, WaitComponent_Conditional_5_Template, 1, 0, "spb-progress-ng");
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(6, WaitComponent_Conditional_6_Template, 3, 0, "mat-dialog-actions");
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.data.title);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.data.text ? 3 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.data.html ? 4 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.data.progress ? 5 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.data.closable ? 6 : -1);
        }
      },
      dependencies: [MatDialogContent, ProgressNgComponent, MatDialogActions, MatButton, MatDialogClose],
      styles: [".title[_ngcontent-%COMP%]{font-size:130%;font-weight:700;padding-left:20px;padding-top:20px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaitComponent, [{
    type: Component,
    args: [{
      selector: 'spb-wait',
      standalone: true,
      imports: [MatDialogContent, ProgressNgComponent, MatDialogActions, MatButton, MatDialogClose],
      template: "<div class=\"title\">{{ data.title }}</div>\n<mat-dialog-content>\n  @if (data.text) {\n    <div matDialogContent>{{ data.text }}</div>\n  }\n  @if (data.html) {\n    <div [innerHtml]=\"htmlText\" matDialogContent></div>\n  }\n  @if (data.progress) {\n    <spb-progress-ng></spb-progress-ng>\n  }\n</mat-dialog-content>\n@if (data.closable) {\n  <mat-dialog-actions>\n    <button matDialogClose=\"\" mat-raised-button color=\"primary\">St\u00E4ng</button>\n  </mat-dialog-actions>\n}",
      styles: [".title{font-size:130%;font-weight:700;padding-left:20px;padding-top:20px}\n"]
    }]
  }], () => [{
    type: i1.DomSanitizer
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], null);
})();

/*
 * Public API Surface of borgo-helpers
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ApplicationLoanBindingMap, ApplicationPurposePipe, ApplicationStatusPipe, BindingPipe, BorgoAccommodationTypeMap, BorgoApplicationPurposeMap, BorgoApplicationStatusMap, BorgoBankAssetTypeMap, BorgoCaseStatusMap, BorgoCivilStatusMap, BorgoCreditorsMap, BorgoObligationCreditTypeMap, BorgoObligationLoanTypeMap, ChildCustodyMap, ChildrenAgeMap, ChildrenCountMap, CivilStatusPipe, CreditPipe, CreditorPipe, DocumentSupplementMap, DocumentTypePipe, EmploymentPipe, EmploymentTypeMap, InsuranceCompanyMap, InsuranceCompanyPipe, LoanPipe, LoanTypePipe, OccupationPipe, OccupationTypeMap, ProgressNgComponent, PropertyTypePipe, PurposePipe, PurposeTypeMap, WaitComponent };
