import {Component} from '@angular/core'
import {
  MatExpansionPanel,
  MatExpansionPanelContent,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'

@Component({
  selector: 'spb-pep',
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelContent,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader
  ],
  templateUrl: './pep.component.html',
  styleUrl: './pep.component.scss'
})
export class PepComponent {

}
