<form [formGroup]="form">
  <spb-creditor
    (resultValue)="setResult($event)"
    (valid)="creditorValid($event)"
    [initial]="creditor"
  ></spb-creditor>
  <mat-form-field>
    <mat-label>Lånebelopp</mat-label>
    <input formControlName="debt" matInput spbFormatNumber>
    <span matTextSuffix>kr</span>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Lånenummer</mat-label>
    <input formControlName="loanNumber" matInput>
  </mat-form-field>

  <spb-selector
    [ctrl]="form.controls.binding"
    [items]="bindings"
    label="Räntebindning"
  ></spb-selector>

  <mat-form-field>
    <mat-label>Amortering</mat-label>
    <input formControlName="amortization" matInput spbFormatNumber>
    <span [style.white-space]="'nowrap'" matTextSuffix>kr / mån</span>
  </mat-form-field>
</form>