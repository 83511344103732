import {KycItem} from './kyc-item'
import {IKycPersonDataset, IKycPersonTaxationDataset, IKycTaxation} from 'sparbanken-syd-borgo'

export interface IKycTaxItem {
  country: string
  tinCode?: string
}

export interface IKycTax {
  /**
   * Actually minimum one and max 3
   */
  countries: IKycTaxItem[]
}

export class KycItemTax extends KycItem<IKycTax> {

  private pTaxation: IKycTax = {countries: []}

  constructor(input: Partial<IKycPersonDataset> = {}) {
    super(input)
    this.setTax()
    this.isValid()
  }

  get taxation(): IKycTax {
    const taxis = this.getTaxation()
    return {
      countries: [taxis.mainTaxation, ...taxis.parallelTaxations]
    }
  }

  public setValue(v: IKycTax): void {
    const countries = v.countries.filter(v => v.country)
    if (countries[0]) {
      Object.assign(this.getMainTaxation(), countries[0])
    }
    // This does probably not work, have never tried it :)
    if (countries.length > 1) {
      Object.assign(this.getParallelTaxation(), countries.splice(1))
    }
    this.isValid()
  }

  private isValid(): void {
    this.isValid$.next(!!this.getMainTaxation().country)
  }

  private setTax() {
    this.pTaxation.countries[0] = this.getMainTaxation()
    const parallel = this.getParallelTaxation()
    parallel.forEach(t => {
      this.pTaxation.countries.push(t)
    })
  }

  private getMainTaxation(): IKycTaxation {
    const taxis = this.getTaxation()
    if (!taxis.mainTaxation) {
      taxis.mainTaxation = {country: ''}
    }
    return taxis.mainTaxation
  }

  private getParallelTaxation(): IKycTaxation[] {
    const taxis = this.getTaxation()
    if (!taxis.parallelTaxations) {
      taxis.parallelTaxations = []
    }
    return taxis.parallelTaxations
  }

  private getTaxation(): IKycPersonTaxationDataset {
    if (!this.input.personTaxationDataset) {
      this.input.personTaxationDataset = {
        prevTaxCountry: 'not_known',
        mainTaxation: {country: ''},
        parallelTaxations: []
      }
    }

    return this.input.personTaxationDataset
  }
}
