import {Base} from '../base/base'
import {IBorgoApplication, IBorgoLoanEmployment, TBorgoEmploymentType} from 'sparbanken-syd-borgo'
import {DateTime} from 'luxon'

export interface IEmployment {
  employmentType: TBorgoEmploymentType | null
  employedStartDate: DateTime | null
  temporaryEmployedEndDate: DateTime | null
  employerName: string | null
  /**
   * This is a boolean that we introduced check this
   * if you have enskild firma. Then the org number
   * will be the personnummer so if this is FALSE
   * org number is required.
   */
  isEgenForetagare: boolean | null

  /**
   * Must be set, will be set to the "" if
   * not present.
   */
  typeSelfEmployedAbOrgnr: string | null
}

export class Employment extends Base implements IEmployment {
  employmentType: TBorgoEmploymentType | null = null
  employedStartDate: DateTime | null = null
  temporaryEmployedEndDate: DateTime | null = null
  employerName: string | null = null
  typeSelfEmployedAbOrgnr: string | null = null
  isEgenForetagare: boolean | null = null

  constructor(application: Partial<IBorgoApplication> = {}, public index: number = 0) {
    super(application)
    this.setFrom(application)
  }

  public shouldHaveEmployerName = (): boolean => {
    const shouldHave: TBorgoEmploymentType[] = ['PERMANENT_EMPLOYED', 'TEMPORARY_EMPLOYED']
    return shouldHave.indexOf(this.employmentType as TBorgoEmploymentType) !== -1
  }

  public override toApplication(application: Partial<IBorgoApplication>) {
    if (application.applicants) {
      // We always reset the application employment
      const employment = {} as IBorgoLoanEmployment
      if (this.isValid()) {
        // If we are valid, we can safely assert that all required values are set.
        employment.employmentType = this.employmentType!
        if (this.shouldHaveEmployerName()) {
          employment.employerName = this.employerName!
          employment.employedStartDate = this.employedStartDate!.setLocale('sv-se').toLocaleString()
        }
        if (this.employmentType === 'TEMPORARY_EMPLOYED') {
          employment.temporaryEmployedEndDate = this.temporaryEmployedEndDate!.setLocale('sv-se').toLocaleString()
        }
        if (this.employmentType === 'SELF_EMPLOYED') {
          employment.typeSelfEmployedAbOrgnr = this.typeSelfEmployedAbOrgnr ?? ''
          if (!this.isEgenForetagare) {
            employment.typeSelfEmployedAbOrgnr = application.applicants[this.index].nationalId
          }
        }
        application.applicants[this.index].employment = employment
      }
    }
  }

  public override isValid(): boolean {
    if (this.employmentType) {
      if (this.shouldHaveEmployerName() && !this.employerName) {
        return false
      }

      if (this.shouldHaveEmployerName() && !this.employedStartDate) {
        return false
      }

      if (this.employmentType === 'TEMPORARY_EMPLOYED' && !this.temporaryEmployedEndDate) {
        return false
      }

      // If not egen firma then org number is required. If egen firma we will enter the
      // personnummer as org nummer.
      if (this.employmentType === 'SELF_EMPLOYED' && this.isEgenForetagare) {
        return !!this.typeSelfEmployedAbOrgnr
      }
      return true
    }
    return false
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    this.getApplicantPropertyOrNot<IBorgoLoanEmployment>(application, this.index, 'employment')
      .forEach(employment => {
        this.employmentType = employment.employmentType
        this.employerName = employment.employerName || null

        // TODO Refer to the applicant sub here!
        this.isEgenForetagare = !!employment.typeSelfEmployedAbOrgnr
        // Comes in as the empty string sometimes.
        this.typeSelfEmployedAbOrgnr = employment.typeSelfEmployedAbOrgnr || null

        if (employment.employedStartDate) {
          this.employedStartDate = DateTime.fromFormat(employment.employedStartDate, 'yyyy-MM-dd')
        }
        if (employment.temporaryEmployedEndDate) {
          this.temporaryEmployedEndDate = DateTime.fromFormat(employment.temporaryEmployedEndDate, 'yyyy-MM-dd')
        }
      })
  }
}
