import {Component, inject, OnInit, signal} from '@angular/core'
import {StageBaseComponent, SUPPLEMENT_DOCUMENT} from '../10-common/stage-base/stage-base.component'
import {filter, switchMap} from 'rxjs'
import {IBorgoApplicationResult} from 'sparbanken-syd-borgo'
import {Router} from '@angular/router'
import {LOAN_ROUTE_PATH} from '../../application/data-types'

@Component({
  selector: 'spb-supplement',
  templateUrl: './supplement.component.html',
  styleUrl: '../10-common/stage-base/stage-base.scss'
})
export class SupplementComponent extends StageBaseComponent implements OnInit {

  public application!: IBorgoApplicationResult

  public documentsAreReady = signal<boolean>(false)

  private router = inject(Router)

  public override ngOnInit(): void {
    super.ngOnInit()
    this.applicationLoaded$
      .pipe(
        switchMap(() => this.applicationService.currentApplicationResult$),
        filter(Boolean)
      ).subscribe({
      next: a => {
        this.application = a
        // Happens to be supplement document, make these constants.
        this.stepper.selectedIndex = SUPPLEMENT_DOCUMENT
      }
    })
  }


  public done() {
    this.router.navigate(['/', LOAN_ROUTE_PATH, 'erbjudande',
      this.caseId, this.applicationId]).then()
  }
}
