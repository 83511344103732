import {Component, effect} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {filter} from 'rxjs/operators'
import {KycBaseItemComponent} from '../0-base/kyc-base-item.component'
import {KycItemContact} from '../../model/kyc-item-contact'

@Component({
  selector: 'spb-kyc-contact',
  templateUrl: './kyc-contact.component.html',
  styleUrl: '../kyc-items.scss',
  providers: [{provide: KycBaseItemComponent, useExisting: KycContactComponent}]
})
export class KycContactComponent extends KycBaseItemComponent {


  public override form = new FormGroup({
    phone: new FormControl<string | null>(null,
      {validators: [Validators.required, Validators.minLength(10)]}),
    email: new FormControl<string | null>(null,
      {validators: [Validators.required, Validators.email]})
  })

  /**
   * Public for testing only
   */
  public contact = new KycItemContact({}, {} as any)

  constructor() {
    super()

    effect(() => {
      const input = this.input()
      this.contact = input.contact
      this.form.patchValue(this.contact.contact, {emitEvent: true})

      this.contact.isValid$.subscribe(this.updateKyc)

      this.contact.value$.subscribe({
        next: v => this.form.patchValue(v)
      })
    })

    this.form.valueChanges
      .pipe(filter(Boolean))
      .subscribe({
        next: (v) => {
          this.contact.setValue(v)
        }
      })
  }
}
