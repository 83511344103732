import {Component, Input, OnInit} from '@angular/core'
import {IBorgoApplicantIdentity, IBorgoApplicationResult, IBorgoLoanApplicant} from 'sparbanken-syd-borgo'

@Component({
  standalone: true,
  imports: [],
  template: ''
})
export class RoBaseComponent implements OnInit {

  @Input({required: true}) application!: IBorgoApplicationResult

  public applicants: Array<IBorgoApplicantIdentity & IBorgoLoanApplicant> = []

  public ngOnInit(): void {
    this.applicants = this.application.application.applicants.map((a, i) => {
      return Object.assign({}, a, this.application.complementaryInfo.applicantIdentity[i])
    })
  }
}
