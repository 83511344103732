import {Component, EventEmitter, inject, Input, OnChanges, OnInit, Output} from '@angular/core'
import {
  IBorgoApplicationResult,
  IBorgoSupplementDocument,
  IBorgoSupplementDocumentRequest,
  TBorgoDocumentContentTypes
} from 'sparbanken-syd-borgo'
import {UserService} from '../../../services/user.service'
import {IFileUpload} from '../../../application/uploader'
import {DocumentSupplementMap} from '@sparbanken-syd/borgo-helpers'
import {filter, Observable, of, switchMap} from 'rxjs'
import {WINDOW} from '../../../application/window.provider'
import {MatDialog} from '@angular/material/dialog'
import {WaitComponent} from '../../../common/dialogs/wait/wait.component'

@Component({
  selector: 'spb-document',
  templateUrl: './document.component.html',
  styleUrl: './document.component.scss'
})
export class DocumentComponent implements OnInit, OnChanges {
  @Input({required: true}) document: IBorgoSupplementDocument = {} as IBorgoSupplementDocument

  @Input({required: true}) application!: IBorgoApplicationResult

  @Output() completed = new EventEmitter<boolean>()

  public documentMap = DocumentSupplementMap

  public hideUpload = false

  public error: boolean = false

  /**
   * A map of Identity > name
   */
  public applicants: Record<string, string> = {}

  private window = inject(WINDOW)

  private hiddenDocumentUploads = ['SALARY_SPEC_BASIS']

  private userService = inject(UserService)

  private dialog = inject(MatDialog)

  public ngOnInit(): void {
    this.application.complementaryInfo.applicantIdentity.forEach((a) => {
      this.applicants[a.applicantId] = a.givenName
    })
  }

  public ngOnChanges(): void {
    this.hideUpload = this.hiddenDocumentUploads
      .indexOf(this.document.supplementaryType) !== -1
    this.completed.next(this.document.status !== 'MISSING' || this.hideUpload)
  }

  public sendDocument(data: IFileUpload): void {
    const ref = this.dialog.open(WaitComponent, {
      data: {text: 'Laddar upp...', title: 'Vänta'}
    })

    data.name = data.name.replace(/[^\w,\s.-]/g, '_')
    const document: IBorgoSupplementDocumentRequest = {
      documentId: this.document.documentId,
      fileName: data.name,
      fileType: this.convertDocumentType(data),
      issueId: this.document.issueId,
      supplementaryType: this.document.supplementaryType,
      fileContent: data.content
    }
    this.validate(data).pipe(
      filter(Boolean),
      switchMap(() => this.userService
        .uploadDocument(this.application!.caseId, this.application!.applicationId, document))
    )
      .subscribe({
        next: (r) => {
          this.document = r
          this.ngOnChanges()
          ref.close()
        }
      })
  }

  public getDocument(documentId: string): void {
    this.userService.getDocument(this.application!.caseId, documentId).subscribe({
      next: (v) => {
        /**
         * We get a base64 encoded string, we convert that string to a string of bytes (atob)
         * for each "byte" we convert it to an ascii code (e.g. 'A' = 65)
         */
        const byteNumbers = atob(v.fileContent)
          .split('')
          .map(a => a.charCodeAt(0))
        /**
         * The Blob constructor expects an array of parts, each part
         * must be a TypedArray in this case UInt8 (all numbers are 0 -255)
         */
        const blob = new Blob([new Uint8Array(byteNumbers)],
          {type: `${this.toContentType(v.fileType)};base64`})
        const url = URL.createObjectURL(blob)
        // It will show all supported formats inline in a popup window
        // all other files will be downloaded.
        this.window.open(url, 'blank', 'popup=true')
      }
    })
  }

  private validate(file: IFileUpload): Observable<boolean> {
    this.error = false
    this.error = file.size > 1024 * 1024
    return of(!this.error)
  }

  private convertDocumentType(file: IFileUpload): TBorgoDocumentContentTypes {
    const ext = file.name.split('.').pop() as string
    return ext.toUpperCase() as TBorgoDocumentContentTypes

  }

  private toContentType(ext: TBorgoDocumentContentTypes): string {
    const map: Record<TBorgoDocumentContentTypes, string> = {
      PDF: 'application/pdf',
      PNG: 'image/png',
      JPG: 'image/jpg',
      JPEG: 'image/jpeg',
      DOC: 'application/msword',
      DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
    return map[ext]
  }
}
