<div class="holder">
  <div class="row-holder">
    @if (loanObject) {
      <div class="row-item">
        <div>Bostad:</div>
        <div>{{ loanObject.type | propertyType }}</div>
      </div>
      <div class="row-item">
        <div>Adress:</div>
        <div>{{ loanObject.street }}</div>
      </div>
      <div class="row-item">
        <div>Nuvarande lån:</div>
        <div>{{ sumOfLoans | number : '1.0-0': 'fr' }} kr</div>
      </div>
      <div class="row-item">
        <div>Driftskostnad:</div>
        <div>{{ loanObject.operatingCost | number : '1.0-0': 'fr' }} kr / mån</div>
      </div>
      @if (loanObject.apartment) {
        <div class="row-item">
          <div>Avgift:</div>
          <div>{{ loanObject.apartment.monthlyFee | number : '1.0-0': 'fr' }} kr / mån</div>
        </div>
        <div class="row-item">
          <div>Boyta:</div>
          <div>{{ loanObject.apartment.area }} m<sup>2</sup></div>
        </div>
      }
      @if (loanObject.house) {
        @if (loanObject.house.fullValueInsurance) {
          <div class="row-item">
            <div>Fullvärdesförsäkring:</div>
            <div>Ja</div>
          </div>
        } @else {
          <div class="row-item">
            <div>Försäkringsbelopp:</div>
            <div>{{ loanObject.house.insuranceAmount | number : '1.0-0': 'fr' }}</div>
          </div>

          <div class="row-item">
            <div>Självrisk:</div>
            <div>{{ loanObject.house.insuranceExcessAmount | number : '1.0-0': 'fr' }}</div>
          </div>
        }
      }
      <div class="row-item">
        <div>Försäkringsbolag:</div>
        @if (loanObject.insuranceCompany !== 'OTHER_INSURANCE_COMPANY') {
          <div>{{ loanObject.insuranceCompany! | insuranceCompany }}</div>
        } @else {
          <div>{{ loanObject.otherInsuranceCompanyName }}</div>
        }
      </div>
    }
  </div>
</div>