<mat-card [class]="{ error: !formValid$() && !edit()}">
  <mat-card-header>
    <div class="kyc-header">
      <div>PEP</div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (edit()) {
      <mat-radio-group [formControl]="ctrl" color="primary">
        <mat-radio-button [value]="false">Nej</mat-radio-button>
        <mat-radio-button [value]="true">Ja</mat-radio-button>
      </mat-radio-group>
    } @else {
      PEP: {{ ctrl.value ? 'Ja' : 'Nej' }}
    }
    <div [style.margin-top.px]="20">
      <spb-pep></spb-pep>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <spb-kyc-footer
      [(edit)]="edit"
      [formValid]="formValid$()"
      [changes]="pep"></spb-kyc-footer>
  </mat-card-actions>
</mat-card>
