import {Injectable} from '@angular/core'
import {map, switchMap} from 'rxjs/operators'
import {Observable} from 'rxjs'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {environment} from '../../environments/environment'

/**
 * Describes the latest valid interest
 */
export interface LatestValidInterest {
  month3: number
  year1: number
  year2: number
  year3: number
  year4: number
  year5: number
  disclaimerInterest: number
  interestStartDate: number
}

@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get the latest valid interest RT
   */
  public getLatestInterest(): Observable<LatestValidInterest> {
    const url = `${environment.rtUrl}/interests/latest`
    return this.httpClient.get<LatestValidInterest>(url)
  }

  /**
   * Get disclaimer text from RT
   */
  public getDisclaimer(iR: number, iStart: number): Observable<string> {
    let headers = new HttpHeaders()
    headers = headers.set('Content-Type', 'text/plain')

    const p = 480
    const a = 1000000
    const sF = 0

    const url = `${environment.rtUrl}/interests/` +
      `disclaimer?periods=${p}&amount=${a}&interestRate=${iR}&startFee=${sF}&interestStartDate=${iStart}`
    return this.httpClient.get(url, {
      headers,
      responseType: 'text'
    })
      .pipe(
        map((disclaimer: string) => disclaimer)
      )
  }

  /**
   * As it works now, we first need to get the interest that we want to use for the disclaimer
   */
  public showDisclaimer(): Observable<string> {
    return this.getLatestInterest().pipe(
      switchMap((latestInterest: LatestValidInterest) => {
        const iR = latestInterest.disclaimerInterest / 100
        const iStart = latestInterest.interestStartDate
        return this.getDisclaimer(iR, iStart)
      })
    )
  }
}
