@if (!application) {
  <spb-progress-ng
    message="Kontrollerar ansökan"
    [minimal]="false"
  ></spb-progress-ng>
}
<div [ngClass]="{hidden: !application}">
  <mat-stepper
    [style.max-width.px]="600"
    linear
    orientation="vertical"
    spbStepperScroll>
    @for (s of [1, 2, 3, 4, 5, 6]; track $index) {
      <mat-step
        [editable]="true"
        [label]="labelMap.get(s)!" completed>
        @if (application) {
          @if (s === 1) {
            <spb-ro-applicants
              [application]="application">
            </spb-ro-applicants>
            <spb-ro-costs
              [application]="application">
            </spb-ro-costs>
          }
          @if (s === 2) {
            <spb-ro-household
              [application]="application">
            </spb-ro-household>
          }
          @if (s === 3) {
            <spb-ro-main-property
              [application]="application">
            </spb-ro-main-property>
          }
          @if (s === 4) {
            <spb-ro-other-living
              [application]="application">
            </spb-ro-other-living>
          }
          @if (s === 5) {
            <spb-ro-new-loan
              [application]="application">
            </spb-ro-new-loan>
          }
          @if (s === 6) {
            <spb-select-advice
              [selected]="adviceSelection">
            </spb-select-advice>
          }
        }

      </mat-step>
    }
    <mat-step
      [completed]="false"
      [label]="labelMap.get(7)!">
      @if (application) {
        <spb-documents
          [application]="application"
          (documentsAreReady)="documentsAreReady.set($event)"
          [applicationStatus]="applicationStatus"
        ></spb-documents>
      }
      <button
        (click)="done()"
        [disabled]="!documentsAreReady()" color="primary" mat-raised-button>Klar
      </button>
    </mat-step>
    @for (s of [8, 9, 10, 11]; track $index) {
      <mat-step
        [editable]="false"
        [label]="labelMap.get(s)!"></mat-step>
    }
  </mat-stepper>
</div>
