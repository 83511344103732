import {Component} from '@angular/core'
import {UserService} from '../../../services/user.service'
import {SelectorBaseComponent} from '../selector-base.component'
import {IBorgoApplicationListItem, TBorgoApplicationStatus, TBorgoCaseStatus} from 'sparbanken-syd-borgo'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {catchError, NEVER} from 'rxjs'

/**
 * Interface to adapt to SelectorBase we
 * should remove this and replace with a
 * basic select.
 */
export interface IApplicationSelect {
  application: IBorgoApplicationListItem
}

@Component({
  selector: 'spb-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['../selectors.scss', './applications.component.scss']
})
export class ApplicationsComponent extends SelectorBaseComponent<IApplicationSelect> {
  public availableApplications: IBorgoApplicationListItem[] = []

  public ctrl = new FormControl<IBorgoApplicationListItem | null>(null, {validators: [Validators.required]})

  protected override form = new FormGroup({application: this.ctrl})

  public error: boolean = false

  public noData: boolean = false

  public createApplication: boolean = false

  constructor(
    public userService: UserService) {
    super()
    this.userService.getApplications().pipe(
      catchError(() => {
        this.error = true
        return NEVER
      })
    ).subscribe({
      next: r => {
        const hiddenStatuses: TBorgoApplicationStatus[] = [
          'LOAN_ACTIVATED',
          'LOAN_DISBURSED',
          'APPLICATION_COMPLETED',
          'APPLICATION_TERMINATED',
          'PRELIMINARY_APPROVED_CREDIT_DECISION'
        ]
        const hiddenCaseStatus: TBorgoCaseStatus[] = ['CLOSED', 'ARCHIVED']

        this.availableApplications = r
          .filter(a => a.purpose === 'INCREASE')
          .filter(a => !a.status || hiddenStatuses.indexOf(a.status) === -1)
          .filter(a => !a.caseStatus || hiddenCaseStatus.indexOf(a.caseStatus) === -1)

        if (this.availableApplications.length === 0) {
          this.createApplication = true
        }
        // Select the first one and hide the selector
        if (this.availableApplications.length === 1) {
          this.noData = true
          this.form.controls.application.setValue(this.availableApplications[0])
        }
      }
    })
  }
}
