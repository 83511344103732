import {KycItem} from './kyc-item'
import {IBorgoApplication, IKycIncomeDataset, IKycPersonDataset} from 'sparbanken-syd-borgo'
import {Income} from '../../../model/income/income'

export class KycItemIncome extends KycItem<IKycIncomeDataset> {

  private pIncome: number = 0

  constructor(input: Partial<IKycPersonDataset> = {}, application: IBorgoApplication) {
    super(input)
    this.input = input
    this.setFromInput()
    this.setFromApplication(application)
    this.isValid()
  }

  get income(): number {
    return this.pIncome
  }

  public setValue(v: number) {
    this.getPersonDataSet().grossSalary = v
    this.changes$.set(this.differs())
    this.isValid()
  }

  public override reset() {
    this.setValue(this.pIncome)
    this.value$.next(this.getPersonDataSet())
  }

  private differs(): boolean {
    return this.pIncome !== this.getPersonDataSet().grossSalary
  }

  private isValid(): void {
    this.isValid$.next(this.getPersonDataSet().grossSalary > 0)
  }

  private setFromApplication(application: IBorgoApplication): void {
    const income = new Income(application, 0, 'EMPLOYMENT')
    this.pIncome = income.income || this.pIncome
    this.setValue(this.pIncome)
  }

  private setFromInput(): void {
    this.pIncome = this.getPersonDataSet().grossSalary
  }

  private getPersonDataSet(): IKycIncomeDataset {
    if (!this.input.personRegulatoryDataset) {
      this.input.personRegulatoryDataset = {
        personIncomeDataset: {grossSalary: 0}
      }
    }

    if (!this.input.personRegulatoryDataset.personIncomeDataset) {
      this.input.personRegulatoryDataset.personIncomeDataset = {
        grossSalary: 0
      }
    }
    return this.input.personRegulatoryDataset.personIncomeDataset
  }
}
