import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core'
import {FILE_READER, fileReaderProvider} from '../../../application/fileReader.provider'
import {IFileUpload, Uploader} from '../../../application/uploader'


@Component({
  selector: 'spb-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [fileReaderProvider]
})
export class FileUploadComponent extends Uploader implements OnInit {
  @Input() text: string = 'Välj fil'

  @Input() color: string = 'primary'
  /**
   * Emit metadata about the file after upload.
   * There might be more than one, but we emit them piece
   * by piece.
   */
  @Output() files = new EventEmitter<IFileUpload>()

  constructor(
    @Inject(FILE_READER) fileReader: FileReader) {
    super(fileReader)
  }

  public ngOnInit(): void {
    this.result$.pipe(
    ).subscribe({
      next: (data: IFileUpload) => {
        this.files.emit(data)
      }
    })
  }
}
