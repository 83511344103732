<!-- always put them in form for consistency -->
<form>
  <mat-form-field>
    <mat-label>Ny total skuld</mat-label>
    <input [formControl]="form.controls.totalLoanAmount" matInput spbFormatNumber>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Belopp att låna</mat-label>
    <input [formControl]="form.controls.amount" matInput spbFormatNumber>
    <span matTextSuffix>kr</span>
    <mat-error>Lånebelopp kan vara från 200 000 kr till total max belåning 15 milj kr</mat-error>
  </mat-form-field>
</form>