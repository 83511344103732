<div class="holder">
  <div class="row-holder">
    @for (accommodation of accommodations; track $index) {
      <div class="row-item">
        <div>Bostad:</div>
        <div>{{ accommodation.type | propertyType }}</div>
      </div>
      <div class="row-item">
        <div>Driftskostnad:</div>
        <div>{{ accommodation.operatingCost | number: '1.0-0' : 'fr' }} kr / mån</div>
      </div>
      @if (accommodation.monthlyFee) {
        <div class="row-item">
          <div>Avgift:</div>
          <div>{{ accommodation.monthlyFee | number: '1.0-0' : 'fr' }} kr / mån</div>
        </div>
      }
      <div class="row-item">
        <div>Summa lån:</div>
        <div>{{ accommodation.creditAmount | number: '1.0-0' : 'fr' }} kr</div>
      </div>
      @if (accommodation.creditAmount > 0) {
        <div class="sub-heading">Lån</div>
        @for (loan of accommodation.credits; track $index) {
          <div class="row-item">
            <div>Kreditgivare</div>
            @if (loan.otherCreditorName) {
              <div>{{ loan.otherCreditorName }}</div>
            } @else {
              <div>{{ loan.creditor | creditor }}</div>
            }
          </div>
          <div class="row-item">
            <div>Lånebelopp:</div>
            <div>{{ loan.amount | number: '1.0-0' : 'fr' }} kr</div>
          </div>
          <div class="row-item">
            <div>Bindningstid:</div>
            <div>{{ loan.rateFixationPeriod | binding }}</div>
          </div>
          <div class="row-item">
            <div>Amortering:</div>
            <div>{{ loan.monthlyAmortization | number: '1.0-0': 'fr' }} kr / mån</div>
          </div>
        }
      }
    } @empty {
      <div class="sub-heading">Inga övriga boenden</div>
    }
  </div>
</div>

