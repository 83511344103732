import {Component, EventEmitter, inject, Input, OnChanges, Output} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {catchError, debounceTime, distinctUntilKeyChanged, filter, NEVER} from 'rxjs'
import {UserService} from '../../../services/user.service'
import {IBorgoCollateral} from 'sparbanken-syd-borgo'
import {Router} from '@angular/router'
import {LOAN_ROUTE_PATH} from '../../../application/data-types'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {ProgressNgComponent} from '../../../common/components/progress/progress-ng.component'
import {MatOption, MatSelect} from '@angular/material/select'
import {MatInput} from '@angular/material/input'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'


@Component({
  selector: 'spb-collateral',
  standalone: true,
  templateUrl: './collateral.component.html',
  imports: [
    MatFormField,
    ReactiveFormsModule,
    ProgressNgComponent,
    MatSelect,
    MatOption,
    MatInput,
    MatLabel,
    MatSuffix,
    ThemeModule
  ],
  styleUrls: ['../selectors.scss', './collateral.component.scss']
})
export class CollateralComponent implements OnChanges {
  /**
   * If the collateral is set from outside?
   */
  @Input() selectCollateral: string | undefined

  @Output() resultValue = new EventEmitter<IBorgoCollateral>()

  public availableCollaterals: IBorgoCollateral[] = []

  public ctrl = new FormControl<IBorgoCollateral | null>(null, {validators: [Validators.required]})

  public selectedCollateral: IBorgoCollateral | null = null

  public addressCtrl = new FormControl<string | null>({
    value: null,
    disabled: true
  })

  public currentValuationCtrl = new FormControl<number | null>({
    disabled: true,
    value: 0
  })

  public error: boolean = false

  public noData: boolean = false

  private router = inject(Router)

  constructor(private userService: UserService) {
    /**
     * Call this on construction time since
     * the available collaterals will never change
     * for a logged-in user.
     */
    this.getCollaterals()

    this.ctrl.valueChanges.pipe(
      filter(Boolean),
      debounceTime(10),
      distinctUntilKeyChanged('id')
    ).subscribe({
      next: (collateral: IBorgoCollateral) => {
        this.selectedCollateral = collateral
        this.addressCtrl.setValue(collateral.collateralObjectAddress.street1)
        this.currentValuationCtrl.setValue(collateral.valuation?.marketValue)
        this.resultValue.emit(collateral)
      }
    })
  }

  public ngOnChanges(): void {
    /**
     * Must reset and possibly enable again or
     * the change detection from the parent does
     * not trigger.
     */
    this.ctrl.reset(null, {emitEvent: false})
    this.ctrl.enable({emitEvent: false})
    this.selectedCollateral = null
    if (this.availableCollaterals.length === 1) {
      this.ctrl.setValue(this.availableCollaterals[0])
      // Send first, disable later! Or do not disable at all.
      this.ctrl.disable()
    }

    if (this.selectCollateral) {
      const collateral =
        this.availableCollaterals.find(c => c.id === this.selectCollateral)
      if (collateral) {
        this.ctrl.setValue(collateral)
        this.selectedCollateral = collateral
      }
    }
  }

  /**
   * We need a way to get this data...
   */
  private getCollaterals(): void {
    this.userService.getCollaterals()
      .pipe(catchError(() => {
        this.error = true
        return NEVER
      }))
      .subscribe({
        next: (r) => {
          this.noData = r.length === 0
          if (this.noData) {
            this.router.navigate(['/', LOAN_ROUTE_PATH, 'fel']).then()
          }
          this.availableCollaterals = r
          this.ngOnChanges()
        }
      })
  }
}
