/**
 * This is a base component for all the steps in the
 * application.
 *
 * 1. I loads the application if caseId/application Id is
 * in the url
 *
 * 2. It keeps track of the step number and name.
 *
 * 3. It loads the MatStepper as a child.
 */

import {Component, inject, OnInit, ViewChild} from '@angular/core'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {of, ReplaySubject, switchMap} from 'rxjs'
import {UserService} from '../../../services/user.service'
import {MatStepper} from '@angular/material/stepper'
import {ApplicationService} from '../../../services/application.service'
import {ISavedApplication} from 'sparbanken-syd-borgo'


export const SUPPLEMENT_DOCUMENT = 6
export const ALL_IS_DONE = 11

@Component({
  selector: 'spb-stage-base',
  template: ''
})
export class StageBaseComponent implements OnInit {

  @ViewChild(MatStepper) public stepper!: MatStepper

  protected labelMap = new Map<number, string>([
    [1, 'Sökande'],
    [2, 'Hushållet'],
    [3, 'Låneobjekt'],
    [4, 'Annat boende'],
    [5, 'Hur mycket vill ni låna?'],
    [6, 'Ansök'],
    [SUPPLEMENT_DOCUMENT + 1, 'Komplettering'],
    [8, 'Behandlar ansökan'],
    [9, 'Val av lånevillkor'],
    [10, 'Kundkännedom'],
    [ALL_IS_DONE, 'Lånehandlingar']
  ])

  protected caseId: string = ''
  protected applicationId: string = ''

  /**
   * Only public for testing purposes
   */
  public applicationLoaded$ = new ReplaySubject<boolean>(1)

  protected userService = inject(UserService)

  protected applicationService = inject(ApplicationService)

  public applicationStatus: Partial<ISavedApplication> | null = null

  /**
   * Populate the selection of advice (Bolånerådgivning) here.
   */
  public adviceSelection: boolean | null = null

  private route = inject(ActivatedRoute)

  public ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
            if (params.get('caseId')) {
              this.caseId = params.get('caseId') as string
              this.applicationId = params.get('applicationId') as string
              return this.userService.getApplication(this.caseId, this.applicationId)
            }
            return of(null)
          }
        ),
        switchMap(() => this.applicationService.currentSaveData$)
      ).subscribe({
      next: (s) => {
        this.applicationStatus = s
        this.adviceSelection = s!.declinesAdvice
        this.applicationLoaded$.next(true)
      }
    })
  }
}
