import {Base} from '../base/base'
import {IBorgoChild, TBorgoChildCustody} from 'sparbanken-syd-borgo'
import {TChildrenAge} from '@sparbanken-syd/borgo-helpers'

export class Child extends Base {
  /**
   * We have a slightly different form than Borgo
   * atm This is to prove a point I guess.
   */
  age: TChildrenAge | null = null
  custody: TBorgoChildCustody | null = null
  income: boolean | null = null
  cost: boolean | null = null
  shared: boolean | null = null

  /**
   * Children are leaf objects and do not really care about
   * the full application. Still we try to stay uniform in
   * coding and make this behave as all others.
   */
  constructor(child: Partial<IBorgoChild> = {}) {
    super()
    this.setFrom(child)
  }

  /**
   * A simple converter that refuses to return items
   * that are not valid.
   */
  public toBorgo(): IBorgoChild | null {
    if (this.isValid()) {
      return {
        age: this.age as TChildrenAge,
        custody: this.custody as TBorgoChildCustody,
        costMaintenanceSupport: this.cost as boolean,
        incomeMaintenanceSupport: this.income as boolean
      }
    }
    return null
  }

  public override isValid(): boolean {
    // All values must have a value. Please do not force set values to invalid values
    return this.age !== null && this.custody !== null &&
      typeof this.income === 'boolean' && typeof this.cost === 'boolean'
  }

  private setFrom(child: Partial<IBorgoChild>): void {
    // All the assertions are needed because we are Partial.
    this.age = child.age as TChildrenAge ?? null
    this.custody = child.custody ?? null
    this.income = child.incomeMaintenanceSupport ?? null
    this.cost = child.costMaintenanceSupport ?? null
    if (this.age !== null) {
      this.shared = this.income !== null || this.cost !== null || this.custody !== null
    }
  }
}
