import {Component, Input} from '@angular/core'
import {CountryMap} from '../../../model/countries'
import {sortOriginal} from '../../../../../../application/helpers'
import {FormGroup} from '@angular/forms'

@Component({
  selector: 'spb-kyc-tax-input',
  templateUrl: './kyc-tax-input.component.html',
  styleUrl: '../../kyc-items.scss'
})
export class KycTaxInputComponent {

  @Input({required: true}) form!: FormGroup<any>

  protected readonly CountryMap = CountryMap
  protected readonly sortOriginal = sortOriginal
}
