import {Component} from '@angular/core'
import {RoBaseComponent} from '../0-base/ro-base.component'
import {DecimalPipe, JsonPipe} from '@angular/common'
import {CreditPipe, LoanPipe} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-ro-costs',
  standalone: true,
  imports: [
    DecimalPipe,
    JsonPipe,
    LoanPipe,
    CreditPipe
  ],
  templateUrl: './ro-costs.component.html',
  styleUrl: '../read-only.scss'
})
export class RoCostsComponent extends RoBaseComponent {

}
