export class ProblemSolver {

  public solved: string[] = []
  public raw: string[] = []

  constructor(error: any) {
    if (error.error?.errorMessage) {
      try {
        JSON.parse(error.error.errorMessage)
      } catch (e) {
        /**
         * Empty for a reason
         */
      }
    }
  }
}
