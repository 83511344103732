import {Base} from '../base/base'
import {IBorgoApplication, IBorgoLoanHouseHold} from 'sparbanken-syd-borgo'
import {Accommodation} from '../accommodation/accommodation'
import {Children} from '../children/children'

/**
 * A Household consist of children and accommodations.
 * On top it has a number 1..4 and an ID that is a string
 * generated by Borgo.
 *
 * The children is just the ordinary children
 *
 * There
 */
export class Household extends Base {

  public accommodations: Accommodation[] = []

  public children: Children = new Children()

  /**
   * Let us start with one and expand this later.
   */
  public index = 0

  public override toApplication(application: Partial<IBorgoApplication>) {
    /**
     * Make sure our items do their thing
     */
    this.accommodations.forEach(a => a.toApplication(application))

    /**
     *
     */
    const household: IBorgoLoanHouseHold = {
      accommodation: this.accommodations
        .map(a => a.toBorgo()),
      child: this.children.getBorgo(),
      householdNumber: this.index + 1
    }

    /**
     *
     */
    this.children.toApplication(application)

    // Make sure we have a household array
    application.household = application.household || []

    // Remove this if it exists and add it back as a new one.
    application.household = application.household
      .filter(h => h.householdNumber !== this.index + 1)
    application.household.push(household)
  }
}
