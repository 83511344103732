import {Component, inject, OnInit} from '@angular/core'
import {WINDOW} from '../../application/window.provider'
import {catchError, filter, first, NEVER, switchMap} from 'rxjs'
import {LoginResponse, UserService} from '../../services/user.service'
import {ActivatedRoute, Router} from '@angular/router'
import {LOAN_ROUTE_PATH} from '../../application/data-types'
import {HttpErrorResponse} from '@angular/common/http'

@Component({
  selector: 'spb-start',
  templateUrl: './start.component.html'
})
export class StartComponent implements OnInit {

  private service = inject(UserService)
  private injectedWindow = inject(WINDOW)
  private router = inject(Router)
  private route = inject(ActivatedRoute)
  protected loading = false

  public ngOnInit() {
    this.service.token$.pipe(
      filter(Boolean),
      first(),
      // Update loans and other static stuff.
      switchMap(() => {
        this.loading = true
        return this.service.getUser()
      }),
      switchMap(() => this.service.getLoans()),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 404) {
          this.router.navigate(['/', LOAN_ROUTE_PATH, 'fel']).then()
        }

        return NEVER
      })
    ).subscribe({
      next: () => this.router.navigate(['/', LOAN_ROUTE_PATH, 'välj'])
    })

    this.route.paramMap.subscribe({
      next: m => {
        if (m.get('login')) {
          this.loading = true
          this.start()
        }
      }
    })
  }

  /**
   * Starts the login with Borgo, eventually we will
   * get a URL that we open. This URL will take us to
   * Borgo and from there we are gone, permanently!
   */
  public start(): void {
    this.loading = true
    this.service.login().subscribe(
      {
        next: (res: LoginResponse) => {
          this.injectedWindow.location.href = res.url
        }
      }
    )
  }
}
