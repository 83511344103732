import {IBorgoApplication, IBorgoLoanApplicantIncome, TBorgoIncomeType} from 'sparbanken-syd-borgo'
import {Base} from '../base/base'


export interface IIncome {
  /**
   * An amount per month before tax.
   */
  income: number

  /**
   * The type of income, 'EMPLOYMENT' | 'CAPITAL'
   */
  type: TBorgoIncomeType
}

export class Income extends Base implements IIncome {

  /**
   * Default we have zero.
   */
  income: number = 0

  constructor(application: Partial<IBorgoApplication> = {},
              public index: number = 0, public type: TBorgoIncomeType = 'EMPLOYMENT') {
    super(application)
    this.setFrom(application)
  }

  /**
   * Set _this_ income to the target application
   * @param input
   */
  public override toApplication(input: Partial<IBorgoApplication>): void {
    if (input.applicants && input.applicants[this.index]) {
      const applicant = input.applicants[this.index]
      applicant.monthlyIncome = applicant.monthlyIncome || []
      // Remove existing of this type.
      applicant.monthlyIncome = applicant.monthlyIncome
        .filter(i => i.type !== this.type)
      applicant.monthlyIncome.push({
        type: this.type,
        amount: this.income,
        currency: 'SEK'
      })
      // Finally remove if they are zero.
      applicant.monthlyIncome = applicant.monthlyIncome.filter(i => i.amount > 0)
    }
  }

  public override isValid(): boolean {
    return this.income > 0
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    /**
     * Incomes on the application is an array, actually a tuple of
     * two incomes. If the type matches our type we map/reduce the
     * value to us.
     */
    this.getApplicantPropertyOrNot<IBorgoLoanApplicantIncome[]>(application, this.index, 'monthlyIncome')
      .forEach(incomes => {
        return this.income = incomes
          .filter(i => i.type === this.type)
          .map(i => i.amount)
          .reduce((sum, val) => sum + val, 0)
      })
  }
}
