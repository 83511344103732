import {Component, OnChanges, OnInit} from '@angular/core'
import {SelectorBaseComponent} from '../selector-base.component'
import {FormArray, FormControl, FormGroup} from '@angular/forms'
import {IChildSelect} from './child-select/child-select.component'
import {Child} from '../../model/children/child'
import {Children} from '../../model/children/children'
import {ListHandler} from '../list-handler'
import {ChildComponent} from './child/child.component'

@Component({
  selector: 'spb-children',
  templateUrl: './children.component.html',
  styleUrls: ['../selectors.scss']
})
export class ChildrenComponent extends SelectorBaseComponent<Children> implements OnInit, OnChanges {

  /**
   * Passed to child (sic!) component
   */
  public select: IChildSelect = {count: 0, have: null}

  /**
   * Provides an empty form since arrays do not behave
   */
  protected override form = new FormGroup({
    children: new FormArray<FormControl<Child>>([])
  })

  public childrenHandler = new ListHandler<Child>(this.form.controls.children)

  public override ngOnChanges() {
    // We cannot call super if the children is null.
    // So we first check and then pass the empty array
    this.form.controls.children.clear({emitEvent: false})
    this.childrenHandler = new ListHandler<Child>(this.form.controls.children)
    this.childrenHandler.controlGenerator = ChildComponent.generateForm

    this.select = {have: null, count: 0}
    if (this.initial.children) {
      this.select.have = this.initial.children.length > 0
      this.select.count = this.initial.children.length
      // The initial value must contain real children
      this.childrenHandler.setItems(this.initial.children)
    }
  }

  /**
   * Called from the form only
   */
  public setSelect(select: IChildSelect): void {
    if (!select.have) {
      this.childrenHandler.clear()
    } else {
      this.setForm(this.initial.children?.length || 1)
    }
  }

  private setForm(count: number): void {
    const existing = [...this.initial.children ?? []]
    this.childrenHandler.clear()
    for (let i = 0; i < count; i++) {
      this.childrenHandler.add(existing[i] || new Child())
    }
  }
}
