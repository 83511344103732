@if (user.name) {
  <form>
    <mat-form-field>
      <mat-label>Namn</mat-label>
      <input matInput disabled [value]="user.name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Personnummer</mat-label>
      <input matInput disabled [value]="user.personNummer">
    </mat-form-field>
  </form>
} @else {
  <spb-progress-ng></spb-progress-ng>
}