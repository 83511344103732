<mat-card [class]="{ error: !formValid$() && !edit()}">
  <mat-card-header>
    <div class="kyc-header">
      <div>Anställning</div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (employment) {
    @if (edit()) {
      <spb-employment
        (resultValue)="update($event)"
        [initial]="employment!"
      ></spb-employment>
    } @else {
      <div class="set-values">
        <div>Sysselsättning: {{ employment.employmentType! | employment }}</div>
        @if (employment.shouldHaveEmployerName()) {
          <div>Arbetsgivare: {{ employment.employerName }}</div>

          @if (employment.employedStartDate) {
            <div>Anställningsdatum: {{ employment.employedStartDate.toISODate() }}</div>
          }
          @if (employment.employmentType === 'TEMPORARY_EMPLOYED' && employment.temporaryEmployedEndDate) {
            <div>Slutdatum: {{ employment.temporaryEmployedEndDate.toISODate() }}</div>
          }
        }
      </div>
    }
    }
  </mat-card-content>
  <mat-card-actions>
    @if (kycItemEmployment) {
      <spb-kyc-footer
        [canEdit]="!primary()"
        [(edit)]="edit"
        [formValid]="formValid$()"
        [changes]="kycItemEmployment"></spb-kyc-footer>
    }
  </mat-card-actions>
</mat-card>