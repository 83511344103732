import {Component, effect} from '@angular/core'
import {KycBaseItemComponent} from '../0-base/kyc-base-item.component'
import {IKycTax, KycItemTax} from '../../model/kyc-item-tax'
import {FormControl, FormGroup, FormRecord} from '@angular/forms'
import {sortOriginal} from '../../../../../application/helpers'
import {merge} from 'rxjs'

@Component({
  selector: 'spb-kyc-tax',
  templateUrl: './kyc-tax.component.html',
  styleUrl: '../kyc-items.scss',
  providers: [{provide: KycBaseItemComponent, useExisting: KycTaxComponent}]
})
export class KycTaxComponent extends KycBaseItemComponent {

  public taxation = new KycItemTax()

  protected readonly sortOriginal = sortOriginal

  public override form = new FormRecord({})

  public hasTwo = new FormControl<boolean>(false)
  public hasThree = new FormControl<boolean>(false)

  constructor() {
    super()

    this.form.addControl('first', this.getTaxForm())
    this.form.addControl('second', this.getTaxForm())
    this.form.addControl('third', this.getTaxForm())

    effect(() => {
      const input = this.input()
      this.taxation = input.taxation
      this.updateForm(this.taxation.taxation)
      this.hasTwo.setValue(this.taxation.taxation.countries.length > 1)
      this.hasThree.setValue(this.taxation.taxation.countries.length > 2)
      this.taxation.isValid$.subscribe(this.updateKyc)

      this.taxation.value$.subscribe({
        next: v => this.updateForm(v)
      })
    })


    merge(this.form.valueChanges, this.hasTwo.valueChanges, this.hasThree.valueChanges)
      .subscribe({
        next: () => {
          this.sendValues()
        }
      })

  }

  public getForm(name: string): any {
    return this.form.get(name)
  }

  private updateForm(v: IKycTax): void {
    ['first', 'second', 'third'].forEach((k: string, i: number) => {
      if (v.countries[i]) {
        this.form.get(k)?.patchValue(v.countries[i])
      }
    })

  }

  private getTaxForm(): FormGroup {
    return new FormGroup({
      country: new FormControl<string>('', {validators: []}),
      tinCode: new FormControl<string>('', {validators: []})
    })
  }

  private sendValues(): void {
    this.taxation.setValue({
      countries: [
        this.getForm('first').value,
        this.getForm('second').value,
        this.getForm('third').value
      ]
    })
  }
}