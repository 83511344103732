import {KycItem} from './kyc-item'
import {IKycPersonDataset} from 'sparbanken-syd-borgo'

export class KycItemCitizen extends KycItem<string[]> {


  private readonly pCitizen: string[]

  constructor(input: Partial<IKycPersonDataset> = {}) {
    super(input)
    this.pCitizen = [...this.getCitizen()]
  }

  get citizen(): string[] {
    return this.getCitizen()
  }

  public setValue(citizens: string[]): void {
    this.setCitizen(citizens)
    this.isValid()
  }

  public override reset(): void {
    const current = this.getCitizen()
    current.length = 0
    this.setValue(this.pCitizen)
    this.changes$.set(this.differs())
  }

  private differs(): boolean {
    return this.pCitizen[0] !== this.getCitizen()[0] || this.pCitizen[1] !== this.getCitizen()[1]
  }

  private isValid(): void {
    this.isValid$.next(this.getCitizenShip().nationality.length > 0)
  }

  private getCitizen(): string[] {
    return this.getCitizenShip().nationality.filter(v => v)
  }

  private setCitizen(citizens: string[]): void {
    this.getCitizenShip().nationality = citizens as string[]
  }

  private getCitizenShip(): { nationality: string[] } {
    if (!this.input.personBasicDataset) {
      this.input.personBasicDataset = {
        personBiographicalDataset: {nationality: []}
      }
    }
    if (!this.input.personBasicDataset.personBiographicalDataset) {
      this.input.personBasicDataset.personBiographicalDataset = {nationality: []}
    }
    return this.input.personBasicDataset.personBiographicalDataset! as { nationality: [] }
  }
}
