<form [formGroup]="form">
  <spb-yes-no-input
    (resultValue)="form.controls.guaranteeCommitmentAmount.setValue($event)"
    [ctrl]="form.controls.guaranteeCommitmentAmount"
    [initialState]="initialGuarantee"
    (resultAnswer)="form.controls.guaranteeSelect.setValue($event)"
    inputInfoText="Ange din del av det totala borgensåtagandet."
    inputLabel="Belopp"
    inputSuffix="kr"
    yesNoLabel="Finns borgensåtagande?"
  ></spb-yes-no-input>

  <spb-yes-no-input
    [ctrl]="form.controls.taxDefermentAmount"
    (resultValue)="form.controls.taxDefermentAmount.setValue($event)"
    [initialState]="initialTaxDefer"
    (resultAnswer)="form.controls.taxSelect.setValue($event)"
    inputInfoText="Ange din del av det totala uppskovsbeloppet."
    inputLabel="Belopp"
    inputSuffix="kr"
    yesNoLabel="Finns uppskov från försäljning av bostad?"
  ></spb-yes-no-input>
</form>
<spb-yes-no-radio
  [externalControl]="loansHandler.have"
  leadText="Finns övriga privatlån?"
></spb-yes-no-radio>
@if (loansHandler.have.value) {
  <div class="spb-form-explanation-text">Ta bara upp din del av den totala skulden om det är så att du delar privatlånet
    med medlåntagaren i denna ansökan
  </div>
  @for (loan of loansHandler.list; track loan) {
    <div class="spb-sub-section-header">Uppgifter om privatlånet</div>
    <div class="spb-list-item">
      <spb-additional-loan
        [initial]="loan"
        (resultValue)="loansHandler.set($event, $index)"
      ></spb-additional-loan>
      <button (click)="loansHandler.remove($index)" color="warn" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
  <button [style.margin-top.px]="15" mat-stroked-button (click)="loansHandler.add()">Fler</button>
}
<spb-yes-no-radio
  [externalControl]="creditsHandler.have"
  leadText="Finns övriga krediter?"
></spb-yes-no-radio>
@if (creditsHandler.have.value) {
  <div class="spb-form-explanation-text">Ta bara upp din del av den totala skulden om det är så att du delar krediten
    med medlåntagaren i denna ansökan.
  </div>
  @for (credit of creditsHandler.list; track credit) {
    <div class="spb-sub-section-header">Uppgifter om krediten</div>
    <div class="spb-list-item">
      <spb-additional-credit
        [initial]="credit"
        (resultValue)="creditsHandler.set($event, $index)"
      ></spb-additional-credit>
      <button (click)="creditsHandler.remove($index)" color="warn" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  }
  <button [style.margin-top.px]="15" mat-stroked-button (click)="creditsHandler.add()">Fler</button>
}
