import {Component, OnDestroy, OnInit, signal} from '@angular/core'
import {applicationEditable, getApplicationToSend, sortOriginal} from '../../application/helpers'
import {catchError, filter, NEVER, Subject, Subscription, switchMap, throttleTime} from 'rxjs'
import {IBorgoApplication, IBorgoApplicationResult, IBorgoCollateral, IBorgoUserLoan} from 'sparbanken-syd-borgo'
import {MatDialog} from '@angular/material/dialog'
import {IWaitComponentData, WaitComponent} from '../../common/dialogs/wait/wait.component'
import {environment} from '../../../environments/environment'
import {Insurance} from '../model/insurance/insurance'
import {Children} from '../model/children/children'
import {Accommodation} from '../model/accommodation/accommodation'
import {Household} from '../model/household/household'
import {Contact} from '../model/contact/contact'
import {Valuation} from '../model/valuation/valuation'
import {LoanAmount} from '../model/loan-amount/loan-amount'
import {LOAN_ROUTE_PATH} from '../../application/data-types'
import {KycPurpose} from '../model/kyc-purpose/kyc-purpose'
import {StageBaseComponent} from '../10-common/stage-base/stage-base.component'
import {PathSelector} from '../2-select/path-selector/path-selector'
import {ApplyErrorComponent} from './apply-error/apply-error.component'


@Component({
  selector: 'spb-update-application',
  templateUrl: './update-application.component.html',
  styleUrl: './update-application.component.scss'
})
export class UpdateApplicationComponent extends StageBaseComponent implements OnInit, OnDestroy {

  public application: IBorgoApplicationResult | null = null
  public developerMode = environment.developerMode

  protected readonly sortOriginal = sortOriginal

  public currentLoanIds: string[] = []

  public currentLoans: IBorgoUserLoan[] = []
  /**
   * This is the Borgo version of a collateral
   */
  public selectedCollateral: IBorgoCollateral | null = null
  public selectCollateral = signal<string | undefined>(undefined)
  /**
   *
   */
  public insurance: Insurance = new Insurance()

  /**
   * Make sure we have at least one, the primary, id est the loan object
   */
  public accommodation: Accommodation = new Accommodation()

  // Children are for HOUSEHOLD
  public children: Children = new Children()


  public household: Household = new Household()

  public valuation: Valuation = new Valuation()

  public loanAmount = new LoanAmount()

  public kycPurpose = new KycPurpose()

  /**
   * Emit here when you want to save or apply to prevent
   * double clicks.
   */
  public clicker = new Subject<boolean>()

  /**
   * We have this public so that we can view it in template
   */
  public applicationToSend: IBorgoApplication = getApplicationToSend()

  public loanAdviceSelected: boolean | null = null

  public applicantsValid = signal<boolean>(false)
  public childrenValid = signal<boolean>(false)
  public accommodationValid = signal<boolean>(false)
  public insuranceValid = signal<boolean>(false)
  public loanAmountValid = signal<boolean>(false)
  public kycValid = signal<boolean>(false)

  protected readonly applicationEditable = applicationEditable

  protected loanPath = LOAN_ROUTE_PATH

  private sub$ = new Subscription()

  constructor(
    private dialog: MatDialog,
    private pathSelector: PathSelector
  ) {
    super()
    /**
     * Prevents double clicks
     */
    this.clicker.pipe(
      throttleTime(2000)
    ).subscribe({
      next: r => {
        this.update(r)
      }
    })
  }

  /**
   * If we are a "house", id est, not an apartment?
   */
  get house(): boolean {
    return this.applicationToSend.loanObject!.type !== 'APARTMENT'
  }


  public override ngOnInit(): void {
    super.ngOnInit()
    // User _must have been called first
    // Move this to the user component
    this.setUser()
    this.userService.getLoans().subscribe()
    this.sub$ = this.applicationService.currentApplicationResult$
      .pipe(
        filter(Boolean))
      .subscribe({
        next: (a) => {
          this.reset()
          this.setApplication(a)
        }
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public selectAdvice(selection: boolean): void {
    this.loanAdviceSelected = selection
  }

  public update(apply: boolean): void {
    // Always increase
    this.applicationToSend.purpose = 'INCREASE'

    const data = {
      applicationId: this.applicationService.applicationId$(),
      caseId: this.applicationService.caseId$(),
      application: this.applicationToSend,
      readyForDecision: apply
    }
    const ref =
      this.dialog.open<WaitComponent, IWaitComponentData, any>(WaitComponent, {
        data: {
          title: 'Sparar...',
          text: 'Vi sparar och kontrollerar din information'
        },
        disableClose: true
      })
    ref.afterOpened().pipe(
      switchMap(() => this.userService.updateApplication(data)),
      catchError((e) => {
        ref.close()
        this.dialog.open(ApplyErrorComponent, {data: e})
        return NEVER
      }),
      switchMap((r) => {
        if (r.validationProblems) {
          const issues = r.validationProblems.map(p => {
            return `<li>${p.jsonPath}</li>`
          })
          this.dialog.open(WaitComponent, {
            data: {
              title: 'Valideringsproblem',
              html: `<ul style="color: red">${issues}</ul>`,
              closable: true
            },
            disableClose: true
          })
          ref.close()
          return NEVER
        } else {
          return this.userService.getApplication(data.caseId as string, data.applicationId as string)
        }
      }),
      filter(Boolean)
    ).subscribe({
      next: res => {
        this.application = res
        if (res?.validationIssues) {
          res.validationIssues.issue.forEach(i => {
            console.log(i.code, i.description)
            if (i.code === '412') {
              this.dialog.open(WaitComponent, {
                data: {
                  title: 'Omöjlig uppgift',
                  html: '<p style="color: red">Kunden har trasig adress, byt kund denna är förstörd</p>',
                  closable: true
                },
                disableClose: false
              })
            }
          })

        }
        ref.close()
        this.reset()
        if (apply) {
          this.pathSelector.select(res.caseId, res.applicationId, res.applicationStatus)
        } else {
          this.setApplication(res)
        }
      }
    })

  }

  /**
   * When someone selects a collateral. From the collateral
   * selector.
   */
  public setCollateral(collateral: IBorgoCollateral): void {
    this.selectedCollateral = collateral

    // Set this to trigger change in the selector.
    // Also used as input to the same selector!
    this.selectCollateral.set(collateral.id)

    // Remember to replace object
    this.accommodation = new Accommodation(this.application?.application)
    this.household.accommodations = [this.accommodation]

    // Doing this should update the "loanObject" with the collateral info.
    // It does not set the loans. We wait for the loan component to
    // select a new collateral and fetch the loans.
    this.accommodation.setLoanObjectFromCollateral(collateral, this.applicationToSend)
    this.valuation.setCollateral(collateral)
  }


  /**
   * This is a list of loan ids on a collateral. It returns
   * a list of IDs set on the collateral. The loans may also
   * exist on the user, in that case we take all loans that
   * match them.
   * @param ids
   */
  public setCurrentLoanIds(ids: string[]): void {
    this.currentLoanIds = ids

    this.currentLoans = this.applicationService.currentUserLoans$()
      .filter(ul => ids.indexOf(ul.accountId) !== -1)

    this.accommodation.setCreditsFromUserLoans(this.currentLoans)
    this.household.toApplication(this.applicationToSend)
    this.loanAmount.toApplication(this.applicationToSend)
  }

  /**
   * Updates all model values until we have converted
   * all models... TODO: Refactor this.
   */
  public setUpdated(): void {
    /**
     * Relating to the house
     */
    this.insurance.toApplication(this.applicationToSend)
    this.valuation.toApplication(this.applicationToSend)

    this.household.children = this.children
    this.household.toApplication(this.applicationToSend)
  }


  public setUser(): void {
    const contact = new Contact()
    contact.email = this.applicationService.currentUser$().emails[0]
    contact.phone = this.applicationService.currentUser$().phones[0]
    // TODO: this.contact = contact
  }

  /**
   * Thries to update all items based on some input.
   * @param r
   */
  public setApplication(r: IBorgoApplicationResult): void {
    this.reset()
    // The intention of this is to populate already set values
    // If we first reset, all controls will be destroyed and then populated
    const input = r.application as IBorgoApplication
    // Replacing the object causes change detection
    this.insurance = new Insurance(input)

    this.children = new Children(input)
    // Sets the existing if any.
    this.loanAmount = new LoanAmount(input)

    this.kycPurpose = new KycPurpose(input)
    /**
     * If the received application has a set id for collateral
     * we expect that we can set the current collateral based on
     * this id.
     */
    this.valuation = new Valuation(input)
    this.selectCollateral.set(input?.loanObject?.externalReferences[0]?.id)
    this.application = r
    // Update all values=?!?!?
    this.setUpdated()

  }

  public reset(): void {
    this.insurance = new Insurance()
    //
    this.household = new Household()
    this.accommodation = new Accommodation()
    this.household.accommodations = [this.accommodation]
    this.children = new Children()
    this.valuation = new Valuation()

    // Do NOT reset the loans as they are fetched during login
    // this.existingUserLoans = []
    this.loanAmount = new LoanAmount()
    this.kycPurpose = new KycPurpose()
    this.applicationToSend = getApplicationToSend()

    this.selectCollateral.set(undefined)
    this.selectedCollateral = null
    this.application = null
  }
}
