<mat-card [class]="{ error: !formValid$() && !edit()}">
  <mat-card-header>
    <div class="kyc-header">
      <div>Adress</div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (edit()) {
      <form [formGroup]="form">
        <mat-form-field>
          <mat-label>Gatuadress</mat-label>
          <input matInput formControlName="street">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Postnummer</mat-label>
          <input matInput formControlName="postalCode">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Postort</mat-label>
          <input matInput formControlName="location">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Land</mat-label>
          <mat-select formControlName="country">
            @for (country of CountryMap | keyvalue:sortOriginal; track $index) {
              <mat-option [value]="country.key">{{ country.value }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    } @else {
      <div>{{ form.controls.street.value }}</div>
      <div>{{ form.controls.postalCode.value }} {{ form.controls.location.value }}</div>
      <div>{{ form.controls.country.value | country }}</div>
    }
  </mat-card-content>
  <mat-card-actions>
    <spb-kyc-footer
      [(edit)]="edit"
      [formValid]="formValid$()"
      [changes]="address"></spb-kyc-footer>
  </mat-card-actions>
</mat-card>