import {Base} from '../base/base'
import {
  IBorgoApplicantObligations,
  IBorgoApplication,
  IBorgoObligationBase,
  IBorgoObligationCredit,
  IBorgoObligationLoan,
  TBorgoCreditor
} from 'sparbanken-syd-borgo'
import {BorgoCreditorsMap} from '@sparbanken-syd/borgo-helpers'

export class Obligations extends Base {
  selected: boolean = false
  guaranteeSelect: boolean | null = null
  guaranteeCommitmentAmount: number | null = null
  taxSelect: boolean | null = null
  taxDefermentAmount: number | null = null
  loans: IBorgoObligationLoan[] | null = null
  credits: IBorgoObligationCredit[] | null = null

  constructor(application: Partial<IBorgoApplication> = {}, private index: number = 0) {
    super(application)
    this.setFrom(application)
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    this.getApplicantPropertyOrNot<IBorgoApplicantObligations>(application, this.index, 'obligations')
      .forEach(o => {
        this.selected = true
        this.guaranteeCommitmentAmount = o.guaranteeCommitmentAmount ?? 0
        this.taxDefermentAmount = o.taxDefermentAmount ?? 0
        this.guaranteeSelect = true
        this.taxSelect = true
        if (o.loan) {
          this.loans = o.loan.map(l => ({...l}))
        }

        if (o.credit) {
          this.credits = o.credit.map(l => ({...l}))
        }
      })
  }


  public override toApplication(application: Partial<IBorgoApplication>): void {
    if (application.applicants && application.applicants[this.index] && this.isValid()) {
      application.applicants[this.index].obligations = {
        taxDefermentAmount: this.taxDefermentAmount as number,
        guaranteeCommitmentAmount: this.guaranteeCommitmentAmount as number,
        loan: this.convertCreditorNames<IBorgoObligationLoan>(this.loans as IBorgoObligationLoan[]),
        credit: this.convertCreditorNames<IBorgoObligationCredit>(this.credits as IBorgoObligationCredit[])
      }
    }
  }


  public override isValid(): boolean {
    return this.taxSelect !== null && this.guaranteeSelect !== null &&
      this.loans !== null && this.credits !== null
  }

  /**
   * Convert a key like Borgo or Avanza to a  name like Borgo or Avanza
   * Used
   */
  private convertCreditorNames<T extends IBorgoObligationBase>(items: T[]): T[] {
    return items
      .filter(v => !!v)
      .map((v: T) => {
        const creditor = this.translateCreditor(v.creditor)
        v.interestRate = v.interestRate + ''
        v.interestRate = v.interestRate.replace(/,/g, '.')
        return {...v, creditor}
      })
  }

  private translateCreditor(name: TBorgoCreditor | string): TBorgoCreditor {
    if (BorgoCreditorsMap.has(name as TBorgoCreditor)) {
      return BorgoCreditorsMap.get(name as TBorgoCreditor)!.name as TBorgoCreditor
    }
    return name as TBorgoCreditor
  }
}
