import {Component, OnInit, signal} from '@angular/core'
import {filter, switchMap} from 'rxjs'
import {environment} from '../../../../environments/environment'
import {ALL_IS_DONE, StageBaseComponent} from '../../10-common/stage-base/stage-base.component'
import {IBorgoApplicationResult} from 'sparbanken-syd-borgo'

@Component({
  selector: 'spb-offer-start',
  templateUrl: './offer-start.component.html',
  styleUrl: '../../10-common/stage-base/stage-base.scss'
})
export class OfferStartComponent extends StageBaseComponent implements OnInit {

  public developerMode = environment.developerMode

  public blockProgress = signal<boolean>(true)

  public termsSelected: boolean = false
  public kycCompleted: boolean = false

  public allIsDone = ALL_IS_DONE

  public application: IBorgoApplicationResult | null = null

  public override ngOnInit(): void {
    super.ngOnInit()
    this.applicationLoaded$
      .pipe(
        switchMap(() => this.applicationService.currentApplicationResult$),
        filter(Boolean)
      ).subscribe({
      next: a => {
        this.application = a
        this.blockProgress.set(this.applicationStatus?.readyForUser !== true)

        if (a.applicationStatus === 'PENDING_CREDIT_DECISION') {
          this.blockProgress.set(true)
          setTimeout(() => {
            this.stepper.selectedIndex = 7
          }, 1)

        }

        if (a.applicationStatus === 'REJECTED_CREDIT_DECISION') {
          this.blockProgress.set(true)
          setTimeout(() => {
            this.stepper.selectedIndex = 7
          }, 1)

        }

        const stepBase = this.blockProgress() ? 0 : 1

        if (a.applicationStatus === 'APPROVED_CREDIT_DECISION') {
          setTimeout(() => {
            this.stepper.selectedIndex = 7 + stepBase
          }, 1)

        }

        if (a.applicationStatus === 'WAITING_CUSTOMER_ONBOARDED') {
          this.termsSelected = true
          setTimeout(() => {
            this.stepper.selectedIndex = 9
          }, 1)
        }

        if (a.applicationStatus === 'CREDIT_PREPARATION') {
          this.termsSelected = true
          this.kycCompleted = true
          this.blockProgress.set(false)
          setTimeout(() => {
            this.stepper.selectedIndex = 10
          }, 1)
        }
      }
    })
  }
}
