import {KycItem} from './kyc-item'
import {IBorgoAddress, IKycPersonDataset} from 'sparbanken-syd-borgo'

export interface IKycAddress {
  street?: string
  country?: string
  location?: string
  postalCode?: string

}

export class KycItemAddress extends KycItem<IKycAddress> {

  private pAddress: IBorgoAddress | null = null

  private street: string = ''
  private location: string = ''
  private postalCode: string = ''
  private country: string = ''

  /**
   * Set the original from the incoming KYC
   */
  constructor(input: Partial<IKycPersonDataset> = {}) {
    super(input)
    this.setAddress(input)
  }


  get address(): IKycAddress {
    return this.returnValue()
  }

  public setValue(input: IKycAddress): void {
    if (!this.input.addresses) {
      this.input.addresses = []
    }

    let existing = this.input.addresses
      .find(a => a.addressType === 'PRIMARY')

    if (!existing) {
      existing = {
        addressValid: true,
        addressType: 'PRIMARY'
      } as any
      this.input.addresses.push(existing!)
    }
    existing!.addressLine = [input.street as string]
    existing!.location = input.location as string
    existing!.postalCode = input.postalCode as string
    existing!.country = input.country as string

    this.street = input.street!
    this.location = input.location!
    this.postalCode = input.postalCode!
    this.country = input.country!

    // Make sure to emit any differences.
    this.changes$.set(this.differs())
  }

  public override reset() {
    this.setFrom({addresses: [this.pAddress as any]})
    this.value$.next(this.returnValue())
    this.changes$.set(this.differs())
  }

  /**
   * Returns true if any value differs from our "original" value.
   * @private
   */
  public differs(): boolean {
    if (this.pAddress) {
      return this.pAddress.addressLine.join(', ') !== this.street ||
        this.pAddress.country !== this.country ||
        this.pAddress.location !== this.location ||
        this.pAddress.postalCode !== this.postalCode
    }
    return true
  }

  private returnValue(): IKycAddress {
    return {
      country: this.country,
      postalCode: this.postalCode,
      street: this.street,
      location: this.location
    }
  }

  private setAddress(input: Partial<IKycPersonDataset>): void {
    /**
     * If addresses is present it is an array,
     * if not addresses we will have "null" as
     * input.
     */
    if (input.addresses) {
      this.pAddress = input.addresses
        .filter(a => a)
        .filter(a => a.addressType === 'PRIMARY')
        .map(a => ({
          addressLine: a.addressLine.map(s => s),
          country: a.country,
          postalCode: a.postalCode,
          location: a.location,
          addressType: a.addressType,
          addressValid: a.addressValid
        }))[0]
    }

    if (this.pAddress) {
      this.country = this.pAddress.country
      this.street = this.pAddress.addressLine.join(', ')
      this.postalCode = this.pAddress.postalCode
      this.location = this.pAddress.location
    }
  }

  private setFrom(from: Partial<IKycPersonDataset>): void {
    this.street = ''
    this.country = ''
    this.postalCode = ''
    this.location = ''
    if (from.addresses) {
      let existing = from.addresses
        .filter(a => a)
        .find(a => a.addressType === 'PRIMARY')

      if (!existing) {
        existing = {
          addressLine: [],
          addressValid: true,
          addressType: 'PRIMARY'
        } as any
      }
      this.country = existing!.country
      this.street = existing!.addressLine.join(', ')
      this.postalCode = existing!.postalCode
      this.location = existing!.location
    }
  }
}
