import {Component, Input} from '@angular/core'
import {IPartyData, IUserDataResult} from 'sparbanken-syd-borgo'
import {UserService} from '../../../services/user.service'

/**
 * This component just presents the fixed information,
 * name and personnummer that we get from Borgo. They
 * are non-editable.
 */
@Component({
  selector: 'spb-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['../selectors.scss']
})
export class UserInfoComponent {
  @Input({required: true}) user: IPartyData & IUserDataResult = {} as any

  constructor(
    userService: UserService
  ) {
    userService.getUser().subscribe({
      next: t => this.user = t
    })
  }
}
