import {AbstractControl, FormArray, FormControl} from '@angular/forms'

/**
 * You have a list of objects and a FormArray ot the
 * same. You want to keep these in sync.
 *
 * The List Handler to rescue.
 */
export class ListHandler<T> {

  /**
   * You can have or not have items.
   */
  public have = new FormControl<boolean | null>(null)

  /**
   * This is your primed list of items.
   */
  public list: Partial<T>[] = []

  /**
   * Provide a generator for your wanted control
   */
  public controlGenerator: () => AbstractControl = () => new FormControl({})

  /**
   *
   * @param array - The FormArray to keep in sync.
   */
  constructor(
    private array: FormArray<any>
  ) {
    this.have.valueChanges.subscribe({
      next: r => {
        if (r) {
          if (this.list.length === 0) {
            this.add()
          }
        } else {
          this.list.length = 0
          this.array.clear()
        }
      }
    })
  }

  public setItems(items?: T[]): void {
    if (items) {
      items.forEach((i) => {
        this.add(i)
      })
      this.have.setValue(items.length > 0, {emitEvent: true})
    }
  }

  public set(v: T, index: number): void {
    this.array.at(index).patchValue(v)
  }

  public add(v?: T): void {
    this.list.push(v ?? {})
    const form = this.controlGenerator()
    form.patchValue(v)
    this.array.push(form)
  }

  public remove(index: number): void {
    this.list.splice(index, 1)
    this.array.removeAt(index)
    if (this.list.length === 0) {
      this.clear()
    }
  }

  public clear(): void {
    this.list.length = 0
    this.array.clear()
    this.have.setValue(null)
  }
}
