import {Component} from '@angular/core'
import {SelectorBaseComponent} from '../../selector-base.component'
import {IBorgoBankAsset, TBorgoBankAssetType} from 'sparbanken-syd-borgo'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {BorgoBankAssetTypeMap} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-bank-asset',
  templateUrl: './bank-asset.component.html',
  styleUrls: ['../../selectors.scss']
})
export class BankAssetComponent extends SelectorBaseComponent<IBorgoBankAsset> {

  protected bankAssetTypes = BorgoBankAssetTypeMap

  protected override form = new FormGroup({
    type: new FormControl<TBorgoBankAssetType | null>(null, {nonNullable: true, validators: [Validators.required]}),
    bankName: new FormControl<string | null>(null, {nonNullable: true, validators: [Validators.required]}),
    amount: new FormControl<number | null>(null, {nonNullable: true, validators: [Validators.required]})
  })
}
