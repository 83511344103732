import {IBorgoApplication, IBorgoLoanApplicant} from 'sparbanken-syd-borgo'

export class Base {

  protected _application?: Partial<IBorgoApplication>

  constructor(application: Partial<IBorgoApplication> = {}) {
    this._application = application
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  public toApplication(_application: Partial<IBorgoApplication>): void {
  }

  public isValid(): boolean {
    return true
  }

  protected getApplicantPropertyOrNot<T>(application: Partial<IBorgoApplication>, index: number, prop: keyof IBorgoLoanApplicant): T[] {
    const res: T[] = []
    if (application.applicants && application.applicants[index]) {
      if (application.applicants[index][prop]) {
        res.push(application.applicants[index][prop] as T)
      }
    }
    return res
  }
}
