import {NgModule} from '@angular/core'
import {CommonModule, registerLocaleData} from '@angular/common'

import {UserRoutingModule} from './user-routing.module'
import {StartComponent} from './1-start/start.component'
import {WINDOW_PROVIDERS} from '../application/window.provider'
import {MatButtonModule} from '@angular/material/button'
import {HomeComponent} from './0-home/home.component'
import {UpdateApplicationComponent} from './3-create/update-application.component'
import {ReactiveFormsModule} from '@angular/forms'
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {TestUserComponent} from './selectors/test-user/test-user.component'
import {MatExpansionModule} from '@angular/material/expansion'
import {UserInfoComponent} from './selectors/user-info/user-info.component'
import {ExistingLoansComponent} from './selectors/existing-loans/existing-loans.component'
import {MatProgressBarModule} from '@angular/material/progress-bar'
import {CollateralLoansComponent} from './selectors/collateral-loans/collateral-loans.component'
import {InsuranceComponent} from './selectors/insurance/insurance.component'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {EmploymentComponent} from './selectors/employer/employment.component'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {AccommodationComponent} from './selectors/accomodation/accommodation.component'
import {LoanComponent} from './selectors/loan/loan.component'
import {ContactComponent} from './selectors/contact/contact.component'
import {SelectorBaseComponent} from './selectors/selector-base.component'
import {IncomeComponent} from './selectors/income/income.component'
import {MaritalStatusComponent} from './selectors/marital-status/marital-status.component'
import {SelectorComponent} from './selectors/fields/selector/selector.component'
import {ApplicationsComponent} from './selectors/applications/applications.component'
import {IsoDatePipe} from './pipes/iso-date.pipe'
import {
  ApplicationPurposePipe,
  ApplicationStatusPipe,
  EmploymentPipe,
  OccupationPipe
} from '@sparbanken-syd/borgo-helpers'
import {SelectComponent} from './selectors/applications/select/select.component'
import {MatIconModule} from '@angular/material/icon'
import {ValuationComponent} from './selectors/valuation/valuation.component'
import {FileUploadComponent} from './documents/file-upload/file-upload.component'
import {DocumentsComponent} from './documents/documents.component'
import {DocumentComponent} from './documents/document/document.component'
import {MatRadioModule} from '@angular/material/radio'
import {YesNoRadioComponent} from './selectors/fields/yes-no-radio/yes-no-radio.component'
import {ChildComponent} from './selectors/children/child/child.component'
import {ChildrenComponent} from './selectors/children/children.component'
import {ChildSelectComponent} from './selectors/children/child-select/child-select.component'
import {EnumeratePipe} from './pipes/enumerate.pipe'
import {CostComponent} from './selectors/cost/cost.component'
import {
  AdditionalObligationLoanComponent
} from './selectors/credits/additional-loan/additional-obligation-loan.component'
import {CreditsComponent} from './selectors/credits/credits.component'
import {AdditionalCreditComponent} from './selectors/credits/additional-credit/additional-credit.component'
import {CreditorComponent} from './selectors/fields/creditor/creditor.component'
import {YesNoInputComponent} from './selectors/fields/yes-no-input/yes-no-input.component'
import {BankAssetComponent} from './selectors/bank-assets/bank-asset/bank-asset.component'
import {BankAssetsComponent} from './selectors/bank-assets/bank-assets.component'
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
  MatLuxonDateModule
} from '@angular/material-luxon-adapter'
import {MAT_DATE_FORMATS} from '@angular/material/core'
import {TestComponent} from './test/test.component'
import {OperatorsComponent} from './test/operators/operators.component'
import {LoanAmountComponent} from './selectors/loan-amount/loan-amount.component'
import {OfferComponent} from './5-offer/1-offering/offer.component'
import {SelectApplicationComponent} from './2-select/select-application.component'
import {KycPurposeComponent} from './selectors/kyc-purpose/kyc-purpose.component'
import {ApplicantGroupComponent} from './groups/applicant/applicant-group.component'
import {ApplicantSectionComponent} from './20-sections/applicants/applicant-section.component'
import {
  MatStep,
  MatStepContent,
  MatStepLabel,
  MatStepper,
  MatStepperIcon,
  MatStepperNext,
  MatStepperPrevious
} from '@angular/material/stepper'
import {CreateApplicationComponent} from './selectors/applications/create/create-application.component'
import {SpbCommonModule} from '../common/common.module'
import {SupplementComponent} from './4-supplement/supplement.component'
import {OfferStartComponent} from './5-offer/0-start/offer-start.component'
import {OfferKycComponent} from './5-offer/2-kyc/offer-kyc.component'
import {KycItemsComponent} from './5-offer/2-kyc/kyc-items/kyc-items.component'
import {KycAddressComponent} from './5-offer/2-kyc/kyc-items/12-address/kyc-address.component'
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle
} from '@angular/material/card'
import {KycContactComponent} from './5-offer/2-kyc/kyc-items/10-contact/kyc-contact.component'
import {KycFooterComponent} from './5-offer/2-kyc/kyc-items/3-footer/kyc-footer.component'
import {KycBaseItemComponent} from './5-offer/2-kyc/kyc-items/0-base/kyc-base-item.component'
import {CountryPipe} from './5-offer/2-kyc/pipes/country.pipe'
import {InterestListComponent} from './5-offer/1-offering/interest-list/interest-list.component'
import {StageBaseComponent} from './10-common/stage-base/stage-base.component'
import {KycEmploymentComponent} from './5-offer/2-kyc/kyc-items/13-employment/kyc-employment.component'
import {KycPepComponent} from './5-offer/2-kyc/kyc-items/19-pep/kyc-pep.component'
import {PepComponent} from './5-offer/2-kyc/pep/pep.component'
import {KycCitizenComponent} from './5-offer/2-kyc/kyc-items/11-citizen/kyc-citizen.component'
import {KycTaxComponent} from './5-offer/2-kyc/kyc-items/14-tax/kyc-tax.component'
import {KycTaxInputComponent} from './5-offer/2-kyc/kyc-items/14-tax/kyc-tax-input/kyc-tax-input.component'
import {MatDivider} from '@angular/material/divider'
import {KycTaxOutputComponent} from './5-offer/2-kyc/kyc-items/14-tax/kyc-tax-output/kyc-tax-output.component'
import {KycIncomeComponent} from './5-offer/2-kyc/kyc-items/15-income/kyc-income.component'
import {ErrorComponent} from './9-error/error.component'
import {AlternativeComponent} from './5-offer/1-offering/alternative/alternative.component'
import {AccommodationsComponent} from './20-sections/accomodations/accommodations.component'
import {ProgressNgComponent} from '../common/components/progress/progress-ng.component'
import {PlrPipe} from '../common/pipes/plr.pipe'
import {RoApplicantsComponent} from './30-read-only/1-applicants/ro-applicants.component'
import {RoCostsComponent} from './30-read-only/2-costs/ro-costs.component'
import {RoHouseholdComponent} from './30-read-only/3-household/ro-household.component'
import {RoOtherLivingComponent} from './30-read-only/5-other-living/ro-other-living.component'
import {RoMainPropertyComponent} from './30-read-only/4-main-property/ro-main-property.component'
import {CollateralComponent} from './selectors/collaterals/collateral.component'
import {RoNewLoanComponent} from './30-read-only/6-new-loan/ro-new-loan.component'
import {SelectAdviceComponent} from './3-create/advice/select-advice.component'

registerLocaleData(localeFr, 'fr', localeFrExtra)


@NgModule({
  declarations: [
    StartComponent,
    HomeComponent,
    UpdateApplicationComponent,
    TestUserComponent,
    UserInfoComponent,
    ExistingLoansComponent,
    CollateralLoansComponent,
    InsuranceComponent,
    EmploymentComponent,
    AccommodationComponent,
    LoanComponent,
    ContactComponent,
    SelectorBaseComponent,
    IncomeComponent,
    MaritalStatusComponent,
    SelectorComponent,
    ApplicationsComponent,
    SelectComponent,
    ValuationComponent,
    FileUploadComponent,
    DocumentsComponent,
    DocumentComponent,
    YesNoRadioComponent,
    ChildComponent,
    ChildrenComponent,
    ChildSelectComponent,
    EnumeratePipe,
    CostComponent,
    AdditionalObligationLoanComponent,
    CreditsComponent,
    AdditionalCreditComponent,
    CreditorComponent,
    YesNoInputComponent,
    BankAssetComponent,
    BankAssetsComponent,
    BankAssetComponent,
    TestComponent,
    OperatorsComponent,
    LoanAmountComponent,
    OfferComponent,
    SelectApplicationComponent,
    YesNoInputComponent,
    KycPurposeComponent,
    ApplicantGroupComponent,
    ApplicantSectionComponent,
    CreateApplicationComponent,
    SupplementComponent,
    OfferStartComponent,
    OfferKycComponent,
    KycItemsComponent,
    KycAddressComponent,
    KycContactComponent,
    KycFooterComponent,
    KycBaseItemComponent,
    CountryPipe,
    InterestListComponent,
    StageBaseComponent,
    KycEmploymentComponent,
    KycPepComponent,
    KycCitizenComponent,
    KycTaxComponent,
    KycTaxInputComponent,
    KycTaxOutputComponent,
    KycIncomeComponent,
    ErrorComponent,
    AlternativeComponent,
    AccommodationsComponent
  ],
  imports: [
    CommonModule,
    SpbCommonModule,
    UserRoutingModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    ThemeModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatDatepickerModule,
    IsoDatePipe,
    ApplicationPurposePipe,
    ApplicationStatusPipe,
    MatIconModule,
    MatRadioModule,
    MatLuxonDateModule,
    MatStepper,
    MatStep,
    MatStepperPrevious,
    MatStepperNext,
    MatStepContent,
    MatCard,
    MatCardTitle,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardActions,
    MatStepLabel,
    MatStepperIcon,
    EmploymentPipe,
    PepComponent,
    OccupationPipe,
    MatDivider,
    ProgressNgComponent,
    PlrPipe,
    RoApplicantsComponent,
    RoCostsComponent,
    RoHouseholdComponent,
    RoOtherLivingComponent,
    RoMainPropertyComponent,
    CollateralComponent,
    RoNewLoanComponent,
    SelectAdviceComponent
  ],
  exports: [
    KycItemsComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, firstDayOfWeek: 1}},
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS}
  ]
})
export class UserModule {
}
