import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms'
import {Component, EventEmitter, Input, OnChanges, OnInit, output, Output} from '@angular/core'
import {sortOriginal} from '../../application/helpers'

@Component({
  template: ''
})
export class SelectorBaseComponent<T extends object> implements OnInit, OnChanges {

  @Input({required: true}) public initial!: Partial<T>

  @Output() protected resultValue = new EventEmitter<T>()

  /**
   * Signals if this item is valid or not. Use this
   * to look at different states.
   */
  public valid = output<boolean>()

  /**
   * If you have other things that needs to be included in validity state.
   */
  protected othersValid: boolean = true

  protected form: FormGroup = new FormGroup({})

  /**
   * Keep maps sorted
   */
  protected readonly sortOriginal = sortOriginal

  public ngOnInit(): void {
    this.form.valueChanges.subscribe({
      next: (res: Partial<T>) => {
        this.valid.emit(this.form.valid && this.othersValid)
        //if (this.form.valid) {
        /**
         * Expects the target to be overwritten by form controls...
         * The controls must match the object!
         *
         * The ?? {} is if someone forgets to pass in an initial object
         */
        this.resultValue.emit(Object.assign((this.initial ?? {}) as T, res))
        //}
      }
    })
    // If the subscription was not set up before the form was set
    this.valid.emit(this.form.valid && this.othersValid)
  }

  public ngOnChanges(): void {
    // Strangest thing. If I reset to nothing, the initial gets reset
    this.form.reset(this.initial)
    this.form.patchValue(this.initial)
  }

  protected setValidatorsIfRequired(set: boolean | null | undefined, ctrl: AbstractControl, validators: ValidatorFn[]): void {
    if (typeof set === 'boolean') {
      if (set) {
        ctrl.clearValidators()
        ctrl.setValidators(validators)
        ctrl.enable({emitEvent: false})
      } else {
        ctrl.clearValidators()
        ctrl.reset(null, {emitEvent: false})
        ctrl.disable({emitEvent: false}) // Disable the controls so that they do not emit.
      }
      ctrl.updateValueAndValidity({emitEvent: false})
    }
  }
}