import {Component, Input, OnChanges, output} from '@angular/core'
import {IBorgoApplication} from 'sparbanken-syd-borgo'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {getBaseApplicant} from '../../../application/helpers'
import {filter} from 'rxjs'
import {ApplicationService} from '../../../services/application.service'
import {tap} from 'rxjs/operators'

@Component({
  selector: 'spb-applicant-section',
  templateUrl: './applicant-section.component.html',
  styleUrls: ['../../selectors/selectors.scss']
})
export class ApplicantSectionComponent implements OnChanges {
  /**
   * The application we send
   */
  @Input({required: true}) applicationToSend!: IBorgoApplication

  @Input() input: IBorgoApplication | null = null

  /**
   * Emit true if all applicants are valid
   */
  public valid = output<boolean>()

  /**
   * the control for selecting applicants.
   */
  public applicantCtrl = new FormControl<boolean | null>(null,
    {validators: [Validators.required]})

  public form = new FormGroup({
    selected: this.applicantCtrl,
    applicants: new FormArray([new FormControl<boolean>(false,
      {validators: [Validators.requiredTrue]})])
  })

  constructor(private applicationService: ApplicationService) {
    this.applicantCtrl.valueChanges
      .pipe(
        filter((v): v is boolean => v !== null),
        tap(v => this.applicationService.coApplicant$.next(v))
      )
      .subscribe({
        next: (v) => {
          if (this.applicationToSend) {
            if (!v) {
              this.applicationToSend.applicants.length = 1
              this.form.controls.applicants.removeAt(1)
            } else {
              this.form.controls.applicants.push(new FormControl<boolean>(false,
                {validators: [Validators.requiredTrue]}))
            }
          }
        }
      })

    this.form.statusChanges.subscribe({
      next: () => this.valid.emit(this.form.valid)
    })
  }

  public ngOnChanges(): void {
    if (this.input) {
      const primary = this.input.applicants.find(a => a.primary === true)
      if (primary) {
        const other = this.input.applicants.filter(a => !a.primary)
        this.input.applicants = [primary, ...other]
      }
      if (this.input.applicants.length > 1) {
        this.applicantCtrl.setValue(true)
      }

      // Totally empty application let us add a base
      if (this.input.applicants.length === 0) {
        this.input.applicants.push(getBaseApplicant())
      }
    }
  }
}
