<div class="holder">
  @if (household.child && household.child.length > 1) {
    <div class="sub-heading">Barn</div>
    <div class="row-holder">
      @for (child of household.child; track $index) {
        <div class="row-item">
          <div>Del av vårdnaden</div>
          <div>{{ child.custody }} %</div>
        </div>
        <div class="row-item">
          <div>Ålder</div>
          <div>{{ child.age }} år</div>
        </div>
        <div class="row-item">
          <div>Betalar underhåll:</div>
          <div>{{ child.costMaintenanceSupport ? 'Ja' : 'Nej' }}</div>
        </div>
        <div class="row-item" [style.margin-bottom.px]="10">
          <div>Får underhållsbidrag:</div>
          <div>{{ child.incomeMaintenanceSupport ? 'Ja' : 'Nej' }}</div>
        </div>
      }
    </div>
  } @else {
    <div class="sub-heading">Inga barn i hushållet</div>
  }
</div>