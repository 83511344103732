import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {StartComponent} from './1-start/start.component'
import {HomeComponent} from './0-home/home.component'
import {UpdateApplicationComponent} from './3-create/update-application.component'
import {TestComponent} from './test/test.component'
import {SelectApplicationComponent} from './2-select/select-application.component'
import {SupplementComponent} from './4-supplement/supplement.component'
import {OfferStartComponent} from './5-offer/0-start/offer-start.component'
import {ErrorComponent} from './9-error/error.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'start',
        component: StartComponent
      },
      {
        path: 'start/:login',
        component: StartComponent
      },
      {
        path: 'välj',
        component: SelectApplicationComponent
      },
      {
        path: 'uppdatera/:caseId/:applicationId',
        component: UpdateApplicationComponent
      },
      {
        path: 'uppdatera',
        component: UpdateApplicationComponent
      },
      {
        path: 'erbjudande',
        component: OfferStartComponent
      },
      {
        path: 'erbjudande/:caseId/:applicationId',
        component: OfferStartComponent
      },
      {
        path: 'test',
        component: TestComponent
      },
      {
        path: 'komplettera/:caseId/:applicationId',
        component: SupplementComponent
      },
      {
        path: 'komplettera',
        component: SupplementComponent
      },
      {
        path: 'fel',
        component: ErrorComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'start'
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {
}
