import {Base} from '../base/base'
import {
  IBorgoApplication,
  IBorgoApplicationHouse,
  IBorgoLoanObject,
  TBorgoAccommodationType,
  TInsuranceCompany
} from 'sparbanken-syd-borgo'

export interface IInsurance {
  /**
   * Simple yes no box, if no then all is void. If yes
   * we have to enter an insurance company.
   */
  insured: boolean | null
  /**
   * Full value insured
   */
  fullValue: boolean | null

  /**
   * Company must be set if insured = true
   */
  company: TInsuranceCompany | null

  /**
   * The company name is required if "other"
   */
  companyName: string | null

  /**
   * Value must be set if not full value
   */
  insuranceAmount: number | null

  /**
   * Should be populated if not full value. This is "självrisk".
   */
  insuranceExcessAmount: number | null
  /**
   * Valid if all fields are set properly
   */
  valid: boolean
}

export class Insurance extends Base implements IInsurance {
  insured: boolean | null = null
  fullValue: boolean | null = null
  company: TInsuranceCompany | null = null
  companyName: string | null = null
  insuranceAmount: number | null = null
  insuranceExcessAmount: number | null = null

  valid: boolean = false

  private type: TBorgoAccommodationType | null = null

  constructor(application: Partial<IBorgoApplication> = {}) {
    super(application)
    this.setFrom(application)
  }

  /**
   * Called when our values have been modified, or when
   * we _think_ our values have been overwritten. Or later..
   * @param input
   */
  public override toApplication(input: Partial<IBorgoApplication>): void {
    const loanObject = input.loanObject || {} as IBorgoLoanObject
    /**
     * Reset all parameters
     */
    delete loanObject.otherInsuranceCompanyName
    delete loanObject.insuranceCompany
    loanObject.insured = false
    loanObject.house = {fullValueInsurance: false, insuranceExcessAmount: 0, insuranceAmount: 0}
    // House ...
    if (this.insured) {
      loanObject.insured = this.insured
      loanObject.insuranceCompany = this.company as TInsuranceCompany
      if (this.company === 'OTHER_INSURANCE_COMPANY') {
        loanObject.otherInsuranceCompanyName = this.companyName as string
      }
    }
    if (loanObject.type !== 'APARTMENT') {
      const house: IBorgoApplicationHouse = {
        fullValueInsurance: this.fullValue as boolean
      }

      if (!this.fullValue) {
        house.insuranceAmount = this.insuranceAmount as number
      }
      house.insuranceExcessAmount = this.insuranceExcessAmount as number
      loanObject.house = house
    } else {
      delete loanObject.house
    }
    input.loanObject = loanObject
  }

  public override isValid(): boolean {
    if (this.insured === null) {
      return false
    }
    if (this.insured) {
      const companyValid = (this.company !== null && this.company !== 'OTHER_INSURANCE_COMPANY') ||
        (this.company === 'OTHER_INSURANCE_COMPANY' && this.companyName !== null)

      if (this.type !== 'APARTMENT') {
        const valueValid = this.fullValue === true ||
          (this.fullValue === false && this.insuranceAmount !== null && this.insuranceExcessAmount !== null)
        return companyValid && (valueValid)
      } else {
        return companyValid
      }

    }
    return true
  }

  /**
   * Configures this object from an application
   */
  private setFrom(application: Readonly<Partial<IBorgoApplication>>): void {
    if (!application.loanObject) {
      return
    }
    this.type = application.loanObject.type
    if (application.loanObject.insured) {
      this.insured = true

      if (application.loanObject.insuranceCompany) {
        this.company = application.loanObject.insuranceCompany
      }

      if (application.loanObject.otherInsuranceCompanyName) {
        this.companyName = application.loanObject.otherInsuranceCompanyName
      }

      if (application.loanObject.house) {
        if (application.loanObject.house.fullValueInsurance) {
          this.fullValue = true
        } else {
          this.fullValue = false
          this.insuranceAmount = application.loanObject.house.insuranceAmount as number
          this.insuranceExcessAmount = application.loanObject.house.insuranceExcessAmount as number
        }
      }
    }
  }
}
