/**
 * This is just a dumb holder for the
 * internal routing
 */
import {Component} from '@angular/core'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
}
