import {Component} from '@angular/core'
import {RoBaseComponent} from '../0-base/ro-base.component'
import {JsonPipe} from '@angular/common'
import {IBorgoMobilePhoneNumber} from 'sparbanken-syd-borgo'
import {CivilStatusPipe, EmploymentPipe} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-ro-applicants',
  standalone: true,
  imports: [
    JsonPipe,
    CivilStatusPipe,
    EmploymentPipe
  ],
  templateUrl: './ro-applicants.component.html',
  styleUrl: '../read-only.scss'
})
export class RoApplicantsComponent extends RoBaseComponent {

  public toPhone(c: IBorgoMobilePhoneNumber): string {
    return `0${c.area}-${c.number}`.replace(/^0-$/, '')
  }
}
