@if (loading) {
  <spb-progress-ng
    message="Hämtar erbjudande"
    [minimal]="false"
  ></spb-progress-ng>
} @else if (declinesOffer) {
  En rådgivare kommer att kontakta {{ 'dig/er' | plr | async }}.
} @else {
  <div class="main">
    <div class="offer">
      Din ansökan om {{ loan | number: '1.0-0' : 'fr' }} kr är beviljad
    </div>
    <div class="example-title">Räntor</div>
    <div class="interest">Sparbanken Syd har individuell prissättning på bolån och vi
      erbjuder {{ 'dig/er' | plr | async }}
      räntor enligt
      nedanstående tabell (observera att det är dagsaktuella räntor och att dessa kan komma att ändras fram till och
      med utbetalningsdagen).
    </div>
    <div class="interest">Räntan binder du mellan tre månader och fem år (bindningstiderna är cirkatider).
      Eventuell rabatt gäller under 12 månader vid val av 3-månaders räntebindning och under hela bindningstiden vid
      val
      av räntebindning 1-5 år.
    </div>
    <div class="interests">
      <div class="interest-table">
        <spb-interest-list
          [interests]="interestRates"
        ></spb-interest-list>
      </div>
    </div>
    <div [style.margin-top.px]="15">
      Sparbanken Syd erbjuder individuell prissättning på bolån, där {{ 'din/er' | plr | async }} erbjudna ränta bland
      annat
      baseras på
      följande kriterier:
      <ul>
        <li>Hur stora bolån du har i förhållande till värdet på bostaden.</li>
        <li>Lånevolym i Sparbanken Syd eller hos vår samarbetspartner Borgo AB.</li>
        <li>Hur stor del av {{ 'dina/era' | plr | async }} övriga bankaffärer du har hos oss.
          Ju fler bankaffärer {{ 'du/ni' | plr | async }} väljer att lägga hos oss, desto
          lägre kan {{ 'din/er' | plr | async }} ränta bli.
        </li>
        <li>Det aktuella ränteläget.</li>
        <li>Den lokala marknaden.</li>
      </ul>
    </div>
    <form [formGroup]="form">
      <div>Välj önskad räntebindning</div>
      <mat-form-field>
        <mat-label>Räntebindning</mat-label>
        <mat-select
          ngDefaultControl
          placeholder="Välj i listan"
          formControlName="binding"
        >
          <mat-option [value]="null">Välj i listan</mat-option>
          @for (binding of bindings | keyvalue:sortOriginal; track $index) {
            @if ($index < 6) {
              <mat-option [value]="binding.key">{{ binding.value.name }}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>

      <div class="example-title">Amortering</div>
      <spb-alternative
        [alternative]="alternative"></spb-alternative>
      @if (alternative.hasAlternative) {
        <mat-checkbox
          [formControl]="alternativeCtrl"
          ngDefaultControl
          color="primary">{{ 'Jag/Vi'  | plr | async }} väljer amortering enligt alternativregel
        </mat-checkbox>
      }
      <mat-form-field>
        <mat-label>Amortering</mat-label>
        <input matInput spbFormatNumber formControlName="amortization">
        <span matTextSuffix [style.white-space]="'nowrap'">kr / mån</span>
      </mat-form-field>


    </form>
    @if (stampDuty !== 0) {
      <div class="example-title">Kostnader</div>
      <div [style.margin-top.px]="15">
        <p>Om {{ 'du/ni' | plr | async }} väljer att acceptera erbjudandet tillkommer Lantmäteriets avgift
          <b>{{ stamDutyFee | number : '1.0-0' : 'fr' }}</b> kr samt stämpelskatt för uttag av nytt pantbrev
          <b>{{ stampDuty | number : '1.0-0' : 'fr' }}</b> kr</p>
      </div>
      <div>
        Kostnader för uttag av pantbrev kommer att debiteras {{ 'dig/er' | plr | async }} i samband med lånets första
        förfallodag och framgår
        av {{ 'din/er' | plr | async }} första låneavi.
      </div>
    }
    <div class="example-title">Räkneexempel</div>
    <div class="disclaimer">
     {{disclaimer}}
    </div>
    <div class="buttons">
      <button
        [disabled]="form.invalid"
        (click)="sendOffer(true)" color="primary" mat-raised-button>{{ 'Jag/Vi' | plr | async }} accepterar erbjudandet
      </button>
      <button (click)="declineOffer()" mat-raised-button>{{ 'Jag/Vi' | plr | async }} avböjer erbjudandet</button>
    </div>
  </div>
}

