import {KycItem} from './kyc-item'
import {IKycPersonDataset} from 'sparbanken-syd-borgo'
import {search} from 'jmespath'

export class KycItemPep extends KycItem<boolean | null> {

  /**
   * This is the original value
   */
  private readonly pPep: boolean | null = null

  /**
   * The path is integral for all items and
   * refer to the position in the IKycPersonDatast
   * @protected
   */
  protected path = 'personRegulatoryDataset.personPepDataset'

  constructor(input: Partial<IKycPersonDataset> = {}) {
    super(input)
    // If it has the item we start with true or false.
    this.pPep = this.pep
    this.setValue(this.pPep)
  }

  get pep(): boolean | null {
    return search(this.input, this.path + '.politicallyExposed')
  }

  set pep(v: boolean) {
    this.setValue(v)
  }

  /**
   * It is valid if it has an item
   */
  public valid(): boolean {
    return this.hasItem()
  }

  public setValue(v: boolean | null): void {
    this.create()
    this.input.personRegulatoryDataset!.personPepDataset!.politicallyExposed = v as boolean
    this.changes$.set(this.pep !== this.pPep)
    this.isValid$.next(this.valid())
  }

  public override reset() {
    super.reset()
    this.setValue(this.pPep)
    this.value$.next(this.pPep)
  }

  protected create() {
    if (!this.input.personRegulatoryDataset) {
      this.input.personRegulatoryDataset = {}
    }

    if (!this.input.personRegulatoryDataset.personPepDataset) {
      this.input.personRegulatoryDataset.personPepDataset = {politicallyExposed: false}
    }
  }

  private hasItem(): boolean {
    return search(this.input, this.path) !== null
  }
}
