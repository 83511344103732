import {Component, OnChanges, OnInit} from '@angular/core'
import {SelectorBaseComponent} from '../selector-base.component'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {IBorgoObligationCredit, IBorgoObligationLoan} from 'sparbanken-syd-borgo'
import {ListHandler} from '../list-handler'
import {Obligations} from '../../model/obligations/obligations'
import {startWith} from 'rxjs/operators'

@Component({
  selector: 'spb-credits',
  templateUrl: './credits.component.html'
})
export class CreditsComponent extends SelectorBaseComponent<Obligations> implements OnInit, OnChanges {

  protected override form = new FormGroup({
    guaranteeSelect: new FormControl<boolean | null>(null, {validators: [Validators.required]}),
    guaranteeCommitmentAmount: new FormControl<number>(0),
    taxSelect: new FormControl<boolean | null>(null, {validators: [Validators.required]}),
    taxDefermentAmount: new FormControl<number | null>(0),
    credits: new FormArray<FormControl<IBorgoObligationCredit | null>>([]),
    loans: new FormArray<FormControl<IBorgoObligationLoan | null>>([])
    }
  )

  public creditsHandler = new ListHandler(this.form.controls.credits)
  public loansHandler = new ListHandler(this.form.controls.loans)

  public initialTaxDefer: boolean | null = null
  public initialGuarantee: boolean | null = null

  public override ngOnInit() {
    super.ngOnInit()
    this.form.controls.guaranteeSelect.valueChanges.pipe(
      startWith(null)
    ).subscribe({
      next: (v) => {
        this.setValidatorsIfRequired(
          v, this.form.controls.guaranteeCommitmentAmount, [Validators.required])
      }
    })

    this.form.controls.taxSelect.valueChanges.pipe(
      startWith(null)
    ).subscribe({
      next: (v) => {
        this.setValidatorsIfRequired(
          v, this.form.controls.taxDefermentAmount, [Validators.required])
      }
    })
  }

  public override ngOnChanges() {
    this.form.reset({}, {emitEvent: false})
    this.creditsHandler = new ListHandler(this.form.controls.credits)
    this.loansHandler = new ListHandler(this.form.controls.loans)

    if (this.initial.selected) {
      this.initialGuarantee = this.hasInitial(this.initial.guaranteeCommitmentAmount)
      this.initialTaxDefer = this.hasInitial(this.initial.taxDefermentAmount)
      this.form.controls.guaranteeSelect.setValue(true)
      this.form.controls.taxSelect.setValue(true)

      this.form.controls.taxDefermentAmount
        .setValue(this.initial.taxDefermentAmount ?? null, {emitEvent: false})
      this.form.controls.guaranteeCommitmentAmount
        .setValue(this.initial.guaranteeCommitmentAmount ?? null, {emitEvent: false})

      this.creditsHandler.setItems(this.initial.credits as IBorgoObligationCredit[])
      this.loansHandler.setItems(this.initial.loans as IBorgoObligationLoan[])
    }
  }

  public hasInitial(value: number | null | undefined): boolean | null {
    if (value === undefined || value === null) {
      return null
    }
    // If the value is not 0 then return true.
    return value !== 0
  }
}
