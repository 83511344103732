<form [formGroup]="form">
  @if (!primary) {
    <spb-selector
      [ctrl]="form.controls.type"
      [items]="types"
      label="Boendeform"
    ></spb-selector>
  }
  @if (form.controls.type.value === 'APARTMENT') {
    <mat-form-field>
      <mat-label>Avgift</mat-label>
      <input matInput spbFormatNumber formControlName="fee">
      <span matTextSuffix [style.white-space]="'nowrap'">kr / mån</span>
    </mat-form-field>
    @if (primary) {
      <mat-form-field>
        <mat-label>Boarea</mat-label>
        <input matInput spbFormatNumber formControlName="area">
        <span matTextSuffix>m<sup>2</sup></span>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Bostadsrättsföreningsnummer</mat-label>
        <input matInput spbFormatNumber formControlName="cooperativeTaxNumber">
      </mat-form-field>
    }
  }

  @if (form.controls.type.value === 'RENTED_APARTMENT') {
    <mat-form-field>
      <mat-label>Avgift</mat-label>
      <input matInput spbFormatNumber formControlName="rent">
      <span matTextSuffix [style.white-space]="'nowrap'">kr / mån</span>
    </mat-form-field>
  }
  <mat-form-field>
    <mat-label>Driftkostnad</mat-label>
    <input formControlName="runCost" matInput spbFormatNumber>
    <span [style.white-space]="'nowrap'" matTextSuffix>kr / mån</span>
    <mat-hint>Exempel: Hem & husförsäkring, el, bredband, uppvärmning</mat-hint>
  </mat-form-field>

</form>


@if (!primary) {
  <spb-yes-no-radio
    [externalControl]="loansHandler.have"
    leadText="Är bostaden belånad?"
  ></spb-yes-no-radio>
  @if (loansHandler.have.value) {
    @for (loan of loansHandler.list; track loan) {
      <mat-card>
        <mat-card-content>
          <div class="spb-sub-section-header">Uppgifter om bolån</div>
          <spb-loan [initial]="loan"
                    (resultValue)="loansHandler.set($event, $index)"
                    [index]="$index"></spb-loan>
        </mat-card-content>
        <mat-card-actions>
          <button (click)="loansHandler.remove($index)" color="warn" mat-icon-button>
            <mat-icon>delete</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    }
    <button
      [disabled]="form.invalid"
      [style.margin-top.px]="15" mat-raised-button (click)="loansHandler.add()">
      <mat-icon>add</mat-icon>
      Fler bolån
    </button>
  }
}
