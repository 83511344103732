import {Component, inject, OnInit, output} from '@angular/core'
import {UserService} from '../../../services/user.service'
import {KycPersonDataset} from './model/kyc-person-dataset'
import {combineLatest, filter, switchMap} from 'rxjs'
import {ApplicationService} from '../../../services/application.service'
import {KycService} from '../../../services/kyc.service'
import {MatDialog} from '@angular/material/dialog'
import {IWaitComponentData, WaitComponent} from '../../../common/dialogs/wait/wait.component'

@Component({
  selector: 'spb-offer-kyc',
  templateUrl: './offer-kyc.component.html',
  styleUrl: './offer-kyc.component.scss'
})
export class OfferKycComponent implements OnInit {

  public kycComplete = output<boolean>()

  public kycPerson: KycPersonDataset | null = null

  public kyc = inject(KycService)

  public savedKyc: boolean = false

  private userService = inject(UserService)

  private applicationService = inject(ApplicationService)

  private dialog = inject(MatDialog)

  public ngOnInit() {
    const kyc = this.userService.getKyc()
    const app = this.applicationService.currentApplicationResult$
      .pipe(filter(Boolean))

    combineLatest([kyc, app]).subscribe({
      next: ([kyc, app]) => {
        this.kycPerson = new KycPersonDataset(kyc.personDataset, app.application)
      }
    })

  }

  public setKyc(): void {
    const ref = this.dialog.open<WaitComponent, IWaitComponentData>(WaitComponent, {
      data: {
        title: 'Uppdaterar',
        text: 'Vi sparar din uppdaterade kundkännedom...',
        progress: true
      }
    })
    this.userService.setKyc(this.kycPerson!.getDiff())
      .pipe(
        switchMap(() => {
          return this.userService.onboard(
            this.applicationService.caseId$(),
            this.applicationService.applicationId$())
        })
      ).subscribe({
      next: () => {
        this.savedKyc = true
        ref.close()
      }
    })
  }

  public next(): void {
    this.userService.getApplication(
      this.applicationService.caseId$(),
      this.applicationService.applicationId$()
    ).subscribe()
  }
}
