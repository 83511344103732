<spb-child-select
  (resultValue)="setSelect($event)"
  (valid)="valid.emit($event)"
  [initial]="select"
>
</spb-child-select>

@for (child of childrenHandler.list; track child) {
  <div class="spb-sub-section-header">Uppgifter om barnet</div>
  <div class="spb-list-item">
    <spb-child
      [initial]="child"
      (resultValue)="childrenHandler.set($event, $index)"
    ></spb-child>
    <button (click)="childrenHandler.remove($index)" color="warn" mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </div>
}
@if (select.have) {
  <button (click)="childrenHandler.add()" [style.margin-top.px]="15" mat-stroked-button>Fler</button>
}