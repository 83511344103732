import {Component, OnInit} from '@angular/core'
import {SelectorBaseComponent} from '../selector-base.component'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {Valuation} from '../../model/valuation/valuation'
import {startWith} from 'rxjs/operators'

@Component({
  selector: 'spb-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['../selectors.scss']
})
export class ValuationComponent extends SelectorBaseComponent<Valuation> implements OnInit {

  public have = new FormControl()

  protected override form = new FormGroup({
    selected: new FormControl<boolean | null>(null, {nonNullable: true, validators: [Validators.required]}),
    new: new FormControl<number | null>(null, {validators: [Validators.required]})
  })

  public override ngOnInit() {
    super.ngOnInit()
    this.form.valueChanges.pipe(
      startWith(null)
    ).subscribe({
      next: () => {
        this.setValidatorsIfRequired(
          this.form.controls.selected.value, this.form.controls.new, [Validators.required])
      }
    })
  }
}
