import {Component, OnInit} from '@angular/core'
import {RoBaseComponent} from '../0-base/ro-base.component'
import {IBorgoLoanHouseHold} from 'sparbanken-syd-borgo'
import {JsonPipe} from '@angular/common'

@Component({
  selector: 'spb-ro-household',
  standalone: true,
  imports: [
    JsonPipe
  ],
  templateUrl: './ro-household.component.html',
  styleUrls: ['../read-only.scss']
})
export class RoHouseholdComponent extends RoBaseComponent implements OnInit {
  public household!: IBorgoLoanHouseHold


  public override ngOnInit() {
    super.ngOnInit()
    // we safely make the assumption that there is only one and always one household
    this.household = this.application.application.household[0]
  }
}
