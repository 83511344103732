@if (availableApplications.length > 0) {
  <p>Välj den ansökan du vill hantera</p>
  <form>
    <mat-form-field>
      <mat-label>Ansökningar</mat-label>
      <mat-select [formControl]="ctrl"
                  panelWidth="500px">
        @for (application of availableApplications; track application.applicationId) {
          <mat-option [value]="application">
            <spb-select [application]="application"></spb-select>
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </form>
  <spb-create-application></spb-create-application>

} @else if (createApplication) {
  <div [style.visibility]="'hidden'">
    <p>Ny ansökan</p>
    <spb-create-application
      [createApplication]="createApplication"
    ></spb-create-application>
  </div>
} @else {
  <spb-progress-ng
    [minimal]="false"
    message="Hämtar ansökningar"
    error="Något gick väldigt mycket sönder"
    [errored]="error"></spb-progress-ng>
}