import {Component, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {IBorgoObligationCredit, TBorgoCreditor, TBorgoObligationCreditType} from 'sparbanken-syd-borgo'
import {SelectorBaseComponent} from '../../selector-base.component'
import {BorgoObligationCreditTypeMap} from '@sparbanken-syd/borgo-helpers'

import {Creditor, ICreditor} from '../../../model/creditor/creditor'


@Component({
  selector: 'spb-additional-credit',
  templateUrl: './additional-credit.component.html',
  styleUrls: ['../../selectors.scss']
})
export class AdditionalCreditComponent extends SelectorBaseComponent<IBorgoObligationCredit> implements OnInit {

  protected initialCreditor: ICreditor = new Creditor()

  protected types = BorgoObligationCreditTypeMap

  protected override form = AdditionalCreditComponent.generateForm()

  public override ngOnInit() {
    super.ngOnInit()
    if (this.form.controls.creditor.value) {
      this.initialCreditor = Creditor.fromCredit({creditor: this.form.controls.creditor.value})
    }
  }

  public setCreditor(value: ICreditor): void {
    this.form.controls.creditor.setValue(value.otherCreditorName ?? value.creditor)
  }

  public static generateForm() {
    return new FormGroup({
      type: new FormControl<TBorgoObligationCreditType | null | undefined>(null, {
        nonNullable: true,
        validators: [Validators.required]
      }),

      // From base
      creditor: new FormControl<TBorgoCreditor | string | null | undefined>(null, {
        nonNullable: true,
        validators: [Validators.required]
      }),

      amount: new FormControl<number | null>(null, {
        nonNullable: true, validators: [
          Validators.required, Validators.min(1)
        ]
      }),
      interestRate: new FormControl<string | null>(null, {nonNullable: true, validators: []})
    })
  }

}
