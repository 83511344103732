import {
  IBorgoApplication,
  IBorgoApplicationResult,
  IBorgoLoanApplicant,
  TBorgoApplicationStatus
} from 'sparbanken-syd-borgo'

export const sortOriginal = (): number => {
  return 0
}

export const applicationEditable = (status?: TBorgoApplicationStatus): boolean => {
  const editableLoans: TBorgoApplicationStatus[] = ['UPDATED_APPLICATION', 'INITIATED', 'REJECTED_CREDIT_DECISION']
  if (!status) {
    return false
  }
  return editableLoans.indexOf(status) !== -1
}


/**
 * ONLY Use this for testing!
 */
export const getBaseApplicationResult = (): IBorgoApplicationResult => {
  return {
    applicationId: '',
    caseId: '',
    application: getBaseApplication(),
    applicationStatus: 'INITIATED',
    statusOverview: {} as any,
    validationIssues: {
      issue: []
    },
    documentsToSupplement: {
      document: []
    },
    complementaryInfo: {
      applicantIdentity: [],
      costs: [{
        type: 'MORTGAGE_DEED_AUTHORITY_HANDLING_FEE',
        amount: 1
      }]
    },
    applicationAdditionalInfo: {
      externalApplicationId: '',
      loanCreditBreakdown: null,
      timers: [{} as any]
    },
    caseAdditionalInfo: {
      externalCaseId: '',
      caseName: ''
    },
    creditBreakdownRequirements: {
      totalCreditAmount: 0,
      existingCreditAmount: 0,
      increaseCreditAmount: 0,
      amortizationBasisValue: 0,
      amortizationBasisDebt: 0,
      amortizationBasisDate: '',
      amortizationBasisTotalMonthlyAmortization: 0,
      currentRuleMonthlyAmortization: 0,
      currentRulePercentage: 2,
      currentRuleFramework: 'LTV_LTI',
      strictRuleMonthlyAmortization: 0,
      strictRulePercentage: 2,
      alternativeRuleCreditAmount: 0,
      alternativeRuleMonthlyAmortization: 0,
      alternativeRuleIncreaseCreditAmount: 0,
      alternativeRuleIncreaseMonthlyAmortization: 0
    }
  }
}
export const getBaseApplication = (): Required<IBorgoApplication> => {
  return {// Always increase but since I have built the selector...
    purpose: 'INCREASE',
    appliedCredit: {
      // Replace this with the value from the selector.
      totalCreditAmount: 0,
      currency: 'SEK' // Fixed
    },
    loanObject: {
      /**
       * We should get the loan object data from a collateral.
       * Type us HOUSE, HOLIDAY_HOUSE or APARTMENT, but the
       * value on the Collateral is VILLA or BOSTADSRÄTT?? Stupid fucks!
       */
      type: 'HOUSE',
      // Replace street and postal code from the selected collateral.
      street: '',
      postalCode: '',
      /**
       * Not really required for it to work...
       */
      insured: true,
      externalReferences: [{
        // Hard coded from Solveig, do not know where CBS comes from.
        source: 'CBS', // We let this be, let us see if it breaks.
        id: '' // we take this from the selected collateral, something like: '165455'
      }
      ],
      house: {
        // This is the bare minimum, we need a selector for this.
        fullValueInsurance: true
      },
      externalValuation: false,
      operatingCost: 0

    },
    /**
     * National ID was sufficient to populate the applicants object.
     * Everything else was optional.
     */
    applicants: [{
      /**
       * Seems to be very much required. Let us take this
       * value somewhere from the login result.
       */
      nationalId: '', // Solveig '194706195460',

      email: '',
      primary: true,
      civilStatus: {
        type: 'SINGLE' // Lacks widowed?
      },
      mobilePhoneNumber: {
        area: '703',
        number: '3274188'
      },
      /**
       * Take employment from user input, select employment type
       * and add the other mandatory fields as needed.
       *
       * It worked just fine with the basic fields
       */
      employment: {
        employmentType: 'PERMANENT_EMPLOYED',
        employedStartDate: '',
        employerName: ''
      },
      /**
       * Bombed totally with only EMPLOYMENT and amount. Works
       * fine with added currency?! Fuck up!
       */
      monthlyIncome: [
        {
          type: 'EMPLOYMENT',
          amount: 0,
          currency: 'SEK'
        }
      ],
      bankAsset: []
    }],
    household: [{
      householdNumber: 1,
      child: [],
      accommodation: [{
        type: 'HOUSE',
        operatingCost: 0,
        sellOrTerminate: false,
        currentAccommodation: false,
        creditAmount: 1000,
        groundRentAmount: 0,
        loanObject: false,
        credits: []
      }]
    }],
    complementaryData: {
      loanCreditBreakdown: {
        ruleFramework: 'LTV_LTI',
        creditBreakdown: [
          {
            order: 2,
            amount: 1000000,
            rateFixationPeriod: '36',
            monthlyAmortization: 3000,
            interestRateDiscount: '0.30',
            interestRateDiscountInMonths: '36',
            alternativeRule: false,
            creditBreakdownId: '13F00E0EA3227529E0630100007F6643',
            externalCreditId: '',
            accountNumber: ''
          }
        ]
      },
      kyc: {
        purpose: '2', // Two seems to be working for Increase??
        purposeDescription: '',
        fundingOrigination: ['0']
      }
    }
  }
}

export const getBaseApplicant = (): IBorgoLoanApplicant => (
  {
    bankAsset: [],
    civilStatus: {
      type: null as any
    },
    email: '',
    employment: {
      employmentType: null as any
    },
    mobilePhoneNumber: {
      area: '',
      number: ''
    },
    nationalId: ''
  })

/**
 * This is currently used in live data and should be
 * cleaned up!
 */
export const getApplicationToSend = (): IBorgoApplication => {
  return {
    // Always increase but since I have built the selector...
    purpose: 'INCREASE',
    appliedCredit: {
      // Replace this with the value from the selector.
      totalCreditAmount: 0,
      currency: 'SEK' // Fixed
    },
    loanObject: {
      /**
       * We should get the loan object data from a collateral.
       * Type us HOUSE, HOLIDAY_HOUSE or APARTMENT, but the
       * value on the Collateral is VILLA or BOSTADSRÄTT?? Stupid fucks!
       */
      type: 'HOUSE',
      // Replace street and postal code from the selected collateral.
      street: '',
      postalCode: '',
      /**
       * Not really required for it to work...
       */
      insured: true,
      externalReferences: [{
        // Hard coded from Solveig, do not know where CBS comes from.
        source: 'CBS', // We let this be, let us see if it breaks.
        id: '' // we take this from the selected collateral, something like: '165455'
      }
      ],
      house: {
        // This is the bare minimum, we need a selector for this.
        fullValueInsurance: true
      },
      externalValuation: false,
      operatingCost: 0

    },
    /**
     * National ID was sufficient to populate the applicants object.
     * Everything else was optional.
     */
    applicants: [],
    /**
     * Minimal data for a successful update is. Can be left empty.
     * - Household number,
     * - Child, may be empty
     *
     */
    household: [{
      householdNumber: 1,
      child: [],
      accommodation: [{
        type: 'HOUSE',
        operatingCost: 6000,
        sellOrTerminate: false,
        currentAccommodation: true,
        creditAmount: 1000,
        groundRentAmount: 0,
        loanObject: true,
        /**
         * If you are sending credits the loanObject must be _true_
         * or the application will fail 500!
         */
        //credits: [{} as any]
        // these will be populated later.
        credits: [{
          creditor: 'BORGO',
          amount: 0,
          loanNumber: '',
          monthlyAmortization: 0,
          rateFixationPeriod: '3'
        }]
      }]
    }],
    complementaryData: {
      // We have these fixed, the description should have a select box.
      /**
       * Amortering av andra krediter
       * Investering i bostaden
       * Köp av bostad eller fritidsbostad...
       */
      kyc: {
        purpose: '2', // Two seems to be working for Increase??
        purposeDescription: '',
        fundingOrigination: ['0']
      }
    }
  }
}