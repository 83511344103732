import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core'
import {IBorgoApplicationResult, IBorgoSupplementDocument, ISavedApplication} from 'sparbanken-syd-borgo'

@Component({
  selector: 'spb-documents',
  templateUrl: './documents.component.html'
})
export class DocumentsComponent implements OnChanges {
  @Input({required: true}) application!: IBorgoApplicationResult

  @Input({required: true}) applicationStatus: Partial<ISavedApplication> | null = null

  @Output() documentsAreReady = new EventEmitter<boolean>()

  public documents: IBorgoSupplementDocument[] = []

  private documentsReady: boolean[] = []

  private documentsToHide = [
    'OTHER_SUPPLEMENT', // Other has to be tied to an issue etc...
    'MARKET_VALUE_VALUATION' // We hide this for now, but it has to do with Valuation
  ]

  public ngOnChanges(): void {
    this.documents = this.application.documentsToSupplement.document.filter(d => {
      return this.documentsToHide.indexOf(d.supplementaryType) === -1
    })

    this.documents = this.documents.filter(d => this.removeEmploymentCertificate(d))

    this.documents.sort((a, b) =>
      a.supplementaryType.localeCompare(b.supplementaryType))
    this.documentsReady = this.documents.map(() => false)
    // No documents = good!
    this.documentsAreReady.emit(this.documentsReady.length === 0)

    if (this.applicationStatus) {
      if (this.applicationStatus.readyForUser) {
        this.documentsAreReady.next(true)
      }
    }
  }

  public documentReady(ready: boolean, index: number) {
    this.documentsReady[index] = ready
    this.documentsAreReady.next(this.documentsReady.every(s => s))
  }

  /**
   * Returns true if we should remove the certificate due to that
   * the applicant is pensioner.
   * @param doc
   */
  private removeEmploymentCertificate(doc: IBorgoSupplementDocument): boolean {
    return !(this.getIssueForSupplement(doc) === '224' && this.checkIfRetired())
  }

  private getIssueForSupplement(doc: IBorgoSupplementDocument): string {
    const issue =
      this.application.validationIssues.issue.find(i => i.id === doc.issueId)
    if (issue) {
      return issue.code
    }
    return '-1'
  }

  private checkIfRetired(): boolean {
    return this.application.application.applicants[0].employment.employmentType === 'PENSIONER'
  }
}
