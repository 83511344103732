import {Component} from '@angular/core'

import {ICost} from '../model/cost/cost'
import {Obligations} from '../model/obligations/obligations'
import {FormArray, FormControl, FormGroup} from '@angular/forms'
import {getBaseApplication} from '../../application/helpers'
import {Children} from '../model/children/children'
import {Employment} from '../model/employment/employment'
import {BankAssets} from '../model/asset/bank-assets'
import {Income} from '../model/income/income'
import {Valuation} from '../model/valuation/valuation'

export const fucker = (input: FormGroup | FormControl | FormArray, name: string = '') => {
  if (input instanceof FormGroup) {
    console.log(`G: ${name || ''} ${input.status}`)
    Object.entries(input.controls)
      .forEach(v => fucker(v[1] as any, `${name} -- ${v[0]}`))
  }

  if (input instanceof FormControl) {
    console.log(`C: ${name}: ${input.status} <${input.value}>`)
  }

  if (input instanceof FormArray) {
    console.log(`${name} ${input.status}`)
    Object.entries(input.controls)
      .forEach(v => fucker(v[1] as any, `A ${name} [] ${v[0]}`))
  }
}

(window as any).fucker = fucker

@Component({
  selector: 'spb-test',
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent {

  public costs: ICost[] | null = null

  protected obligations = new Obligations()

  protected children = new Children()

  protected employment = new Employment()

  protected bankAssets = new BankAssets()

  protected valuation = new Valuation()

  protected income = new Income()
  private costItems: number[] = []

  public setCost(i: any, index: number): void {
    this.costItems[index] = i
  }

  public setUpdated(): void {
    console.log('set updated')
    this.costItems.push(this.costItems.length)
  }

  public setIt(): void {
    this.costs = [{type: 'STUDENT_LOAN'} as any]
    this.employment = new Employment()
  }

  public setValue(): void {
    this.costs = [{type: 'STUDENT_LOAN', cost: 200, selected: true}]
    const f = {
      household: [
        {
          householdNumber: 1,
          householdId: '13D9EDC2ABE86C5BE0630100007F8E8A',
          child: [
            {
              age: 1,
              custody: '50',
              incomeMaintenanceSupport: true,
              costMaintenanceSupport: false
            },
            {
              age: 1,
              custody: '50',
              incomeMaintenanceSupport: true,
              costMaintenanceSupport: false
            }
          ]
        }]
    }

    this.children = new Children(f as any)
    this.employment = new Employment()
    const application = getBaseApplication()

    application.applicants[0].obligations = {
      guaranteeCommitmentAmount: 0,
      taxDefermentAmount: 150000,
      loan: [
        {
          type: 'UNSECURED',
          creditor: 'SEB',
          amount: 150000,
          interestRate: '1.2',
          monthlyAmortization: '500',
          loanNumber: '',
          resolve: false
        }
      ],
      credit: [
        {
          type: 'CARD',
          creditor: 'SEB',
          amount: 10000,
          interestRate: '9'
        }
      ]
    }
    application.applicants[0].bankAsset = [
      {
        type: 'BANK_ACCOUNT',
        amount: 1000,
        bankName: 'Banken'
      }
    ]
    application.applicants[0].monthlyIncome = [
      {
        type: 'EMPLOYMENT',
        amount: 2000
      }
    ]

    this.bankAssets = new BankAssets(application)
    this.obligations = new Obligations(application)
    this.income = new Income(application)
    this.valuation = new Valuation(application)
  }

  public setNoValue(): void {
    this.costs = [{type: 'STUDENT_LOAN', cost: null, selected: false}]
    this.obligations = new Obligations()
    this.children = new Children()
    this.bankAssets = new BankAssets()
    this.income = new Income()
  }
}
