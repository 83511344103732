<form>
  @if (availableCollaterals.length > 0) {
    @if (availableCollaterals.length > 1) {
      <div class="spb-form-lead-text">Välj det objekt som du vill låna mer på</div>
      <mat-form-field>
        <mat-label>Belåningsobjekt</mat-label>
        <mat-select [formControl]="ctrl" placeholder="Välj i listan...">
          <mat-option disabled>Välj i listan...</mat-option>
          @for (collateral of availableCollaterals; track collateral.id) {
            <mat-option [value]="collateral">{{ collateral.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    } @else if (selectedCollateral) {
      <div class="spb-form-lead-text">Belåningsobjekt: {{ selectedCollateral.name }}</div>
    }
    @if (selectedCollateral) {
      <mat-form-field>
        <mat-label>Adress</mat-label>
        <input matInput [formControl]="addressCtrl">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nuvarande värdering</mat-label>
        <input [formControl]="currentValuationCtrl" matInput spbFormatNumber>
        <span matTextSuffix>kr</span>
      </mat-form-field>
    }
  } @else if (noData) {
    Vi hittar ingen säkerhet att låna på.
  } @else {
    <spb-progress-ng [errored]="error"></spb-progress-ng>
  }
</form>