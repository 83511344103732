<div class="footer">
  @if (edit()) {
    <!-- The "edit" just tells the parent if we are in edit mode or not -->
    <button mat-flat-button
            [disabled]="!formValid()"
            color="primary"
            (click)="edit.set(false)">Spara
    </button>
    <button mat-flat-button (click)="changes().reset(); edit.set(false)">Avbryt</button>
  } @else if(canEdit()) {
    <button mat-flat-button (click)="edit.set(true)">Ändra</button>
  }


  @if (changes().changes$()) {
    <button mat-flat-button (click)="changes().reset()">Ångra</button>
  }
  <div [style.flex-grow]="1"></div>
  @if (formValid()) {
    <mat-icon color="accent">done</mat-icon>
  } @else {
    <mat-icon color="warn">close</mat-icon>
  }
</div>