<div class="main">
  @if (employment) {
    <spb-employment [initial]="employment"
                (resultValue)="setUpdated()"
    ></spb-employment>
  } @else {
    Do something
  }

  <div class="buttons">
    <button (click)="setIt()">Set it</button>
    <button (click)="setValue()">Set value</button>
    <button (click)="setNoValue()">Set NO value</button>
    <button (click)="costs = null">Reset it</button>
  </div>
</div>