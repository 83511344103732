import {Component, OnChanges} from '@angular/core'
import {FormArray, FormControl, FormGroup} from '@angular/forms'
import {IBorgoBankAsset} from 'sparbanken-syd-borgo'
import {SelectorBaseComponent} from '../selector-base.component'
import {ListHandler} from '../list-handler'
import {BankAssets} from '../../model/asset/bank-assets'

@Component({
  selector: 'spb-bank-assets',
  templateUrl: './bank-assets.component.html'
})
export class BankAssetsComponent extends SelectorBaseComponent<BankAssets> implements OnChanges {
  protected override form = new FormGroup({
      assets: new FormArray<FormControl<IBorgoBankAsset | null>>([])
    }
  )
  public assetHandler = new ListHandler<IBorgoBankAsset>(this.form.controls.assets)

  public override ngOnChanges() {
    this.assetHandler.clear()
    if (this.initial.selected) {
      // This means that we have read the value and can set
      // all the following
      this.assetHandler.setItems(this.initial.assets)
      this.resultValue.emit(this.initial as BankAssets)
    }
  }
}
