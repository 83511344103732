import {Base} from '../base/base'
import {IBorgoApplication, IBorgoCivilStatus, TBorgoCivilStatus} from 'sparbanken-syd-borgo'
import {verify} from '@sparbanken-syd/personnummer'

export const shouldHaveMaritalStatusName = (status: TBorgoCivilStatus | null): boolean => {
  return status === 'COHABITATION'
}

export interface IMaritalStatus {
  /**
   * The base status, married etc.
   */
  status: TBorgoCivilStatus | null
  /**
   * Sub, personnummer, is required on "COHABITATION", id est "Sambo
   */
  partnerSub: string
  /**
   * See above, required if "Sambo"
   */
  partnerName: string
}

export class MaritalStatus extends Base implements IMaritalStatus {

  public status: 'SINGLE' | 'MARRIED' | 'REGISTERED_PARTNERSHIP' | 'COHABITATION' | null = null
  public partnerSub: string = ''
  public partnerName: string = ''

  constructor(application: Partial<IBorgoApplication> = {}, private index: number = 0) {
    super(application)
    this.setFrom(application)
  }

  private setFrom(application: Partial<IBorgoApplication>) {
    this.getApplicantPropertyOrNot<IBorgoCivilStatus>(
      application, this.index, 'civilStatus')
      .forEach(c => {
        this.status = c.type
        this.partnerName = c.partnerName ?? ''
        this.partnerSub = c.partnerNationalId ?? ''
      })
  }

  public override toApplication(application: Partial<IBorgoApplication>): void {
    if (application.applicants && application.applicants[this.index]) {
      application.applicants[this.index].civilStatus = {
        type: this.status as TBorgoCivilStatus
      }
      if (shouldHaveMaritalStatusName(this.status)) {
        application.applicants[this.index].civilStatus.partnerName = this.partnerName
        try {
          application.applicants[this.index].civilStatus.partnerNationalId = verify(this.partnerSub)[12]
        } catch (e) {
          // We simply ignore this
        }
      }
    }
  }
}
