import {Injectable} from '@angular/core'
import {ReplaySubject} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class KycService {

  /**
   *
   */
  public allGood$ = new ReplaySubject<boolean>(1)
  /**
   * Thank god I know we have six items. .... I hate myself!
   * Really do!
   */
  private valid = [false, false, false, false, false, false]

  constructor() {
  }

  public setValid(index: number, valid: boolean): void {
    this.valid[index - 1] = valid
    this.allGood$.next(this.valid.reduce((acc, cur) => acc && cur, true))
  }
}
