<div class="holder">
  <div>
    <h4>{{ documentMap.get(document.supplementaryType)?.name }}</h4>
    @if (applicants[document.applicantId || '']) {
      <div class="applicant-name">{{ applicants[document.applicantId || ''] }}</div>
    }
  </div>
  <div>
    @if (document.status === 'MISSING') {
      @if (document.supplementaryType === 'SALARY_SPEC_BASIS') {
        <p>Du behöver komplettera med dina tre senaste lönespecifikationer. En rådgivare kommer att kontakta dig via ett
          meddelande i internetbanken och du laddar upp dokumenten direkt i internetbanken</p>
      }
      @if (!hideUpload) {
        <p>Välj ett dokument att ladda upp. Det måste vara ett Microsoft Word&trade; eller ett PDF eller en
          bild i formatet JPG eller PNG. Filen får vara max 1 MB stor.</p>
      }
    } @else {
      <p class="doc-name"> {{ documentMap.get(document.supplementaryType)?.name }} har laddats upp:
        {{ document.fileName }}</p>
    }
  </div>

  @if (error) {
    <div>
      Det går inte att ladda upp detta dokumentet.
    </div>
  }
  <!-- File name is '' when not uploaded -->
  @if (!hideUpload) {
    <div class="buttons">
      @if (document.fileName) {
        <div>
          <button mat-raised-button (click)="getDocument(document.documentId)">Visa</button>
        </div>
      }
      <spb-file-upload
        (files)="sendDocument($event)"
        [color]="document.fileSize ? '' : 'primary'"
        [text]=" document.fileSize ? 'Välj annan' : 'Bifoga komplettering'"
      ></spb-file-upload>
    </div>
  }
</div>