<form [formGroup]="form">
  <spb-selector
    [ctrl]="form.controls.type"
    [items]="types"
    label="Typ av kredit"
  ></spb-selector>
  <spb-creditor
    (resultValue)="setCreditor($event)"
    [initial]="initialCreditor">
  </spb-creditor>
  <mat-form-field>
    <mat-label>Skuld</mat-label>
    <input formControlName="amount" matInput spbFormatNumber>
    <span matTextSuffix>kr</span>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Amortering</mat-label>
    <input formControlName="monthlyAmortization" matInput spbFormatNumber>
    <span [style.white-space]="'nowrap'" matTextSuffix>kr / mån</span>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Ränta</mat-label>
    <input class="spb-no-flippers" formControlName="interestRate" matInput type="number">
    <span matTextSuffix>%</span>
  </mat-form-field>
</form>
