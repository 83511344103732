<spb-yes-no-radio
  [externalControl]="have"
  [leadText]="yesNoLabel"
></spb-yes-no-radio>
@if (have.value && !reverse || have.value === false && reverse) {
  @if (inputInfoText) {
    <div class="spb-form-info-text">{{ inputInfoText }}</div>
  }
  <mat-form-field>
    <mat-label>{{ inputLabel }}</mat-label>
    @if (numberField) {
      <input matInput [formControl]="ctrl" spbFormatNumber>
    } @else {
      <input matInput [formControl]="ctrl">
    }
    <span *ngIf="inputSuffix" matTextSuffix [style.white-space]="'nowrap'">{{ inputSuffix }}</span>
  </mat-form-field>
}