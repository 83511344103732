<form [formGroup]="form">


  <spb-yes-no-input
    (resultAnswer)="form.controls.selected.setValue($event)"
    (resultValue)="form.controls.new.setValue($event)"
    [ctrl]="form.controls.new"
    [initialState]="form.controls.selected.value"
    [numberField]="true"
    inputLabel="Nytt marknadsvärde"
    inputSuffix="kr"
    yesNoLabel="Har du en aktuell värdering tex från en mäklare på din bostad?"
  ></spb-yes-no-input>
</form>
