import {Pipe, PipeTransform} from '@angular/core'
import {CountryMap} from '../model/countries'

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  transform(code: string): string {
    return CountryMap.get(code) as string
  }
}
