@if (application) {
  <h3>Du behöver komplettera din ansökan</h3>
  <p>Du behöver komplettera med dokument som styrker uppgifter i din ansökan, läs mer vilka dokument som krävs för just
    din ansökan och hur du går tillväga nedan.
  </p>
  <p>
    Efter att du har kompletterat med efterfrågade dokument kommer en rådgivare att granska din ansökan. Vi kontaktar
    dig därefter via sms och ett meddelande i internetbanken.
  </p>
  @for (document of documents; track $index) {
    <div [style.margin-bottom.px]="20">
      <spb-document
        [document]="document"
        [application]="application"
        (completed)="documentReady($event, $index)"
      ></spb-document>
    </div>
  }
}