<div class="table">
  <div class="header">
    <div>Räntebindning</div>
    <div [style.margin-left.px]="10">Din/er erbjudna ränta per {{ today | date: 'yyyy-MM-dd' }}</div>
  </div>
  @for (interest of interests(); track $index) {
    @if ($index < 6) {
      <div class="row">
        <div>{{ loanBindingMap.get(interest.rateFixationPeriod)?.name }}</div>
        <div>{{ interest.interestRate | number: '1.2-2': 'fr' }} %</div>
      </div>
    }
  }
</div>
