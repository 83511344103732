import {Base} from '../base/base'
import {Child} from './child'
import {IBorgoApplication, IBorgoChild} from 'sparbanken-syd-borgo'

export class Children extends Base {
  /**
   * A list of zero children.
   */
  children: Child[] | null = null

  constructor(application: Partial<IBorgoApplication> = {}, private index: number = 0) {
    super(application)
    this.setFrom(application)
  }

  public getBorgo(): IBorgoChild[] {
    return (this.children || [])
      .map(c => Object.assign(new Child(), c))
      .map(c => c.toBorgo())
      .filter((c): c is IBorgoChild => c !== null)
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    if (application.household && application.household[this.index]) {
      this.children = application.household[this.index].child
        .map(c => new Child(c))
    }
  }
}
