import {KycItem} from './kyc-item'
import {IBorgoApplication, IBorgoEmail, IKycContactDataset, IKycPersonDataset, IKycPhone} from 'sparbanken-syd-borgo'
import {convertPhoneNumber, convertToKycPhone} from '../../../model/contact/contact'

export interface IKycContact {
  phone?: string | null
  email?: string | null
}

export class KycItemContact extends KycItem<IKycContact> {

  public pContact: IKycContact

  private readonly phone: string | null
  private readonly email: string | null


  constructor(input: Partial<IKycPersonDataset>, application: Pick<IBorgoApplication, 'applicants'>) {
    super(input)
    this.phone = this.getPhoneNumber()
    this.email = this.getEmail()
    this.pContact = {
      phone: this.phone,
      email: this.email
    }

    if (application.applicants && application.applicants[0]) {
      this.pContact.email = application.applicants[0].email || this.pContact.email
      if (application.applicants[0].mobilePhoneNumber) {
        // Any here due to phone mayhem.
        this.pContact.phone = this.mobileToString(application.applicants[0].mobilePhoneNumber as any)
      }
      this.setValue(this.pContact)
    }
    this.isValid()
  }

  get contact(): IKycContact {
    return {
      phone: this.pContact.phone,
      email: this.pContact.email
    }
  }


  public setValue(input: Partial<IKycContact>): void {
    this.setPhone(input.phone!)
    this.setEmail(input.email!)
    this.changes$.set(this.differs())
    this.isValid()
  }

  private isValid(): void {
    const phone = this.getPhoneNumber()
    this.isValid$.next(!!this.getEmail() && phone !== null && phone.length > 9)
  }

  private differs(): boolean {
    return this.phone !== this.getPhoneNumber() ||
      this.email !== this.getEmail()
  }

  public override reset() {
    this.setPhone(this.pContact.phone)
    this.setEmail(this.pContact.email)
    this.value$.next({
      phone: this.pContact.phone,
      email: this.pContact.email
    })
    this.changes$.set(this.differs())
  }

  private setPhone(phone: string | null | undefined): void {
    const phones = this.getPhoneArray()
    if (phones.length > 0) {
      phones.shift()
    }
    if (phone) {
      phones.unshift(convertToKycPhone(convertPhoneNumber(phone)))
    }
  }

  private setEmail(email?: string | null): void {
    const emails = this.getEmailArray()
    if (emails.length > 0) {
      emails.shift()
    }
    if (email) {
      emails.unshift({
        address: email,
        type: 'PRIVATE'
      })
    }
  }

  /**
   * Returns the relevant phone number from
   * the existing KYC data.
   * @private
   */
  private getPhoneNumber(): string | null {
    const phones = this.getPhoneArray()
    if (phones.length > 0) {
      return this.mobileToString(this.input!.contactDataset!.phones[0])
    }
    return null
  }

  private getEmail(): string | null {
    const emails = this.getEmailArray()
    if (emails.length > 0) {
      return emails[0].address
    }
    return null
  }

  /**
   * Get the exising phones
   */
  private getPhoneArray(): IKycPhone[] {
    const contact = this.getContactDataSet()
    if (!contact.phones) {
      contact.phones = []
    }
    return contact.phones
  }

  private getEmailArray(): IBorgoEmail[] {
    const contact = this.getContactDataSet()
    if (!contact.emails) {
      contact.emails = []
    }
    return contact.emails
  }

  private getContactDataSet(): IKycContactDataset {
    if (!this.input.contactDataset) {
      this.input.contactDataset = {phones: [], emails: []}
    }
    return this.input.contactDataset
  }

  private mobileToString(phone: IKycPhone): string {
    return `0${phone.area}-${phone.number}`
  }
}
