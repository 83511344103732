<spb-applicant-group
  [applicationToSend]="applicationToSend"
  [index]="0"
  [input]="input"
  (valid)="form.controls.applicants.at(0).setValue($event)"
  [initial]="{}"
></spb-applicant-group>

<spb-yes-no-radio
  [externalControl]="applicantCtrl"
  leadText="Är ni flera sökande?"
>
</spb-yes-no-radio>
@if (applicantCtrl.value) {
  <spb-applicant-group
    [applicationToSend]="applicationToSend"
    [input]="input"
    [index]="1"
    [initial]="{}"
    (valid)="form.controls.applicants.at(1).setValue($event)"
  ></spb-applicant-group>
}