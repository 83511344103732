<form [formGroup]="form">
  <mat-form-field>
    <mat-label>Skatterättslig hemvist</mat-label>
    <mat-select formControlName="country" ngDefaultControl>
      <mat-option value="SE">Sverige</mat-option>
      <mat-option disabled></mat-option>
      @for (country of CountryMap | keyvalue:sortOriginal; track $index) {
        <mat-option [value]="country.key">{{ country.value }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  @if (form.get('country')!.value && form.get('country')!.value !== 'SE') {
    <mat-form-field>
      <mat-label>T.I.N</mat-label>
      <input matInput formControlName="tinCode">
    </mat-form-field>
  }
</form>