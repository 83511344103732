<form [formGroup]="form">
  <spb-selector
    [ctrl]="form.controls.age"
    [items]="childrenAges"
    label="Ålder"
  ></spb-selector>
  <spb-yes-no-radio
    [externalControl]="form.controls.shared"
    leadText="{{'Har ' + ('du/ni' | plr | async ) + ' delad vårdnad om barnet?'}}"
  ></spb-yes-no-radio>
  @if (form.controls.shared.value !== null) {
    <spb-selector
      [ctrl]="form.controls.custody"
      [items]="childrenCustody"
      label="{{('Din/Er' | plr | async) + ' del av vårdnaden' }}"
    ></spb-selector>
    <spb-yes-no-radio
      [externalControl]="form.controls.income"
      leadText="{{'Får ' + ('du/ni' | plr | async) + ' underhållsbidrag för barnet?'}}"
    ></spb-yes-no-radio>
    <spb-yes-no-radio
      [externalControl]="form.controls.cost"
      leadText="{{'Betalar ' + ('du/ni' | plr | async) + ' underhållsbidrag för barnet?'}}"
    ></spb-yes-no-radio>
  }
</form>