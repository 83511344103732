import {Component, Input} from '@angular/core'
import {IBorgoApplicationLoanObject} from 'sparbanken-syd-borgo'

@Component({
  selector: 'spb-existing-loans',
  templateUrl: './existing-loans.component.html'
})
export class ExistingLoansComponent {

  @Input() addressToFilter: string = ''

  @Input() loans: IBorgoApplicationLoanObject = {} as any
}
