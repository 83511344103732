import {Component, OnInit} from '@angular/core'
import {SelectorBaseComponent} from '../../selector-base.component'
import {IBorgoObligationLoan, TBorgoCreditor, TBorgoObligationLoanType} from 'sparbanken-syd-borgo'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {BorgoObligationLoanTypeMap} from '@sparbanken-syd/borgo-helpers'

import {Creditor, ICreditor} from '../../../model/creditor/creditor'
import {startWith} from 'rxjs/operators'


@Component({
  selector: 'spb-additional-loan',
  templateUrl: './additional-obligation-loan.component.html',
  styleUrls: ['../../selectors.scss']
})
export class AdditionalObligationLoanComponent extends SelectorBaseComponent<IBorgoObligationLoan> implements OnInit {

  protected initialCreditor: Creditor = new Creditor()

  protected types = BorgoObligationLoanTypeMap


  protected override form = AdditionalObligationLoanComponent.generateForm()

  public static generateForm() {
    return new FormGroup({
      type: new FormControl<TBorgoObligationLoanType | null>(null, {
        nonNullable: true,
        validators: [Validators.required]
      }),

      monthlyAmortization: new FormControl<string | null>(null),

      resolve: new FormControl<boolean>(false),
      loanNumber: new FormControl<string | null>(null, {
        validators: []
      }),

      // From base
      creditor: new FormControl<TBorgoCreditor | string | undefined | null>(null),
      amount: new FormControl<number | null>(null,
        {validators: [Validators.required, Validators.min(1)]}),
      interestRate: new FormControl<string | null>(null)
    })
  }

  public override ngOnInit() {
    super.ngOnInit()
    if (this.form.controls.creditor.value) {
      this.initialCreditor = Creditor.fromCredit({creditor: this.form.controls.creditor.value})
    }

    /**
     * Loan number becomes mandatory when resolve is true
     */
    this.form.controls.resolve.valueChanges
      .pipe(startWith(false))
      .subscribe({
        next: v => {
          this.setValidatorsIfRequired(v, this.form.controls.loanNumber, [Validators.required])
        }
      })
  }

  public setCreditor(value: ICreditor): void {
    this.form.controls.creditor.setValue(value.otherCreditorName ?? value.creditor)
  }
}
