<mat-card [class]="{ error: !formValid$() && !edit()}">
  <mat-card-header>
    <div class="kyc-header">
      <div>Inkomst</div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (edit()) {
      <mat-form-field>
        <mat-label>Inkomst</mat-label>
        <input matInput [formControl]="form" spbFormatNumber>
        <span matTextSuffix [style.white-space]="'nowrap'">kr / mån</span>
      </mat-form-field>
    } @else {
      <div>Inkomst: {{ form.value | number :'1.0-0': 'fr' }} kr / mån</div>
    }
  </mat-card-content>

  <mat-card-actions>
    <spb-kyc-footer
      [canEdit]="!primary()"
      [(edit)]="edit"
      [changes]="income"
      [formValid]="formValid$()"></spb-kyc-footer>
  </mat-card-actions>
</mat-card>