import {Component} from '@angular/core'
import {SelectorBaseComponent} from '../selector-base.component'
import {KycPurpose} from '../../model/kyc-purpose/kyc-purpose'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {TKycPurpose} from 'sparbanken-syd-borgo'
import {PurposeTypeMap} from '@sparbanken-syd/borgo-helpers'

@Component({
  selector: 'spb-kyc-purpose',
  templateUrl: './kyc-purpose.component.html',
  styleUrl: '../selectors.scss'
})
export class KycPurposeComponent extends SelectorBaseComponent<KycPurpose> {

  public purposes = PurposeTypeMap

  public override form = new FormGroup({
    purpose: new FormControl<TKycPurpose | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    purposeDescription: new FormControl<string>('')
  })

  constructor() {
    super()
    this.form.controls.purpose.valueChanges.subscribe({
      next: v => {
        this.setValidatorsIfRequired(
          v === '4', this.form.controls.purposeDescription, [Validators.required])
      }
    })
  }
}
