import {
  IBorgoAccommodationCredit,
  IBorgoApplication,
  IBorgoCollateral,
  IBorgoLoanAccommodation,
  IBorgoLoanObject,
  IBorgoUserLoan,
  TApplicationLoanBinding,
  TBorgoAccommodationType,
  TBorgoCreditor,
  TBorgoLoanObjectType,
  TCollateralType
} from 'sparbanken-syd-borgo'
import {ILoan} from '../../selectors/loan/loan.component'
import {Base} from '../base/base'
import {search} from 'jmespath'

export const ACCOMMODATION_PATH = 'household[0].accommodation'

const OperatingCostMap = new Map<TBorgoAccommodationType, number>([
  ['HOUSE', 6000],
  ['HOLIDAY_HOUSE', 4500],
  ['APARTMENT', 2500],
  ['RENTED_APARTMENT', 500]
])

export const CollateralTypeToApplicationTypeMap =
  new Map<TCollateralType, TBorgoLoanObjectType>([
    ['113S', 'HOUSE'],
    ['224', 'APARTMENT'],
    ['116S', 'HOLIDAY_HOUSE']
  ])


const convertLoanToApplicationLoan = (loan: ILoan): IBorgoAccommodationCredit => {
  const result: IBorgoAccommodationCredit = {
    amount: loan.debt,
    creditor: loan.creditor as TBorgoCreditor,
    // This is totally mandatory?
    loanNumber: loan.loanNumber,
    monthlyAmortization: loan.amortization,
    rateFixationPeriod: loan.binding
  }
  if (loan.creditor === 'OTHER_CREDITOR') {
    result.otherCreditorName = loan.otherCreditorName
  }
  return result
}

export class Accommodation extends Base {
  /**
   * Totally mandatory
   */
  public type: TBorgoAccommodationType | null = null
  /**
   * Fee is when bostadsrätt
   */
  public fee: number = 0

  /**
   * Rent for hyresrätt
   */
  public rent: number = 0
  /**
   * If this is an "apartment" then this is required, otherwise
   * we set it ti null or undefined, let us see.
   */
  public area: number = 0
  /**
   * Additional run cost as set by the user.
   */
  public runCost: number = 0


  public cooperativeTaxNumber: string = ''
  /**
   * For the primary object we use the Borgo loans,
   * for any other object these have to be entered.
   *
   */
  public loans: ILoan[] = []

  /**
   * Loans already on the collateral?
   */
  public existingLoans: IBorgoAccommodationCredit[] = []
  /**
   * If this is the primary building, that is the
   * one that we are taking loans on.
   */
  public primary: boolean = false


  constructor(application: Partial<IBorgoApplication> = {}, public index: number = 0) {
    super(application)
    this.setFrom(application)
  }

  /**
   * Return accommodation for the application.
   */
  public toBorgo(): IBorgoLoanAccommodation {
    const newLoansCreditAmount = this.loans.map(l => l.debt)
      .reduce((sum, debt) => sum + debt, 0)

    const existingLoansCreditAmount = this.existingLoans.map(el => el.amount)
      .reduce((sum: number, curr: number) => sum + curr, 0)

    const accommodation: IBorgoLoanAccommodation = {
      creditAmount: newLoansCreditAmount + existingLoansCreditAmount,
      currentAccommodation: true,
      groundRentAmount: 0,
      loanObject: this.index === 0,
      operatingCost: OperatingCostMap.get(this.type!) as number,
      sellOrTerminate: false,
      type: this.type!
    }

    if (this.type === 'APARTMENT') {
      accommodation.monthlyFee = this.fee
    }

    if (this.type === 'RENTED_APARTMENT') {
      accommodation.monthlyFee = this.rent
    }

    accommodation.credits =
      this.loans.map(convertLoanToApplicationLoan).concat(this.existingLoans)
    return accommodation
  }

  /**
   * Sets all possible values from a collataral
   * @param collateral
   * @param application
   */
  public setLoanObjectFromCollateral(collateral: IBorgoCollateral, application: Pick<IBorgoApplication, 'loanObject'>): void {
    /**
     * Collaterals are stuff we get from borgo, a list of items
     * that the "user" has, houses and stuff that they have loans on.
     */
    const type = CollateralTypeToApplicationTypeMap
      .get(collateral.collateralObjectType.type) as TBorgoLoanObjectType
    this.type = type
    application.loanObject = application.loanObject || {
      type,
      street: '',
      postalCode: '',
      externalReferences: [{source: 'UC'}]
    } as IBorgoLoanObject
    application.loanObject.type = type
    application.loanObject.street = collateral.collateralObjectAddress.street1
    application.loanObject.postalCode = collateral.collateralObjectAddress.postalCode
    application.loanObject.externalReferences[0].id = collateral.id

    if (type === 'APARTMENT') {
      delete application.loanObject.house
      this.cooperativeTaxNumber = collateral.name.split(',')[0]
      application.loanObject.apartment = {
        taxApartmentNumber: collateral.collateralObjectAddress.apartmentNumber,
        cooperativeApartmentNumber: this.cooperativeTaxNumber,
        monthlyFee: this.fee,
        area: this.area

      }
    } else {
      delete application.loanObject.apartment
      // We either have a house or we do not. If we do not we set it
      // as a very minimum one.
      application.loanObject.house = application.loanObject.house || {
        fullValueInsurance: false
      }
    }
  }

  /**
   * Writes down this to the application loan object
   */
  public override toApplication(application: Partial<IBorgoApplication>) {
    /**
     * Actually if this is 'primary'?
     * This cannot be done if we have not already set this from a collateral
     * and the collateral has created the loanObject.
     */
    if (this.type === 'APARTMENT' && this.index === 0 && application.loanObject) {
      // If apartment then fee and area must be set.
      application.loanObject.apartment!.monthlyFee = this.fee
      application.loanObject.apartment!.area = this.area
      application.loanObject.operatingCost = this.runCost
      application.loanObject.apartment!.cooperativeApartmentNumber = this.cooperativeTaxNumber
    }
    if (application.household && application.household[0]) {
      const target = application.household[0].accommodation[this.index]
      if (target) {
        target.type = this.type!
        target.operatingCost = this.runCost
        target.monthlyFee = this.fee
      } else {
        application.household[0].accommodation.push({
          creditAmount: 0,
          currentAccommodation: false,
          groundRentAmount: 0,
          loanObject: false,
          monthlyFee: this.fee,
          operatingCost: this.runCost,
          sellOrTerminate: false,
          type: this.type!
        })
      }

      if (this.index !== 0) {
        const loan = this.getAccommodation(this.index, application)
        if (loan) {
          loan.credits = this.loans.map(convertLoanToApplicationLoan)
          loan.creditAmount = this.loans.reduce((acc, cur) => acc + cur.debt, 0)
        }
      }
    }
  }

  /**
   * We fetch loans from the user. The user have a bunch of loans.
   *
   * The selected collateral also have a bunch of lonas.
   *
   * The loans match on "id" so we take in the ids of the loans
   * on the collateral and convert all user loans to collateral
   * loans and set this on this "accommmodation"
   * @param loans
   */
  public setCreditsFromUserLoans(loans: IBorgoUserLoan[]): void {
    this.existingLoans = loans.map(ul => {
      const credit: IBorgoAccommodationCredit = {
        amount: ul.bookedBalance * -1,
        // Existing loans are always Borgo.
        creditor: 'BORGO',
        loanNumber: ul.bban,
        monthlyAmortization: 0,
        rateFixationPeriod:
          Number.parseInt(ul.referenceInterest.id.replace(/\D/g, '')) + '' as TApplicationLoanBinding
      }
      return credit
    })
  }

  public getAccommodation(index: number, application: Partial<IBorgoApplication>): IBorgoLoanAccommodation | undefined {
    return this.getAccommodationsIfAny(application)[index]
  }

  public getAccommodationsIfAny(application: Partial<IBorgoApplication>): IBorgoLoanAccommodation[] {
    const accommodations = search(application, ACCOMMODATION_PATH)
    if (!accommodations) {
      return []
    }
    return accommodations
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    if (application.loanObject?.apartment && this.index === 0) {
      this.type = 'APARTMENT'
      this.fee = application.loanObject.apartment.monthlyFee
      this.area = application.loanObject.apartment.area
      this.cooperativeTaxNumber = application.loanObject.apartment.cooperativeApartmentNumber || ''
    }
    if (application.loanObject?.house && this.index === 0) {
      this.type = 'HOUSE'
    }
    if (application.household && application.household[0]) {
      if (application.household[0].accommodation[this.index]) {
        const source = application.household[0].accommodation[this.index]
        this.type = source.type
        this.runCost = source.operatingCost

        if (source.type === 'APARTMENT') {
          this.fee = source.monthlyFee ?? 0
        }

        if (this.index > 0 && source.credits) {
          this.loans = source.credits.map(c => {
            const loan: ILoan = {
              amortization: c.monthlyAmortization,
              binding: c.rateFixationPeriod,
              debt: c.amount,
              loanNumber: c.loanNumber
            }
            return loan
          })
        }
      }
    }
  }
}