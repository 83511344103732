import {inject, Pipe, PipeTransform} from '@angular/core'
import {ApplicationService} from '../../services/application.service'
import {map, Observable} from 'rxjs'

@Pipe({
  name: 'plr',
  standalone: true,
  pure: false
})
export class PlrPipe implements PipeTransform {

  private service = inject(ApplicationService)

  transform(value: string): Observable<string> {
    const splutt = value.split('/')
    return this.service.coApplicant$.pipe(
      map(v => v ? splutt[1] : splutt[0])
    )
  }

}
