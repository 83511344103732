import {Component, effect, input, output} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {filter, switchMap} from 'rxjs'
import {UserService} from '../../../services/user.service'
import {ApplicationService} from '../../../services/application.service'
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio'

@Component({
  selector: 'spb-select-advice',
  standalone: true,
  imports: [
    MatRadioGroup,
    MatRadioButton,
    ReactiveFormsModule
  ],
  templateUrl: './select-advice.component.html'
})
export class SelectAdviceComponent {

  public selected = input.required<boolean | null>()

  public selection = output<boolean>()

  public ctrl = new FormControl<boolean | null>(null)

  constructor(
    private userService: UserService,
    private applicationService: ApplicationService
  ) {
    effect(() => {
      if (this.selected() !== null) {
        this.ctrl.setValue(this.selected(), {emitEvent: false})
        this.ctrl.disable({emitEvent: false})
      }

    })
    this.ctrl.valueChanges
      .pipe(
        filter((v): v is boolean => v !== null),
        switchMap((v) => {
          this.selection.emit(v)
          return this.userService.setSavedData(this.applicationService.applicationId$(), {declinesAdvice: v})
        })
      )
      .subscribe()
  }
}
