<div>
  <p [style.font-size.%]="110">
    <b>Information inför rådgivning om bostadskrediter</b>
  </p>

  <p><b>Tillhandahållande av rådgivning</b></p>
  <p>Sparbanken Syd erbjuder rådgivning om bostadskrediter. Detta innebär bl a att vi
    ger personliga rekommendationer till dig gällande bostadskreditavtal utifrån dina ekonomiska och personliga
    förhållanden samt önskemål och särskilda krav.</p>

  <p><b>Information om rådgivningen</b></p>
  <p>Sparbanken Syd tillhandahåller denna rådgivning utifrån det produktsortiment av
    bostadskrediter som erbjuds av Borgo AB (publ) och av Sparbanken Syd.</p>

  <p>Det utgår inte någon avgift för rådgivningen.</p>

  <p>Rådgivningen är inte oberoende då rådgivning tillhandahålls avseende Sparbanken Syds egna krediter och krediter som
    erbjuds av Borgo AB (publ) som Sparbanken Syd har avtal med.</p>

  <p>
    <b>Dokumentation av rådgivning</b></p>
  <p> Om du erhållit rådgivning kommer vi att dokumentera vilka råd som har getts och vad som
    i övrigt förekommit vid rådgivningstillfället. Vid rådgivningen går vi bl a igenom olika ränte-bindningstider, om
    lånet bör delas upp på flera lån och vilka amorteringsalternativ det finns.</p>
  <mat-radio-group [formControl]="ctrl" color="primary">
    <mat-radio-button [value]="false">Jag vill ha bolånerådgivning</mat-radio-button>
    <mat-radio-button [value]="true">Jag avböjer bolånerådgivning</mat-radio-button>
  </mat-radio-group>
</div>