import {Component, Input, OnChanges} from '@angular/core'
import {SelectorBaseComponent} from '../selector-base.component'
import {LoanAmount} from '../../model/loan-amount/loan-amount'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {IBorgoUserLoan} from 'sparbanken-syd-borgo'
import {filter} from 'rxjs'

@Component({
  selector: 'spb-loan-amount',
  templateUrl: './loan-amount.component.html',
  styleUrl: '../selectors.scss'
})
export class LoanAmountComponent extends SelectorBaseComponent<LoanAmount> implements OnChanges {

  @Input({required: true}) collateralLoans: IBorgoUserLoan[] = []

  public override form = new FormGroup({
    amount: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.max(15 * 1000 * 1000), Validators.min(200 * 1000)]
    }),
    totalLoanAmount: new FormControl<number>({disabled: true, value: 0})
  })

  /**
   * The
   * @private
   */
  private existingLoanAmount: number = 0

  constructor() {
    super()
    this.form.controls.amount.valueChanges.pipe(
      filter(Boolean)
    ).subscribe({
      next: (val: number) => {
        this.form.controls.totalLoanAmount.setValue(this.existingLoanAmount + val)
      }
    })
  }

  public override ngOnChanges() {
    super.ngOnChanges()
    this.existingLoanAmount = this.collateralLoans
      .reduce((acc: number, l) => acc + l.bookedBalance, 0) * -1
    this.form.controls.totalLoanAmount.setValue(this.existingLoanAmount + (this.form.controls.amount.value ?? 0))
    this.form.controls.amount.setValidators([
      Validators.required,
      Validators.max(15 * 1000 * 1000 - this.existingLoanAmount),
      Validators.min(200 * 1000)
    ])
    this.form.updateValueAndValidity({emitEvent: false})
  }
}
