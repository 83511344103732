import {Pipe, PipeTransform} from '@angular/core'

/**
 * Apparently stupid but hey
 */

const valueMap = new Map<number, string>([
  [1, 'första'],
  [2, 'andra'],
  [3, 'tredje'],
  [4, 'fjärde'],
  [5, 'femte'],
  [6, 'sjätte'],
  [8, 'sjunde'],
  [9, 'nionde']
])

@Pipe({
  name: 'enumerate'
})
export class EnumeratePipe implements PipeTransform {

  transform(value: number): string {
    return valueMap.get(value) as string
  }

}
