import {Component, Input, OnInit} from '@angular/core'
import {BorgoAccommodationTypeMap} from '@sparbanken-syd/borgo-helpers'
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms'
import {ILoan, LoanComponent} from '../loan/loan.component'
import {SelectorBaseComponent} from '../selector-base.component'
import {TBorgoAccommodationType} from 'sparbanken-syd-borgo'
import {Accommodation} from '../../model/accommodation/accommodation'
import {ListHandler} from '../list-handler'
import {filter} from 'rxjs'

const requiredValidatorsApartment = [Validators.required]

@Component({
  selector: 'spb-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['../selectors.scss']
})
export class AccommodationComponent extends SelectorBaseComponent<Accommodation> implements OnInit {
  /**
   * This is for the loan object, if so loans are set
   * automatically.
   */
  @Input() primary: boolean = true

  /**
   * These are loans that we have or emit eventually. See later for available loans.
   */
  @Input() loans: ILoan[] = []

  protected readonly types = BorgoAccommodationTypeMap

  public override form = new FormGroup({
    type: new FormControl<TBorgoAccommodationType | null>(null, {
      nonNullable: true, validators:
        [Validators.required]
    }),
    fee: new FormControl<number>(0),
    rent: new FormControl<number>(0),
    area: new FormControl<number>(0),
    runCost: new FormControl<number | null>(null, {validators: [Validators.min(1)]}),
    cooperativeTaxNumber: new FormControl<string>(''),
    loans: new FormArray<FormControl<ILoan | null>>([])
  })

  public loansHandler = new ListHandler<ILoan>(this.form.controls.loans)

  public override ngOnInit() {
    super.ngOnInit()
    this.loansHandler.controlGenerator = LoanComponent.GenerateForm

    this.othersValid = this.primary

    this.form.controls.loans.valueChanges
      .pipe(filter((v): v is ILoan[] => v !== null))
      .subscribe({
        next: v => this.initial.loans = v
      })

    this.form.controls.type.valueChanges
      .pipe(filter(Boolean))
      .subscribe({
          next: () => {
            const requiredApartment = this.form.controls.type.value === 'APARTMENT'
            this.setValidatorsIfRequired(requiredApartment, this.form.controls.fee, requiredValidatorsApartment)
            /**
             * We only take these for loan object
             */
            if (this.primary) {
              this.setValidatorsIfRequired(requiredApartment, this.form.controls.cooperativeTaxNumber, requiredValidatorsApartment)
              this.setValidatorsIfRequired(requiredApartment, this.form.controls.area, requiredValidatorsApartment)
            }
            const requiredRent = this.form.controls.type.value === 'RENTED_APARTMENT'
            this.setValidatorsIfRequired(requiredRent, this.form.controls.rent, requiredValidatorsApartment)
          }
        }
      )

    this.loansHandler.have.valueChanges
      .pipe(filter((v): v is boolean => v !== null))
      .subscribe({
        next: () => {
          this.othersValid = true
          this.form.updateValueAndValidity()
        }
      })
  }
}
