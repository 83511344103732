<form [formGroup]="form">
  <spb-selector
    [ctrl]="form.controls.status"
    [items]="statuses"
    label="Civilstatus"
  ></spb-selector>
  @if (nameRequired) {
    <mat-form-field>
      <mat-label>Sambos namn</mat-label>
      <input matInput formControlName="partnerName">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Sambos personnummer</mat-label>
      <input matInput formControlName="partnerSub">
    </mat-form-field>
  }
</form>