import {Base} from '../base/base'
import {IBorgoApplication, IBorgoLoanAccommodation} from 'sparbanken-syd-borgo'

export class LoanAmount extends Base {
  /**
   * The amount we want to loan, set in
   */
  public amount: number = 0

  /**
   * The amount of loans already on this "house"
   * @private
   */
  private existing: number = 0

  constructor(application: Partial<IBorgoApplication> = {}) {
    super(application)
    this.setFrom(application)
  }

  public override toApplication(application: Partial<IBorgoApplication>) {
    this.getExistingLoans(application)
    application.appliedCredit!.totalCreditAmount = this.amount + this.existing
  }

  private setFrom(application: Partial<IBorgoApplication>): void {
    if (application.purpose === 'INCREASE') {
      this.getExistingLoans(application)
      if (application.appliedCredit?.totalCreditAmount) {
        this.amount = application.appliedCredit.totalCreditAmount - this.existing
      }
    }
  }

  /**
   * Get all loans that are on the loan object
   */
  private getExistingLoans(application: Partial<IBorgoApplication>): void {
    this.existing = this.getLoanObjectAccommodation(application)
      .map(a => a.creditAmount)
      .reduce((a, c) => a + c, 0)
  }

  /**
   * Of all households, all accommodations there should be
   * exactly one with status 'loanObject: true'.
   */
  private getLoanObjectAccommodation(application: Partial<IBorgoApplication>): IBorgoLoanAccommodation[] {
    let accommodations: IBorgoLoanAccommodation[] = []
    if (application.household) {
      application.household.forEach(h => {
        if (h.accommodation) {
          accommodations = [...accommodations, ...h.accommodation]
        }
      })
    }
    return accommodations.filter(a => a.loanObject)
  }
}
