import {Component, effect, inject, Input, OnChanges} from '@angular/core'
import {IBorgoApplication} from 'sparbanken-syd-borgo'
import {Employment} from '../../model/employment/employment'
import {MaritalStatus} from '../../model/marital-status/marital-status'
import {Obligations} from '../../model/obligations/obligations'
import {BankAssets} from '../../model/asset/bank-assets'
import {Contact} from '../../model/contact/contact'
import {Cost} from '../../model/cost/cost'
import {Income} from '../../model/income/income'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {pnrValidator} from '@sparbanken-syd/sparbanken-syd-theme'
import {filter} from 'rxjs'
import {verify} from '@sparbanken-syd/personnummer'
import {getBaseApplicant} from '../../../application/helpers'
import {ApplicationService} from '../../../services/application.service'
import {SelectorBaseComponent} from '../../selectors/selector-base.component'

/**
 * A bunch of booleans that needs to be set valid or not per
 */
export interface IApplicantGroup {
  marital: boolean
  contact: boolean
  employment: boolean
  income: boolean
  capital: boolean
  cost: boolean
  credits: boolean
}

@Component({
  selector: 'spb-applicant-group',
  templateUrl: './applicant-group.component.html',
  styleUrls: ['../../selectors/selectors.scss']
})
export class ApplicantGroupComponent extends SelectorBaseComponent<IApplicantGroup> implements OnChanges {
  @Input({required: true}) index!: number

  @Input({required: true}) applicationToSend!: IBorgoApplication

  @Input({}) input: IBorgoApplication | null = null

  public override form = new FormGroup({
    marital: new FormControl<boolean>(false, {validators: [Validators.requiredTrue]}),
    contact: new FormControl<boolean>(false, {validators: [Validators.requiredTrue]}),
    employment: new FormControl<boolean>(false, {validators: [Validators.requiredTrue]}),
    income: new FormControl<boolean>(false, {validators: [Validators.requiredTrue]}),
    capital: new FormControl<boolean>(false, {validators: [Validators.requiredTrue]}),
    cost: new FormControl<boolean>(false, {validators: [Validators.requiredTrue]}),
    credits: new FormControl<boolean>(false, {validators: [Validators.requiredTrue]})
  })
  /**
   *
   */
  public employer: Employment = new Employment()
  public maritalStatus: MaritalStatus = new MaritalStatus()
  public obligations: Obligations = new Obligations()
  public bankAssets: BankAssets = new BankAssets()
  public contact: Contact = new Contact()
  /**
   * Many costs, one per applicant but only STUDY LOAN is supported
   */
  public cost: Cost = new Cost()
  /**
   * One for each applicant and each applicant has
   * income from employment and one for capital
   */
  public incomes: [Income, Income] = [new Income(), new Income({}, 0, 'CAPITAL')]

  /**
   * The control for the personnummer.
   */
  public subControl = new FormControl<string | null>(null, {
    nonNullable: true,
    validators: [Validators.required, Validators.minLength(10), pnrValidator]
  })

  private applicationService = inject(ApplicationService)

  constructor() {
    super()
    this.subControl.valueChanges.pipe(
      filter(Boolean),
      filter(() => this.subControl.valid),
      filter(() => !!this.applicationToSend.applicants[this.index])
    ).subscribe({
      next: v => {
        this.applicationToSend.applicants[this.index].nationalId = verify(v)[12]
      }
    })

    effect(() => {
      /**
       * When the current user emits we set the personnummer.
       */
      const sub = this.applicationService.currentUser$()?.personNummer
      if (this.applicationToSend && sub) {
        this.applicationToSend.applicants[0].nationalId = verify(sub)[12]
      }
    })

    this.subControl.statusChanges.subscribe({
      next: v => {
        this.othersValid = v === 'VALID' || this.index === 0
      }
    })
  }

  public override ngOnChanges(): void {
    if (!this.applicationToSend.applicants[this.index]) {
      this.applicationToSend.applicants[this.index] = getBaseApplicant()
    }
    if (this.input) {
      if (!this.input.applicants[this.index]) {
        this.input.applicants.push(getBaseApplicant())
      }
      this.employer = new Employment(this.input, this.index)
      this.maritalStatus = new MaritalStatus(this.input, this.index)
      this.contact = new Contact(this.input, this.index)
      this.incomes = [
        new Income(this.input, this.index, 'EMPLOYMENT'),
        new Income(this.input, this.index, 'CAPITAL')
      ]
      this.cost = new Cost(this.input, this.index)
      this.obligations = new Obligations(this.input, this.index)
      this.bankAssets = new BankAssets(this.input, this.index)
      this.subControl.setValue(this.input.applicants[this.index].nationalId)
    }
    this.setUpdated()
  }

  public setUpdated(): void {
    this.employer.toApplication(this.applicationToSend)
    this.maritalStatus.toApplication(this.applicationToSend)
    this.contact.toApplication(this.applicationToSend)
    this.incomes.forEach(i => i.toApplication(this.applicationToSend))
    this.cost.toApplication(this.applicationToSend)
    this.bankAssets.toApplication(this.applicationToSend)
    this.obligations.toApplication(this.applicationToSend)
    // Make sure to set it on the application
    this.subControl.setValue(this.subControl.value)
  }
}
