<div class="holder">
  @if (loading) {
    <spb-progress-ng
      message="Startar identifiering"
      [minimal]="false"
    ></spb-progress-ng>
  } @else {
    <div [style.max-width.px]="600">
      <h1>Ansökan höja bolån</h1>
      <p>Här kan du ansöka om att höja dina bolån som är förmedlade till vår samarbetspartner Borgo.</p>
      <p>Så här går det till:</p>
      <ol>
        <li>Du identifierar dig med BankID mot vår samarbetspartner Borgo.</li>
        <li>Du fyller i en ansökan.</li>
        <li>Du får svar direkt om du behöver komplettera din ansökan med t.ex. lönespecifikationer eller ett
          lägenhetsutdrag.
        </li>
        <li>Vi går igenom din ansökan och återkopplar om det behövs ytterligare kompletteringar. Därefter får du besked
          om
          ärendet har beviljats.
        </li>
        <li>
          Du väljer räntebindning och amorteringsbelopp samt uppdaterar din kundkännedom. Vill du att vi genomför en
          bolånerådgivning gör vi det i detta steg.
        </li>
        <li>
          Dina lånehandlingar skickas digitalt till dig. Du signerar med BankID och lånet är därefter redo för
          utbetalning.
        </li>
      </ol>
      <p>Vi följer alltid upp din ansökan med en personlig kontakt så att du kan känna dig trygg under hela
        processen.</p>
    </div>
    <div class="button-holder">
      <button (click)="start()" color="primary" mat-raised-button>Starta</button>
    </div>
  }
</div>