import {Component, OnInit} from '@angular/core'
import {TBorgoCostType} from 'sparbanken-syd-borgo'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {SelectorBaseComponent} from '../selector-base.component'
import {ICost} from '../../model/cost/cost'
import {startWith} from 'rxjs/operators'

@Component({
  selector: 'spb-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['../selectors.scss']
})
export class CostComponent extends SelectorBaseComponent<ICost> implements OnInit {

  protected override form = new FormGroup({
    selected: new FormControl<boolean | null>(null, {nonNullable: true, validators: [Validators.required]}),

    cost: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: []
    }),
    type: new FormControl<TBorgoCostType>('STUDENT_LOAN',
      {nonNullable: true, validators: [Validators.required]})
  })

  public override ngOnInit() {
    super.ngOnInit()
    this.form.valueChanges.pipe(
      startWith(null)
    ).subscribe({
      next: () => {
        this.setValidatorsIfRequired(
          this.form.controls.selected.value, this.form.controls.cost, [Validators.required])
      }
    })
  }
}
