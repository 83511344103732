<mat-card [class]="{ error: !formValid$() && !edit()}">
  <mat-card-header>
    <div class="kyc-header">
      <div>Kontaktuppgifter</div>
    </div>
  </mat-card-header>
  <mat-card-content>
    @if (edit()) {
      <form [formGroup]="form">
        <mat-form-field>
          <mat-label>Mobiltelefonnummer</mat-label>
          <input matInput formControlName="phone">
        </mat-form-field>

        <mat-form-field>
          <mat-label>E-post</mat-label>
          <input matInput formControlName="email">
        </mat-form-field>
      </form>
    } @else {
      <div>Mobiltelefonnummer: {{ form.controls.phone.value }}</div>
      <div>E-post: {{ form.controls.email.value }}</div>
    }
  </mat-card-content>
  <mat-card-actions>
    <spb-kyc-footer
      [(edit)]="edit"
      [formValid]="formValid$()"
      [changes]="contact"></spb-kyc-footer>
  </mat-card-actions>
</mat-card>