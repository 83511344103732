import {Component, Input, model, OnChanges, signal} from '@angular/core'
import {IBorgoApplication, IBorgoLoanAccommodation} from 'sparbanken-syd-borgo'
import {Accommodation, ACCOMMODATION_PATH} from '../../model/accommodation/accommodation'
import {FormControl} from '@angular/forms'
import {filter} from 'rxjs'
import {search} from 'jmespath'

@Component({
  selector: 'spb-accommodations',
  templateUrl: './accommodations.component.html',
  styleUrl: '../../selectors/selectors.scss'
})
export class AccommodationsComponent implements OnChanges {
  @Input({required: true}) applicationToSend!: IBorgoApplication

  @Input() input: IBorgoApplication | null = null

  /**
   * Should be set to true if all is valid,
   * default it is NOT since you have to
   * select a value for other properties
   */
  public allValid = signal(false)

  /**
   * Outside watchers of the same thing, can write
   * but the model behaves better than the "output"??
   * Fuck if I ever understand these.
   */
  public valid = model<boolean>()

  public accommodations: Accommodation[] = []

  public haveCtrl = new FormControl<boolean | null>(null)

  public validMap: Record<number, boolean> = {}

  constructor() {
    this.haveCtrl.valueChanges
      .pipe(filter((v): v is boolean => v !== null))
      .subscribe({
        next: v => {
          // If answer is NO (false) then we become valid.
          // If answer is YES we become invalid
          if (!v) {
            this.accommodations.forEach((a, i) => {
              this.removeAccommodation(i)
            })
          } else if (this.accommodations.length === 0) {
            this.addAccommodation()
          }
          this.setValidity()
        }
      })
  }

  public ngOnChanges(): void {
    const input: IBorgoLoanAccommodation[] | null = search(this.input, ACCOMMODATION_PATH)
    if (input) {
      const other = input.slice(1)
      other.forEach(() => {
        this.addAccommodation()
      })

      if (this.accommodations.length > 0) {
        this.haveCtrl.setValue(true)
        this.allValid.set(true)
      }
    }
  }

  public addAccommodation(): void {
    this.validMap[this.accommodations.length + 1] = false
    this.accommodations.push(new Accommodation(this.input!, this.accommodations.length + 1))
    this.setValidity()
  }

  /**
   * Removes an accommodation
   * @param index - Should be zero based on our accommodations
   */
  public removeAccommodation(index: number): void {
    // Remove from application, that list is of by one.
    const accommodations = search(this.applicationToSend, ACCOMMODATION_PATH)
    if (accommodations) {
      accommodations.splice(index + 1, 1)
    }
    this.accommodations.splice(index, 1)
    this.validMap = {}

    this.accommodations.forEach((a, idx) => {
      // This index starts over on 0
      a.index = idx + 1
      a.toApplication(this.applicationToSend)
      this.validMap[idx + 1] = a.isValid()
    })

    if (this.accommodations.length === 0) {
      this.haveCtrl.setValue(false)
    }
    this.setValidity()
  }

  /**
   * Called from the template only
   * @param valid
   * @param index - The index in our array + 1
   */
  public setValid(valid: boolean, index: number) {
    this.validMap[index] = valid
    this.setValidity()
  }

  private setValidity(): void {
    this.allValid.set(Object.values(this.validMap).every(v => v))
    this.valid.set(this.allValid())
  }
}