import {Component, effect} from '@angular/core'
import {KycBaseItemComponent} from '../0-base/kyc-base-item.component'
import {FormControl, Validators} from '@angular/forms'
import {KycItemIncome} from '../../model/kyc-item-income'
import {filter} from 'rxjs'

@Component({
  selector: 'spb-kyc-income',
  templateUrl: './kyc-income.component.html',
  styleUrl: '../kyc-items.scss',
  providers: [{provide: KycBaseItemComponent, useExisting: KycIncomeComponent}]
})
export class KycIncomeComponent extends KycBaseItemComponent {

  public override form = new FormControl<number | null>(null
    , {nonNullable: true, validators: [Validators.required, Validators.min(1)]})

  public income = new KycItemIncome({}, {} as any)

  constructor() {
    super()
    effect(() => {
      this.income = this.input().income
      this.form.setValue(this.income.income)
      this.income.isValid$.subscribe(this.updateKyc)
      this.income.value$.subscribe({
        next: v => this.form.setValue(v.grossSalary)
      })
    })


    this.form.valueChanges
      .pipe(filter(Boolean))
      .subscribe({
        next: v => this.income.setValue(v)
      })
  }
}
