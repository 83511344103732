import {Component, Input} from '@angular/core'
import {FormGroup} from '@angular/forms'

@Component({
  selector: 'spb-kyc-tax-output',
  templateUrl: './kyc-tax-output.component.html',
  styleUrl: '../../kyc-items.scss'
})
export class KycTaxOutputComponent {
  @Input({required: true}) label!: string
  @Input({required: true}) form!: FormGroup
}
