import {Component, input, model} from '@angular/core'
import {KycItem} from '../../model/kyc-item'

@Component({
  selector: 'spb-kyc-footer',
  templateUrl: './kyc-footer.component.html',
  styleUrl: './kyc-footer.component.scss'
})
export class KycFooterComponent {
  /**
   * Flag to show "Ändra" button when not in edit mode.
   */
  public canEdit = input<boolean>(true)

  public edit = model<boolean>(false)

  /**
   * Sad name of the input
   */
  public changes = input.required<KycItem<any>>()

  /**
   * Form valid
   */
  public formValid = input<boolean>()
}
