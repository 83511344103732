import {Component} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {TBorgoCreditor} from 'sparbanken-syd-borgo'
import {SelectorBaseComponent} from '../../selector-base.component'
import {BorgoCreditorsMap} from '@sparbanken-syd/borgo-helpers'
import {Creditor} from '../../../model/creditor/creditor'

@Component({
  selector: 'spb-creditor',
  templateUrl: './creditor.component.html',
  styleUrls: ['../../selectors.scss']
})
export class CreditorComponent extends SelectorBaseComponent<Creditor> {

  protected readonly creditors = BorgoCreditorsMap

  public override form = new FormGroup({
    creditor: new FormControl<TBorgoCreditor | undefined | null>(null, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    otherCreditorName: new FormControl<string | null>(null, {nonNullable: true, validators: [Validators.minLength(3)]})
  })

  private otherCreditorValidators = [Validators.required, Validators.minLength(3)]

  constructor() {
    super()
    this.form.controls.creditor.valueChanges.subscribe({
      next: v => {
        this.setValidatorsIfRequired(v === 'OTHER_CREDITOR',
          this.form.controls.otherCreditorName, this.otherCreditorValidators)
        this.initial.creditor = v
      }
    })
  }
}
