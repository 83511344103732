import {Base} from '../base/base'
import {IBorgoApplication, IBorgoCollateral} from 'sparbanken-syd-borgo'

export class Valuation extends Base {

  public selected?: boolean | null = null
  /**
   * Must be null to start with since it
   * sets the yes/no if it has a number.
   */
  public new: number | null = null

  private collateral: IBorgoCollateral | null = null

  constructor(application: Partial<IBorgoApplication> = {}, private index: number = 0) {
    super(application)
    this.setFrom(application)
  }

  public setCollateral(collateral: IBorgoCollateral | null): void {
    this.collateral = collateral
  }

  public override toApplication(application: Partial<IBorgoApplication>) {
    application.household?.forEach(hs => {
      if (hs.accommodation[this.index]) {
        delete hs.accommodation[this.index].marketValue
        if (this.collateral) {
          hs.accommodation[this.index].marketValue = this.collateral.valuation.marketValue
        }
        if (this.selected) {
          hs.accommodation[this.index].marketValue = this.new as number
        }
      }
    })

    // If we have a new value set then we guess that external valuation
    // should be true.
    if (application.loanObject && this.index === 0) {
      application.loanObject.externalValuation = this.selected as boolean
    }
  }

  /**
   * A new market value _can_ be set on the accommodation.
   */
  private setFrom(application: Partial<IBorgoApplication>): void {
    if (application.household) {
      application.household.forEach(h => {
        if (h.accommodation[this.index].marketValue) {
          this.new = h.accommodation[this.index].marketValue as number
          // This must mean that we have made a selection
          if (this.new !== null) {
            this.selected = true
          }
        }
      })
    }
    // A "no" here takes precedence.
    if (application.loanObject) {
      this.selected = application.loanObject.externalValuation
    }
  }
}
