import {Base} from '../base/base'
import {TBorgoCreditor} from 'sparbanken-syd-borgo'
import {BorgoCreditorsMap} from '@sparbanken-syd/borgo-helpers'

export interface ICreditor {
  creditor?: TBorgoCreditor | string | null
  otherCreditorName?: string
}

export class Creditor extends Base implements ICreditor {
  private pCreditor?: TBorgoCreditor | string | null
  otherCreditorName?: string

  get creditor() {
    return this.pCreditor
  }

  set creditor(creditor) {
    this.pCreditor = creditor
    if (creditor !== 'OTHER_CREDITOR') {
      this.otherCreditorName = undefined
    }
  }

  public static fromCredit(input: Partial<ICreditor>): Creditor {
    const creditor = new Creditor()
    creditor.pCreditor = input.creditor
    creditor.otherCreditorName = input.otherCreditorName
    Creditor.reverseCreditor(creditor)
    return creditor
  }

  /**
   * The Creditor name should be set to the "name", that is if
   * it is Länsförsäkringar, then it will find LANSFORSAKRINGAR and
   * set that. If not a recognized name then "OTHER" is set and the
   * other name is the name passed in.
   */
  public static reverseCreditor(creditor: Creditor): void {
    const name = creditor.pCreditor
    const key =
      [...BorgoCreditorsMap.entries()]
        .find(v => v[1].name === name)
    if (!key) {
      creditor.pCreditor = 'OTHER_CREDITOR'
      creditor.otherCreditorName = name as string
    } else {
      creditor.pCreditor = key[0]
    }
  }

  public override isValid(): boolean {
    return (!!this.pCreditor && this.pCreditor !== 'OTHER_CREDITOR') ||
      (this.pCreditor === 'OTHER_CREDITOR' && !!this.otherCreditorName)
  }
}
