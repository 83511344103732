import {Component, inject, input, OnInit, signal} from '@angular/core'
import {KycPersonDataset} from '../../model/kyc-person-dataset'
import {AbstractControl} from '@angular/forms'
import {startWith} from 'rxjs/operators'
import {KycService} from '../../../../../services/kyc.service'

@Component({
  selector: 'spb-kyc-base-item',
  template: ''
})
export class KycBaseItemComponent implements OnInit {
  /**
   * Where in the list we are
   */
  public position = input.required<number>()

  /**
   * Denotes if this is the primary applicant. It controls
   * the behavior of certain inputs. Some that can be set only
   * if we have an application at hand.
   */
  public primary = input.required<boolean>()
  /**
   * The total number of questions
   */
  public total: number = 6

  /**
   * All components have the Collected class.
   */
  public input = input.required<KycPersonDataset>()

  /**
   * Used to listen to form status changes, all components
   * _can_ and _should_ add a form here.
   */
  public form!: AbstractControl

  /**
   * Sets the validity based on the passed in form.
   */
  public formValid$ = signal<boolean>(false)

  /**
   * Set to true if edit mode.
   */
  public edit = signal<boolean>(false)

  private kycService = inject(KycService)

  public updateKyc = (valid: boolean): void => {
    this.kycService.setValid(this.position(), valid)
  }

  public ngOnInit(): void {
    this.form.statusChanges
      .pipe(startWith('VALID'))
      .subscribe({
        next: () => this.formValid$.set(this.form.valid)
      })
  }
}
