import {Base} from '../base/base'
import {IBorgoApplication, IBorgoMobilePhoneNumber, IBorgoPhone} from 'sparbanken-syd-borgo'

export const convertPhoneNumber = (input: string): IBorgoPhone => {
  let number = input.replace(/\D/g, '')
  if (number.charAt(0) !== '0') {
    number = `0${number}`
  }
  return {
    countryCode: '46',
    area: number.substring(1, 4),
    number: number.substring(4)
  }
}

export const convertToKycPhone = (input: IBorgoPhone): any => {
  return {
    cc: input.countryCode,
    area: input.area,
    number: input.number,
    type: 'MOBILE'
  }
}

export interface IContact {
  email: string
  phone: string
}


export class Contact extends Base implements IContact {
  public email: string = ''
  public phone: string = ''

  constructor(application: Partial<IBorgoApplication> = {}, private index: number = 0) {
    super(application)
    this.setFrom(application)
  }


  private setFrom(application: Partial<IBorgoApplication>) {
    this.getApplicantPropertyOrNot<IBorgoMobilePhoneNumber>(
      application, this.index, 'mobilePhoneNumber')
      .forEach(c => this.phone = `0${c.area}-${c.number}`.replace(/^0-$/, ''))

    this.getApplicantPropertyOrNot<string>(
      application, this.index, 'email')
      .forEach(c => this.email = c)
  }

  public override toApplication(application: Partial<IBorgoApplication>): void {
    if (application.applicants && application.applicants[this.index]) {
      application.applicants[this.index].mobilePhoneNumber =
        convertPhoneNumber(this.phone)
      application.applicants[this.index].email = this.email
    }
  }
}

