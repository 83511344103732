/**
 * Only used when no loan exists.
 */
import {Component} from '@angular/core'
import {UserService} from '../../services/user.service'

@Component({
  selector: 'spb-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent {

  constructor(userService: UserService) {
    userService.clearLogin()
  }
}
