import {inject, Injectable} from '@angular/core'
import {ICollateralLoanSummary, UserService} from '../../../services/user.service'
import {Observable, ReplaySubject, switchMap} from 'rxjs'
import {IBorgoApplication} from 'sparbanken-syd-borgo'

@Injectable({
  providedIn: 'root'
})
export class ReadOnlyService {


  private loading: boolean = false

  private userService = inject(UserService)

  private data$ = new ReplaySubject<ICollateralLoanSummary>(1)

  public loadData(application: IBorgoApplication): Observable<ICollateralLoanSummary> {
    if (this.loading) {
      return this.data$
    }
    this.loading = true
    const id = application.loanObject?.externalReferences[0]
    return this.userService.getLoanForCollateral(id!.id).pipe(
      switchMap(summary => {
        this.data$.next(summary)
        return this.data$
      })
    )
  }
}
