import {Component, effect} from '@angular/core'
import {KycBaseItemComponent} from '../0-base/kyc-base-item.component'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {filter} from 'rxjs'
import {CountryMap} from '../../model/countries'
import {sortOriginal} from '../../../../../application/helpers'
import {KycItemAddress} from '../../model/kyc-item-address'

@Component({
  selector: 'spb-kyc-address',
  templateUrl: './kyc-address.component.html',
  styleUrl: '../kyc-items.scss',
  providers: [{provide: KycBaseItemComponent, useExisting: KycAddressComponent}]
})
export class KycAddressComponent extends KycBaseItemComponent {

  public override form = new FormGroup({
    street: new FormControl<string>('', {nonNullable: true, validators: Validators.required}),
    postalCode: new FormControl<string>('',
      {
        nonNullable: true, validators: [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)]
      }),
    location: new FormControl<string>('', {nonNullable: true, validators: Validators.required}),
    country: new FormControl<string>('', {nonNullable: true, validators: Validators.required})
  })

  protected readonly CountryMap = CountryMap
  protected readonly sortOriginal = sortOriginal

  /**
   * Public for testing only
   */
  public address = new KycItemAddress()

  constructor() {
    super()
    effect(() => {

      this.address = this.input().address
      this.form.patchValue(this.address.address)

      this.address.isValid$.subscribe(this.updateKyc)

      this.address.value$.subscribe({
        next: v => this.form.patchValue(v)
      })
    })

    this.form.valueChanges
      .pipe(filter(Boolean)).subscribe({
        next: (v) => {
          this.address.setValue(v)
        }
      }
    )
  }
}
