import {Component, Input} from '@angular/core'
import {SelectorBaseComponent} from '../selector-base.component'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {TBorgoIncomeType} from 'sparbanken-syd-borgo'
import {IIncome} from '../../model/income/income'

@Component({
  selector: 'spb-income',
  templateUrl: './income.component.html',
  styleUrl: '../selectors.scss'
})
export class IncomeComponent extends SelectorBaseComponent<IIncome> {

  /**
   * The title of the input
   */
  @Input() title: string = 'Lön'

  /**
   * Optional suffix
   */
  @Input() suffix?: string

  @Input() hint?: string

  protected override form = new FormGroup({
    income: new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required]
    }),
    type: new FormControl<TBorgoIncomeType | null>(null,
      {nonNullable: true, validators: [Validators.required]})
  })
}
