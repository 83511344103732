<mat-form-field [style.display]="'flex'">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="ctrl" ngDefaultControl placeholder="Välj i listan...">
    <mat-option [value]="null" disabled>Välj i listan...</mat-option>
    @for (item of items | keyvalue: sortOriginal; track $index) {
      @if (item.value.available) {
        <mat-option
          [value]="item.key">{{ item.value.name }}
        </mat-option>
      }
    }
  </mat-select>
</mat-form-field>