import {IBorgoApplication, IKycPersonDataset} from 'sparbanken-syd-borgo'
import {KycItemPep} from './kyc-item-pep'
import {KycItemAddress} from './kyc-item-address'
import {KycItemContact} from './kyc-item-contact'
import {KycItemEmployment} from './kyc-item-employment'
import {KycItemCitizen} from './kyc-item-citizen'
import {KycItemTax} from './kyc-item-tax'
import {KycItemIncome} from './kyc-item-income'

export class KycPersonDataset {
  /**
   * This is the resulting data that we send
   * to Borgo. It only contains changed items.
   */
  public result: Partial<IKycPersonDataset> = {}

  private readonly pPep: KycItemPep

  private readonly pAddress: KycItemAddress

  private readonly pContact: KycItemContact

  private readonly pEmployment: KycItemEmployment

  private readonly pCitizen: KycItemCitizen

  private readonly pTax: KycItemTax

  private readonly pIncome: KycItemIncome

  constructor(input: Partial<IKycPersonDataset> = {}, application: IBorgoApplication) {
    this.pPep = new KycItemPep(input)
    this.pAddress = new KycItemAddress(input)
    this.pContact = new KycItemContact(input, application)
    this.pEmployment = new KycItemEmployment(input, application)
    this.pCitizen = new KycItemCitizen(input)
    this.pTax = new KycItemTax(input)
    this.pIncome = new KycItemIncome(input, application)
    this.result = input
  }

  get pep(): KycItemPep {
    return this.pPep
  }

  get address(): KycItemAddress {
    return this.pAddress
  }

  get contact(): KycItemContact {
    return this.pContact
  }

  get employment(): KycItemEmployment {
    return this.pEmployment
  }

  get citizen(): KycItemCitizen {
    return this.pCitizen
  }

  get taxation(): KycItemTax {
    return this.pTax
  }

  get income(): KycItemIncome {
    return this.pIncome
  }

  /**
   *
   */
  public getDiff(): Partial<IKycPersonDataset> {
    const result = {...this.result}
    // Remove everything that we do not care about?
    delete result.addresses
    return result
  }
}
