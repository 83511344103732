import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core'
import {UserService} from '../../../services/user.service'
import {FormControl} from '@angular/forms'

@Component({
  selector: 'spb-collateral-loans',
  templateUrl: './collateral-loans.component.html',
  styleUrl: '../selectors.scss'

})
export class CollateralLoansComponent implements OnChanges {

  /**
   * Enter a collateral and get the loan amount.
   */
  @Input() collateralId?: string

  /**
   * The sum of loans as a convenience
   */
  @Output() currentLoanAmount = new EventEmitter<number>()

  /**
   * The ID (accountNumber) of the loans here.
   */
  @Output() currentLoanIds = new EventEmitter<string[]>()

  public sum: number = 0

  public searching: boolean = false

  public sumCtrl = new FormControl<number | null>({
    disabled: true,
    value: null
  })

  constructor(private userService: UserService) {
  }

  public ngOnChanges(): void {
    if (this.collateralId) {
      this.sum = -1
      this.userService.getLoanForCollateral(this.collateralId)
        .subscribe({
          next: (summary) => {
            this.sum = summary.sum
            this.searching = false
            this.currentLoanAmount.emit(this.sum)
            this.sumCtrl.setValue(this.sum)
            this.currentLoanIds.emit(summary.ids)
          }
        })
    }
  }
}
