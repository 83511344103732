<div class="holder">
  @for (applicant of applicants; track $index) {
    <div class="row-holder">
      <div class="heading">{{ $index === 0 ? 'Sökande' : 'Medsökande' }}</div>
      <div>
        <div>{{ applicant.firstName }} {{ applicant.lastName }}</div>
      </div>
      <div>
        <div>{{ applicant.nationalId }}</div>
      </div>

      <div class="row-item">
        <div>Telefon:</div>
        <div>{{ toPhone(applicant.mobilePhoneNumber) }}</div>
      </div>
      <div class="row-item">
        <div>E-post:</div>
        <div>{{ applicant.email }}</div>
      </div>

      <div class="row-item">
        <div>Civilstånd:</div>
        <div>{{ applicant.civilStatus.type | civilStatus }}</div>
      </div>
      @if (applicant.civilStatus.type === 'COHABITATION') {
        <div class="row-item">
          <div>Partner:</div>
          <div>{{ applicant.civilStatus.partnerName }} ({{ applicant.civilStatus.partnerNationalId }})</div>
        </div>
      }
      <div class="row-item">
        <div>Sysselsättning:</div>
        <div>{{ applicant.employment.employmentType | employment }}</div>
      </div>
      @if (applicant.employment.employerName) {
        <div class="row-item">
          <div>Arbetsgivare:</div>
          <div>{{ applicant.employment.employerName }}</div>
        </div>
      }
      @if (applicant.employment.employedStartDate) {
        <div class="row-item">
          <div>Anställningsdatum:</div>
          <div>{{ applicant.employment.employedStartDate }}</div>
        </div>
      }
      @if (applicant.employment.temporaryEmployedEndDate) {
        <div class="row-item">
          <div>Slutdatum:</div>
          <div>{{ applicant.employment.temporaryEmployedEndDate }}</div>
        </div>
      }
      @if (applicant.employment.typeSelfEmployedAbOrgnr) {
        <div class="row-item">
          <div>Organisationsnummer:</div>
          <div>{{ applicant.employment.typeSelfEmployedAbOrgnr }}</div>
        </div>
      }
    </div>
  }
</div>